import { useEffect } from "react";

export const isScollbarClicked = (event) => {
  const { offsetX, offsetY, target } = event;
  return (
    offsetX > target.clientWidth || offsetY > target.clientHeight
  );
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutSideClickDetector(ref, callback) {
  useEffect(() => {
    /**
     * Callback click on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !isScollbarClicked(event)
      ) {
        console.log("XXXX");
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutSideClickDetector;
