import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import styles from "./MaterialPreview.module.scss";
import TestSvg from "../../assets/svgs/Quizicon.svg";

import {
  getCertainQuiz,
  setCertainQuiz
} from "../../store/Books/actions";
import { Modal } from "@mui/material";
import Button from "../Button";
import { Box } from "@mui/system";
import { boxStyle } from "../../containers/Books/BookViewer/boxStyle";
import { axiosInstance, customer_portal } from "../../network/apis";
import CloseButtonIcon from "../../assets/svgs/close-button-icon";
import Group from "../../assets/svgs/Group.svg";
import "./QuizPreview.scss";

const QuizPreview = ({
  quiz,
  singleBook,
  currentPage,
  setImageModal
}) => {
  const { messages } = useIntl();
  const {
    quiz: { notedescription, note }
  } = messages;
  const [isOpen, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [questionAnswers, setQuestionAnswer] = useState();
  const [userAnswers, setUserAnswers] = useState({});

  const dispatch = useDispatch();
  const {
    books: { quizDetails }
  } = useSelector((state) => state);
  useEffect(() => {
    if (quizDetails) {
      setQuestionAnswer(quizDetails);
      const oldAnswers = {};
      quizDetails?.questions?.forEach((question) => {
        if (question?.user_answer) {
          oldAnswers[question.user_answer?.question_id] =
            question?.user_answer?.answer_choice_id;
        }
      });
      setUserAnswers(oldAnswers);
    }
  }, [quizDetails]);

  const addUsersAnswer = (id, answer) => {
    const answers = { ...userAnswers };
    answers[id] = answer;
    setUserAnswers(answers);
  };

  const setImageModalToBook = (img) => {
    setImageModal({ src: img.src, title: img.title });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.quizCover}>
          <button
            className={`${styles.emptyPreview}`}
            onClick={() => {
              setOpen(true);
              dispatch(
                getCertainQuiz({
                  id: quiz?.quiz_id,
                  bookId: singleBook.id,
                  page: currentPage
                })
              );
            }}
          >
            <img src={TestSvg} alt="medblend-image" />
            <h6 className="text-start my-auto pe-2">
              {quiz.quiz_title}
            </h6>
          </button>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          dispatch(setCertainQuiz(null));
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...boxStyle,
            maxHeight: "90%",
            height: "90%",
            width: "45%",
            minWidth: "45%",
            overflowY: "auto"
          }}
        >
          <div className="d-flex justify-content-between">
            <span></span>
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="text-center text-primary "
            >
              {quizDetails?.title}
            </Typography>
            <span
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseButtonIcon />
            </span>
          </div>
          <div className="text-secondary my-3">
            Score:{" "}
            {quizDetails?.questions?.[0]?.user_answer
              ?.user_gained_points
              ? quizDetails?.questions?.[0]?.user_answer
                  ?.user_gained_points
              : 0}
            /{quizDetails?.total_points}
          </div>
          {quizDetails?.questions?.find(
            (qs) =>
              !!qs?.image_url ||
              qs?.answer_choices?.some((ans) => ans.image_url)
          ) && (
            <div className="note gray mb-3">
              <span className="grey bold">{note} </span>
              {notedescription}
            </div>
          )}
          <div>
            {quizDetails?.questions?.map((question, i) => {
              return (
                <div className="my-3" key={question.id}>
                  <div className="d-flex align-items-baseline">
                    <p className="me-2 bold mb-0">Q{i + 1}-</p>
                    <h4 className="mb-0">{question.question} ?</h4>
                  </div>
                  {question.image_url && (
                    <div
                      className="container_image_question mb-2"
                      onClick={() =>
                        setImageModalToBook({
                          src: question.image_url,
                          title: `Q${i + 1}-${question.question}`
                        })
                      }
                    >
                      <img
                        src={question.image_url}
                        className="img-thumbnail question-image"
                      />
                      <div className="middle">
                        <div className="text">
                          <img src={Group} alt="medblend-image" />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {question.answer_choices.map(
                      (
                        { answer, id: answerId, image_url, correct },
                        index
                      ) => (
                        <div key={answerId} className="my-2">
                          <div className=" d-flex align-items-center">
                            <label
                              className="cursor-pointer d-flex align-items-start answer"
                              htmlFor={
                                !image_url
                                  ? `MCQ_${question.id}_${answer}`
                                  : ""
                              }
                            >
                              <input
                                id={`MCQ_${question.id}_${answer}`}
                                type="radio"
                                value={answer}
                                disabled={userAnswers?.[question.id]}
                                className="m-2"
                                name={`question_${i}`}
                                checked={
                                  userAnswers?.[question.id] ===
                                  answerId
                                }
                                onChange={async () => {
                                  try {
                                    await axiosInstance.post(
                                      `${customer_portal}/questions/${question.id}/quiz_question_answer`,
                                      {
                                        answer_choice_id: answerId,
                                        book_quiz_id:
                                          quizDetails?.book_quiz_id
                                      }
                                    );
                                    await dispatch(
                                      getCertainQuiz({
                                        id: quiz?.quiz_id,
                                        bookId: singleBook.id,
                                        page: currentPage
                                      })
                                    );
                                  } catch (error) {
                                    //
                                  }
                                  addUsersAnswer(
                                    question.id,
                                    answerId
                                  );
                                }}
                              />
                              {image_url && (
                                <div
                                  className="container_image_answer"
                                  onClick={() =>
                                    setImageModalToBook({
                                      src: image_url,
                                      title: `${index + 1}-${answer}`
                                    })
                                  }
                                >
                                  <img
                                    src={image_url}
                                    width="100px"
                                    className="img-thumbnail answer-image"
                                    alt="answer"
                                  />
                                  <div className="middle">
                                    <div className="text">
                                      <img
                                        height="20px"
                                        src={Group}
                                        alt="medblend-image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <span className="answer-text">
                                {answer}
                              </span>
                            </label>
                            <div className="result">
                              {userAnswers?.[question.id] ===
                              answerId ? (
                                <>
                                  {correct && (
                                    <div className="success">
                                      correct
                                    </div>
                                  )}
                                  {correct === false && (
                                    <div className="error">wrong</div>
                                  )}
                                </>
                              ) : (
                                userAnswers?.[question.id] &&
                                correct && (
                                  <div className="success">
                                    correct{" "}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {userAnswers?.[question.id] &&
                    question.justification && (
                      <div className="explanation  my-3">
                        {question.justification}
                      </div>
                    )}
                </div>
              );
            })}
            {quizDetails?.questions?.length ===
              Object.keys(userAnswers).length && (
              <div className="d-flex flex-row-reverse">
                <Button
                  text={"Next"}
                  onClick={() => {
                    setOpen(false);
                  }}
                ></Button>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

QuizPreview.propTypes = {
  quiz: PropTypes.object,
  singleBook: PropTypes.object,
  currentPage: PropTypes.number,
  setImageModal: PropTypes.func
};

export default QuizPreview;
