import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function Video({ path, thumbnail }) {
  const [first, setfirst] = useState();
  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <h2>How to Navigate</h2>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <video
          poster={thumbnail}
          controls
          style={{
            width: "70%",
            border: "0px solid black",
            borderRadius: "16px",
            boxShadow: "5px 10px 5px #888899"
          }}
        >
          <source src={path} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

Video.propTypes = {
  path: PropTypes.string,
  thumbnail: PropTypes.string
};

export default Video;
