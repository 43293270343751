import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ReactPlayer from "react-player";
import { Typography } from "@mui/material";

import {
  getSingleBookSupplementariesAction,
  updateBookSupplementaryProgress,
  getBookSupplementaryProgress
} from "../../store/Books/actions";

import DicomViewer from "../../components/DicomViewer";
import styles from "./SupplementaryDisplay.module.scss";
// import { getSupplementaryTypeLabel } from "../../utils/Helpers";
import { ImgDisplay } from "../../hooks/ImgDisplay";
import { KEEP_LINE_SPACES } from "../../utils/Patterns";

import PlayIcon from "../../assets/svgs/play";
import { Container, Row } from "react-bootstrap";

function SupplementaryDisplay() {
  const { id, bookId } = useParams();
  const dispatch = useDispatch();
  const { singleBookSupplementaries, SupplementariesProgress } =
    useSelector((state) => state.books);
  const allSubsForBook = singleBookSupplementaries.map(
    (sub) => sub.supplementary_materials
  );
  const singleSupplementary =
    allSubsForBook.flat().find((sub) => +sub.id === +id) ||
    JSON.parse(localStorage.getItem("supplementary"));

  useEffect(() => {
    if (bookId != null)
      dispatch(
        getSingleBookSupplementariesAction({ book_id: bookId })
      );
  }, [id]);

  useEffect(() => {
    if (singleSupplementary?.material_type === "video") {
      // Dispatch action to get supplementary progress
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  }, [singleSupplementary, bookId]);

  const handleProgress = (state) => {
    // Check if the video is already marked as watched
    if (
      !SupplementariesProgress[
        +singleSupplementary.supplementary_material_id
      ] &&
      state.played >= 0.9
    ) {
      dispatch(
        updateBookSupplementaryProgress({
          book_id: +bookId,
          supplementary_material_id:
            +singleSupplementary.supplementary_material_id,
          watched: true
        })
      );
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  };

  const renderPreview = (videoWithControls) => {
    switch (singleSupplementary.material_type) {
      case "video": //video
        return (
          <div className={styles.video}>
            <ReactPlayer
              className={styles.preview}
              url={singleSupplementary?.url?.[0]}
              width="100%"
              height="100%"
              controls={videoWithControls}
              config={{
                file: { attributes: { controlsList: "nodownload" } }
              }}
              onProgress={handleProgress}
            />
            <div>
              {!videoWithControls && (
                <div className={styles.videoWrapper}>
                  <PlayIcon width="20px" height="20px" />
                </div>
              )}
            </div>
          </div>
        );
      case "image": //image
        return (
          <div className={styles.item}>
            <ImgDisplay
              src={singleSupplementary?.url?.[0]}
              className={styles.preview}
            />
          </div>
        );
      case "DICOM": //dicom
        return (
          <div className={styles.item}>
            <DicomViewer
              imageIds={
                singleSupplementary.material_type === "DICOM"
                  ? singleSupplementary?.url
                  : []
              }
              className={styles.preview}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return !singleSupplementary?.id ? (
    <>This page should be visited via the book viewer</>
  ) : (
    <div style={{ minHeight: "400px", height: "300px" }}>
      <Container>
        <Row>
          {renderPreview(true)}
          <Typography
            sx={{
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "17px"
            }}
            className="text-primary fsize-18 bold mt-4 "
          >
            {singleSupplementary.material_title}
          </Typography>
          {singleSupplementary?.diagnosis && (
            <p
              className={`lh-lg ${styles.description} text-gray`}
              dangerouslySetInnerHTML={{
                __html: singleSupplementary?.diagnosis?.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
          )}

          <p
            className={`lh-lg ${styles.description}`}
            dangerouslySetInnerHTML={{
              __html: singleSupplementary.description.replace(
                KEEP_LINE_SPACES,
                "<br />"
              )
            }}
          ></p>
        </Row>
      </Container>
    </div>
  );
}

export default SupplementaryDisplay;
