import { put, call } from "redux-saga/effects";
import { metaObject } from "../../utils/general";
import * as apis from "../../network/apis/categories";
import { getCategoriesResponse } from "./actions";

export function* getCategoriesSaga({ payload }) {
  try {
    const response = yield call(apis.getCategories, payload);
    yield put(
      getCategoriesResponse({
        list: response.data.categories,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}
