import * as Yup from "yup";
import { emailPattern } from "../../constants/validations";
import messages from "../../assets/locale/messages";

export const CONTACT_US_SCHEMA = (locale) => {
  const {
    inputs: {
      emailRequired,
      emailShouldMatchEmailFormat,
      messsageRequired,
      subjectRequired
    }
  } = messages[locale];

  return Yup.object().shape({
    email: Yup.string(emailRequired)
      .email(emailShouldMatchEmailFormat)
      .matches(emailPattern, emailShouldMatchEmailFormat)
      .required(emailRequired),
    subject: Yup.string(subjectRequired).required(subjectRequired),
    message: Yup.string(messsageRequired).required(messsageRequired)
  });
};
