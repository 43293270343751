import store from "../store";

// transfers sessionStorage from one tab to another
var sessionStorage_transfer = function (event) {
  if (!event) {
    event = window.event;
  } // ie suq
  if (!event.newValue) return; // do nothing if no value to work with
  if (event.key == "getSessionStorage") {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem(
      "sessionStorage",
      JSON.stringify(sessionStorage)
    );
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
  }
};

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener("storage", sessionStorage_transfer, false);
} else {
  window.attachEvent("onstorage", sessionStorage_transfer);
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
  localStorage.setItem("getSessionStorage", "foobar");
  localStorage.removeItem("getSessionStorage", "foobar");
}

const Auth = {
  getAuth() {
    const state = store.getState();
    return !!state.auth.login?.auth_token;
  },
  isVerified(isAuthorOrEditor) {
    return (
      isAuthorOrEditor || !!store.getState().auth.login.email_verified
    );
  }
};
export default Auth;
