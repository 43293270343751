import { createTheme } from "@mui/material";
import ComponentsOverrides from "./overrides";
import palette from "./palette";

const theme = ({ dir }) => {
  return createTheme({
    direction: dir,
    components: ComponentsOverrides(),
    palette,
    typography: {
      fontFamily: [
        dir === "rtl" ? "Helvetica-Medium" : "Helvetica-Medium",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    }
  });
};
export default theme;
