import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { Container, Figure, Nav, Navbar } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import medblendLogo from "../../assets/images/logo.png";
import userImage from "../../assets/images/user-image.png";
import Button from "../../components/Button";
import DropDown from "../../components/DropDown";
import {
  logoutRequest,
  setLoginModalTrigger,
  toggleLogin
} from "../../store/Auth/actions";
import Auth from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import Login from "../Login";
import styles from "./Header.module.scss";

export const activeLinkClass = "active-route";
const Header = () => {
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isEditor = pathname.includes("editor");
  const dispatch = useDispatch();
  const history = useHistory();
  const { messages, locale } = useIntl();
  const {
    header: { digitalBooks, aboutUs, signIn, register, home },
    shared: { hi },
    headerMenu,
    contactUs
  } = messages;

  const setOpenLogin = () => dispatch(toggleLogin());
  const setLoginTrigger = (trigger) =>
    dispatch(setLoginModalTrigger(trigger));
  const logUserOut = () =>
    dispatch(logoutRequest({ isAuthor, isEditor }));
  const user = useSelector((state) => state.auth.login);
  const isLoginOpen = useSelector(
    (state) => state.auth.loginModalOpen
  );
  const loginModalTrigger = useSelector(
    (state) => state.auth.loginModalTrigger
  );
  const [dropList, setdropList] = useState([]);
  const location = useLocation();

  let dropListData = [
    {
      label: headerMenu.profileInfo,
      onClick: () =>
        history.push(`${ROUTE_PATHS.profileInfo}/${user?.id}`)
    },
    {
      label: headerMenu.changePassword,
      onClick: () => history.push(ROUTE_PATHS.changePassword)
    },
    {
      label: "My Book List",
      onClick: () => history.push(ROUTE_PATHS.userBookList)
    },
    {
      label: headerMenu.subscriptionManagement,
      onClick: () => history.push(ROUTE_PATHS.subscriptionManagement)
    },
    {
      label: headerMenu.certificates,
      onClick: () => history.push(ROUTE_PATHS.certificates)
    },
    {
      label: headerMenu.logout,
      onClick: () => {
        logUserOut();
      }
    }
  ];

  useEffect(() => {
    setdropList(dropListData);
    if (user?.id && isLoginOpen) {
      setOpenLogin(false);
      setLoginTrigger("");
    }

    if (user?.institution_subscription) {
      dropListData.splice(3, 0, {
        label: headerMenu.institutionBooks,
        onClick: () => history.push(ROUTE_PATHS.institutionBooks)
      });
      setdropList(dropListData);
    }
  }, [user]);

  useEffect(() => {
    if (
      Auth.getAuth() &&
      !Auth.isVerified(isAuthor || isEditor) &&
      ![
        ROUTE_PATHS.verification,
        ROUTE_PATHS.missingVerification
      ].includes(location.pathname)
    )
      history.push(ROUTE_PATHS.missingVerification);
  }, [location.pathname]);

  const homeLink = isAuthor
    ? "/authors"
    : isEditor
    ? "/editors"
    : "/";
  const handleScroll = (e, sectionId) => {
    const otherLinks = e.target.parentNode.children;
    for (let i = 0; i < otherLinks.length; i++) {
      const element = otherLinks.item(i);
      element.classList.remove(activeLinkClass);
    }
    e.target.classList.add(activeLinkClass);
    if (!sectionId) return;
    if (location.pathname !== homeLink) {
      history.push(homeLink);
    }
    const element = document.getElementById(sectionId);
    const header = document.getElementById("header");
    const headerOffset = (header?.clientHeight ?? 40) + 65; // adjust this value based on your needs
    const elementPosition = element?.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };
  const userName = user?.first_name || user?.name;
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      sticky="top"
      className={`${styles.wrapper} bg-white align-items-center`}
    >
      <Container>
        <Link to={homeLink}>
          <img height="50px" src={medblendLogo} alt="medblend-logo" />
        </Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-between"
          id="responsive-navbar-nav"
        >
          <Nav
            className={`${styles.navListWrapper} text-black`}
            id="header"
          >
            <div id={"links"} className={`${styles["nav-links"]}`}>
              <Link
                onClick={(e) => handleScroll(e, "welcome-section-id")}
                className="active-route"
                to={homeLink}
              >
                {home}
              </Link>
              <Link
                onClick={(e) =>
                  handleScroll(e, "digital-books-section-id")
                }
                to={homeLink}
              >
                {digitalBooks}
              </Link>
              <Link
                onClick={(e) =>
                  handleScroll(e, "about-us-section-id")
                }
                to={homeLink}
              >
                {aboutUs}
              </Link>
              <Link
                onClick={(e) =>
                  handleScroll(e, "contact-us-section-id")
                }
                to={homeLink}
              >
                {contactUs}
              </Link>
            </div>
            <div className={`${styles["user-bar"]}`}>
              {!user?.id && (
                <>
                  <Button
                    className="transparent"
                    text={signIn}
                    onClick={() => {
                      setOpenLogin();
                      setLoginTrigger("header");
                    }}
                  />
                  <Button
                    isLink={true}
                    slug={ROUTE_PATHS.registration}
                    className="primary-light"
                    text={register}
                  />
                </>
              )}

              <div className={`${styles.profile_menu_container}`}>
                {userName && (
                  <DropDown
                    buttonText={
                      <div className="d-flex align-items-center">
                        <span
                          className={`${styles.profile_user_name_hi}`}
                        >
                          {capitalize(hi)},
                        </span>
                        <span
                          className={`${styles.profile_user_name} mx-1`}
                        >
                          {capitalize(
                            userName.length < 8
                              ? userName
                              : `${userName.substring(0, 8)}...`
                          )}
                        </span>

                        <Figure
                          className={`${styles.figure_container} m-0 mx-1`}
                        >
                          <Figure.Image
                            alt={"user-photo"}
                            src={
                              !user?.image ? userImage : user?.image
                            }
                            className={`${styles.userImage} m-0 rounded-circle`}
                          />
                        </Figure>
                      </div>
                    }
                    dropList={dropList}
                    menuButtonClassName={`${styles.profile_menu_btn}`}
                    menuBodyClassName={`${styles.profile_menu}`}
                  />
                )}
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {loginModalTrigger === "header" && (
        <Login
          isLoginOpen={isLoginOpen}
          onLoginClose={() => {
            setOpenLogin();
            setLoginTrigger("");
          }}
          lang={locale}
        />
      )}
    </Navbar>
  );
};

export default Header;
