import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
// import BackButton from "../../../components/BackButton";
import TitleWithSubtitle from "../../../components/TitleWithSubtitle";

const BookInfo = ({ singleBook }) => {
  const releaseFormat = "D MMM YYYY";
  const { messages } = useIntl();
  const {
    shared: { by }
  } = messages;

  return (
    <div>
      {/* <BackButton icon="x" className="mb-xs-20" /> */}
      <TitleWithSubtitle
        title={singleBook?.title}
        subtitle={`${singleBook?.author}
       ${
         singleBook?.release_date
           ? ` | ${moment(singleBook.release_date).format(
               releaseFormat
             )}`
           : ""
       }`}
      />
      {/* <hr className="bg-gray-light-mid mt-xs-14" /> */}
    </div>
  );
};
BookInfo.propTypes = {
  singleBook: PropTypes.object
};

BookInfo.defaultProps = {
  singleBook: {}
};
export default BookInfo;
