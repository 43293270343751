import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import uploadImg from "../../assets/svgs/uploadImg.svg";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import AuthLayout from "../../components/AuthLayout";
import CustomSelect from "../../components/Select";
import GoogleButton from "../../components/GoogleButton";
import { REGISTER_SCHEMA } from "./validations";
import styles from "./Registration.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getCountriesRequest } from "../../store/Countries/actions";
import { formatOptions } from "../../utils/Helpers";
import { getJobsRequest } from "../../store/Jobs/actions";
import {
  registerNewUser,
  socialAuthorization
} from "../../store/Auth/actions";
import { uploadToS3 } from "../../utils/S3";
import { COLORS } from "../../utils/Theme";
import { showHideLoader } from "../../store/Loader/actions";
import { showHideSnackbar } from "../../store/Snackbar/actions";

const Registration = () => {
  const dispatch = useDispatch();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { messages, locale } = useIntl();
  const [image, setImage] = useState("");
  const [formData, setformData] = useState({});
  const countries = useSelector((state) => state.countries.list);
  const jobs = useSelector((state) => state.jobs.list);
  const googleUserData = useSelector(
    (state) => state.auth.googleCollectedData
  );

  const {
    signup: { header, termsAgreement, signUpMessage, imageUpload },
    inputs: {
      email,
      password,
      phoneOptional,
      firstName,
      lastName,
      institution,
      city,
      country,
      job,
      signUpWithGoogle
    },
    buttons: { register }
  } = messages;

  const setPasswordStatus = () => setIsShowPassword(!isShowPassword);

  useEffect(() => {
    dispatch(getCountriesRequest());
    dispatch(getJobsRequest());
  }, []);

  // NOTE: Dont't move useDropzone before onDrop useCallback this causes a ReferenceError
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/png"
  });

  const handleSubmit = async (values) => {
    if (image) {
      dispatch(showHideLoader(true));
      await uploadToS3("image", image, image.name).then((data) => {
        if (!data.link) {
          // in case uploading to s3 failed
          dispatch(
            showHideSnackbar({
              isOpen: true,
              type: "error",
              message:
                "Something went wrong while uploading the image, please try again"
            })
          );
          dispatch(showHideLoader(false));
          return;
        }
        const payload = { ...values, image: data?.link };
        registerType(payload);
      });
    } else {
      registerType(values);
    }
    dispatch(showHideLoader(false));
  };

  const registerType = (payload) => {
    if (!googleUserData?.googleId) {
      dispatch(registerNewUser(payload));
    } else {
      dispatch(
        socialAuthorization({
          login: payload,
          type: "signup"
        })
      );
    }
  };

  const renderRegisterForm = () => (
    <div>
      <Formik
        initialValues={{
          email: googleUserData?.email || "",
          password: "",
          phone: "",
          second_name: googleUserData?.familyName || "",
          first_name: googleUserData?.givenName || "",
          country_id: "",
          city: "",
          institution: "",
          job_id: "",
          termsAgreement: false,
          social_provider_id: googleUserData?.googleId || null,
          social_provider_type: googleUserData?.googleId
            ? "google"
            : null
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={REGISTER_SCHEMA(locale)}
      >
        {({ submitForm, values }) => {
          setformData(values);
          return (
            <Form>
              <Container>
                <Row>
                  <Col className="mx-auto" sm={12} md={12} lg={10}>
                    <div className="mb-5">
                      <GoogleButton
                        text={signUpWithGoogle}
                        registerData={{
                          job_id: formData.job_id,
                          country_id: formData.country_id,
                          second_name: formData.second_name
                        }}
                        type="signup"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col className="mx-auto" sm={12} md={12} lg={10}>
                    <h3 className="mb-4">{header}</h3>
                    <p className="mb-5 fsize-14">{signUpMessage}</p>
                    <Form>
                      <div className="mb-4 d-flex flex-row align-items-center">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <img
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : uploadImg
                            }
                            alt="Upload Image"
                            className={styles.personalImage}
                          />
                        </div>
                        <p className="text-gray mx-4 mt-3">
                          {imageUpload}
                        </p>
                      </div>
                      <Row>
                        <Col sm={12} md={12}>
                          <Input
                            disabled={!!googleUserData}
                            name="first_name"
                            label={firstName}
                            isRequired={true}
                          />
                        </Col>
                        <Col sm={12} md={12}>
                          <Input
                            disabled={!!googleUserData}
                            name="second_name"
                            label={lastName}
                            isRequired={true}
                          />
                        </Col>
                      </Row>
                      <Input name="phone" label={phoneOptional} />
                      <Input
                        disabled={!!googleUserData}
                        name="email"
                        label={email}
                        isRequired={true}
                      />
                      {!googleUserData && (
                        <Input
                          inputIcon={isShowPassword ? "Hide" : "Show"}
                          type={!isShowPassword ? "password" : "text"}
                          label={password}
                          name="password"
                          onIconClick={setPasswordStatus}
                          isRequired={true}
                        />
                      )}
                      <Input name="institution" label={institution} />
                      <CustomSelect
                        options={countries.map((country) =>
                          formatOptions(country, "id", "name")
                        )}
                        placeholder={country}
                        name="country_id"
                        bgColor={COLORS.bgPrimaryMid}
                        icon={
                          <FontAwesomeIcon icon={faChevronDown} />
                        }
                        isSearchable={true}
                        containerClass="mb-4"
                        isRequired
                      />
                      <Input name="city" label={city} />
                      <CustomSelect
                        options={jobs?.map((job) =>
                          formatOptions(job, "id", "job_type")
                        )}
                        placeholder={job}
                        name="job_id"
                        bgColor={COLORS.bgPrimaryMid}
                        icon={
                          <FontAwesomeIcon icon={faChevronDown} />
                        }
                        isSearchable={true}
                        containerClass="mb-4"
                        isRequired
                      />
                      <Checkbox
                        name="termsAgreement"
                        label={termsAgreement}
                      />
                      <div className="mt-4 mb-5">
                        <Button
                          text={register}
                          className="primary-lg"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            submitForm();
                          }}
                        />
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return <AuthLayout body={renderRegisterForm()} />;
};

Registration.propTypes = {
  isRegisterOpen: PropTypes.bool,
  onRegisterClose: PropTypes.func
};

Registration.defaultProps = {
  onRegisterClose: () => {}
};

export default Registration;
