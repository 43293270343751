import React from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import DicomViewer from "../DicomViewer";
import styles from "./MaterialPreview.module.scss";
import { getSupplementaryTypeLabel } from "../../utils/Helpers";
import { ImgDisplay } from "../../hooks/ImgDisplay";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

import {
  faTimes,
  faShareSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import LockIcon from "../../assets/svgs/lock";
import openTab from "../../assets/svgs/open-tab.svg";
import PlayIcon from "../../assets/svgs/play";
import History from "../../routes/History";
import Video from "../../assets/svgs/Videoicon.svg";
import Photo from "../../assets/svgs/Photo.svg";

import { KEEP_LINE_SPACES } from "../../utils/Patterns";
import MaterialModal from "./MaterialModal";
import { useDispatch } from "react-redux";
import {
  SET_MATERIAL_MODAL_DATA,
  SET_OPEN_MATERIAL_MODAL
} from "../../store/Utilities/types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4
};

const MaterialPreview = ({
  src,
  type,
  dicomImages,
  details,
  withModal,
  isBookSubscribed
}) => {
  const { messages } = useIntl();
  const [newTabIconHovered, setNewTabIconHovered] =
    React.useState(false);
  const { shared } = messages;

  const renderPreview = ({ videoWithControls }) => {
    switch (type) {
      case 0: //video
        return (
          <>
            <div className={styles.video}>
              <img src={Video} alt="medblend-image" />
            </div>
            <h6 className="text-start my-auto pe-2">
              {details?.material_title}
            </h6>
          </>
        );
      case 1: //image
        return (
          <>
            <div className={styles.video}>
              <img src={Photo} alt="medblend-image" />
            </div>
            <h6 className="text-start my-auto pe-2">
              {details?.material_title}
            </h6>
          </>
        );
      case 2: //dicom
        return (
          <>
            <div className={styles.video}>
              <img src={Photo} alt="medblend-image" />
            </div>
            <h6 className="text-start my-auto pe-2">
              {details?.material_title}
            </h6>
          </>
        );
      default:
        return null;
    }
  };

  const dispatch = useDispatch();
  const setOpenModal = (open) => {
    dispatch({
      type: SET_OPEN_MATERIAL_MODAL,
      payload: open
    });
  };
  const setModalData = () => {
    dispatch({
      type: SET_MATERIAL_MODAL_DATA,
      payload: {
        src,
        type,
        dicomImages,
        details
      }
    });
  };
  const WrappedPreview = ({ videoWithControls, isModal }) => {
    return (
      <>
        <div
          className={isModal ? styles.modalWrapper : styles.wrapper}
        >
          {!src && (
            <div
              className={`${styles.emptyPreview} d-flex align-items-center justify-content-center fsize-20`}
            >
              {
                shared[
                  `${getSupplementaryTypeLabel(type).label}Preview`
                ]
              }
            </div>
          )}
          <div
            onClick={(e) => {
              if (withModal && isBookSubscribed) {
                e.preventDefault();
                setModalData();
                setOpenModal(true);
              }
            }}
            className={
              withModal ? styles.videoCover : styles.additionalWrapper
            }
          >
            {isModal
              ? renderModalPreview({
                  videoWithControls,
                  type,
                  src,
                  dicomImages
                })
              : renderPreview({
                  videoWithControls,
                  isBookSubscribed
                })}
            <div className={styles.videoCover}></div>
          </div>
        </div>
        {!isBookSubscribed && (
          <div className={styles["lock-on-supplementary"]}>
            <LockIcon width="20px" height="20px" />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {WrappedPreview({ videoWithControls: !withModal })}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="suplementaryViewModal">
          <Box sx={style}>
            <div
              className={styles.xBtn}
              onClick={() => handleClose()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {WrappedPreview({
              videoWithControls: true,
              isModal: true
            })}
            {details.material_type === "video" && (
              <Typography sx={{ marginTop: "15px" }}>
                {messages.watchNow}
              </Typography>
            )}
            <Typography
              sx={{
                marginTop: "10px",
                fontWeight: "bold",
                fontSize: "17px"
              }}
              className="text-primary fsize-18 bold mt-4 "
            >
              {details.material_title}
            </Typography>
            <p
              className={`lh-lg ${styles.description} text-gray`}
              dangerouslySetInnerHTML={{
                __html: details?.diagnosis?.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
            <p
              className={`lh-lg ${styles.description}`}
              dangerouslySetInnerHTML={{
                __html: details.description.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
            <div className="d-flex justify-content-end">
              <a
                className="cursor-pointer"
                onClick={() => {
                  localStorage.setItem(
                    "supplementary",
                    JSON.stringify(details)
                  );
                  window.open(
                    `/supplementary/${details.id}`,
                    "_blank"
                  );
                }}
                rel="noreferrer"
              >
                <span
                  className={`${styles.iconOpentext} mx-2`}
                  style={{
                    visibility: newTabIconHovered
                      ? "visible"
                      : "hidden"
                  }}
                >
                  {openNewTab}
                </span>
                <img
                  src={openTab}
                  className={styles.iconOpen}
                  height="30px"
                  onMouseEnter={() => {
                    setNewTabIconHovered(true);
                  }}
                  onMouseLeave={() => {
                    setNewTabIconHovered(false);
                  }}
                />
              </a>
            </div>
          </Box>
        </div>
      </Modal> */}
    </>
  );
};

MaterialPreview.propTypes = {
  src: PropTypes.string,
  type: PropTypes.number.isRequired,
  dicomImages: PropTypes.arrayOf(PropTypes.string),
  withModal: PropTypes.bool,
  videoWithControls: PropTypes.bool,
  details: PropTypes.object,
  isBookSubscribed: PropTypes.bool,
  isModal: PropTypes.bool
};

MaterialPreview.defaultProps = {
  src: "",
  type: 0,
  dicomImages: [],
  withModal: false,
  isBookSubscribed: false
};

export default MaterialPreview;

export const renderModalPreview = ({
  videoWithControls,
  type,
  src,
  dicomImages
}) => {
  switch (type) {
    case 0: //video
      return (
        <div style={{ maxHeight: "400px", height: "250px" }}>
          <ReactPlayer
            className={styles.preview}
            url={src}
            width="100%"
            height="100%"
            controls={videoWithControls}
            config={{
              file: { attributes: { controlsList: "nodownload" } }
            }}
          />
        </div>
      );
    case 1: //image
      return (
        <ImgDisplay
          src={src}
          className={`${styles.preview} ${styles.images}`}
        />
      );
    case 2: //dicom
      return (
        <DicomViewer
          imageIds={dicomImages}
          className={`${styles.preview} ${styles.images}`}
        />
      );
    default:
      return null;
  }
};

export const WrappedModal = ({
  videoWithControls,
  type,
  src,
  dicomImages,
  shared,
  withModal,
  isBookSubscribed
}) => {
  const dispatch = useDispatch();
  const setOpenModal = (open) => {
    dispatch({
      type: SET_OPEN_MATERIAL_MODAL,
      payload: open
    });
  };
  return (
    <>
      <div className={styles.modalWrapper}>
        {!src && (
          <div
            className={`${styles.emptyPreview} d-flex align-items-center justify-content-center fsize-20`}
          >
            {
              shared[
                `${getSupplementaryTypeLabel(type).label}Preview`
              ]
            }
          </div>
        )}

        <div
          onClick={(e) => {
            if (withModal && isBookSubscribed) {
              e.preventDefault();
              setOpenModal(true);
            }
          }}
          className={
            withModal ? styles.videoCover : styles.additionalWrapper
          }
        >
          {renderModalPreview({
            videoWithControls,
            type,
            src,
            dicomImages
          })}
          <div className={styles.videoCover}></div>
        </div>
      </div>
      {!isBookSubscribed && (
        <div className={styles["lock-on-supplementary"]}>
          <LockIcon width="20px" height="20px" />
        </div>
      )}
    </>
  );
};

WrappedModal.propTypes = {
  videoWithControls: PropTypes.bool,
  type: PropTypes.string,
  src: PropTypes.string,
  dicomImages: PropTypes.arrayOf(PropTypes.string),
  shared: PropTypes.object,
  withModal: PropTypes.bool,
  isBookSubscribed: PropTypes.bool
};
