import { Form, Formik } from "formik";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { axiosInstance, customer_portal } from "../../network/apis";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { CONTACT_US_SCHEMA } from "./validations";
import "./style.scss";

const ContactUs = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.auth.login);
  const { messages, locale } = useIntl();
  const {
    contact: { subject, message, header, headerMessage },
    inputs: { email },
    buttons: { send },
    shared: { messageSent },
    contactUsText
  } = messages;

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await axiosInstance.post(`${customer_portal}/supports`, {
        email_address: values.email,
        email_subject: values.subject,
        email_body: values.message
      });
      resetForm();
      dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "success",
          message: messageSent
        })
      );
    } catch ({ response }) {
      dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: response.message
        })
      );
    }
  };

  const RenderContactUsForm = () => (
    <Formik
      initialValues={{
        subject: "",
        email: login?.email || "",
        message: ""
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={CONTACT_US_SCHEMA(locale)}
    >
      {({ submitForm }) => (
        <Form>
          <Container>
            <Row>
              <Col className="mx-auto" sm={12} md={12} lg={10}>
                <p className="mb-5 fsize-14">{headerMessage}</p>
                <Form>
                  <Input
                    name="subject"
                    label={subject}
                    isRequired={true}
                  />
                  <Input
                    name="email"
                    label={email}
                    isRequired={true}
                    disabled={login?.email}
                  />
                  <Input
                    name="message"
                    label={message}
                    inputShape="textarea"
                    isRequired={true}
                  />
                  <div className="mt-4 mb-5">
                    <Button
                      text={send}
                      className="primary-lg"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        submitForm();
                      }}
                    />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );

  return (
    <section id="contact-us-section-id">
      <Container>
        <Row>
          {/* <Col className="pt-xs-80" item xs={12} sm={12} md={7}> */}
          <div className="contactus-container">
            <div className="contactus-text">
              <h2 className="title mb-4">{header}</h2>
              <p className="body">{contactUsText}</p>
            </div>
            <div className="form">
              <RenderContactUsForm />
            </div>
          </div>
          {/* </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
