import * as types from "./types";

export const getCountriesRequest = (data) => ({
  type: types.GET_COUNTRIES_REQUEST,
  payload: data
});

export const getCountriesResponse = (data) => ({
  type: types.GET_COUNTRIES_RESPONSE,
  payload: data
});
