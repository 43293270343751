import S3 from "react-aws-s3";

export const CONFIG = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: "Acore_fe",
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  s3Url: process.env.REACT_APP_S3URL
};

export const uploadToS3 = async (type, file, fileName) => {
  const S3Client = new S3(CONFIG);
  const uniqueFileName = `${new Date().getTime()}_${fileName.replace(
    /\s/g,
    ""
  )}`;
  if (file) {
    switch (type) {
      case "image":
        return await S3Client.uploadFile(file, uniqueFileName)
          .then((data) => {
            return { link: data.location };
          })
          .catch((err) => console.error(err));
      default:
        return null;
    }
  }
};
