import store from "../../store";
import { showHideLoader } from "../../store/Loader/actions";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { loginResponse } from "../../store/Auth/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";

export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(
    config,
    "handlerEnabled"
  ) && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    store.dispatch(showHideLoader(true));
  }
  const customerData =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user"));
  const token = customerData?.auth_token;
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};

export const successHandler = (response) => {
  const state = store?.getState();

  if (isHandlerEnabled(response)) {
    response.lang = state.locale.lang;
    store.dispatch(showHideLoader(false));
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    store.dispatch(showHideLoader(false));

    if (error?.response?.status === 401) {
      store.dispatch(loginResponse({}));
      localStorage.clear();
      history.push(ROUTE_PATHS.home);
    }
    if (error?.response?.data?.message) {
      store.dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: error.response.data.message
        })
      );
    }
  }
  return Promise.reject({ ...error });
};
