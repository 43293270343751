import React from "react";

const ModalClose = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="12 0 41 41"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g
                fill="#FFF"
                transform="translate(-289.000000, -222.000000) translate(269.500000, 208.500000) translate(20.000000, 14.000000)"
              >
                <rect width="40" height="40" rx="8" />
              </g>
              <path
                stroke="#0E4DCE"
                strokeLinecap="round"
                strokeWidth="2"
                d="M11.92 20L28.122 20"
                transform="translate(-289.000000, -222.000000) translate(269.500000, 208.500000) translate(20.000000, 14.000000) translate(20.021383, 20.000000) scale(-1, 1) rotate(45.000000) translate(-20.021383, -20.000000)"
              />
              <path
                stroke="#0E4DCE"
                strokeLinecap="round"
                strokeWidth="2"
                d="M11.92 20L28.122 20"
                transform="translate(-289.000000, -222.000000) translate(269.500000, 208.500000) translate(20.000000, 14.000000) translate(20.021383, 20.000000) rotate(45.000000) translate(-20.021383, -20.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ModalClose;
