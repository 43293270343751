import * as types from "./types";

const INITIAL_STATE = {
  login:
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user")) ||
    {},
  googleCollectedData: null,
  loginModalOpen: false,
  loginModalTrigger: ""
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_RESPONSE:
      return { ...state, login: action.payload };
    case types.COMPLETE_DATA_WITH_GOOGLE:
      return { ...state, googleCollectedData: action.payload };
    case types.TOGGLE_LOGIN:
      return { ...state, loginModalOpen: !state.loginModalOpen };
    case types.SET_LOGIN_MODAL_TRIGGER:
      return { ...state, loginModalTrigger: action.payload };
    default:
      return state;
  }
};

export default authReducer;
