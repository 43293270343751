import React from "react";
import { useIntl } from "react-intl";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmptyState = () => {
  const { messages } = useIntl();
  const {
    shared: { emptyStateMsg }
  } = messages;

  return (
    <div className="text-center text-black mb-5">
      <FontAwesomeIcon
        icon={faFolderOpen}
        size="6x"
        className="mb-2"
      />
      <h5 className="text-center fsize-24">{emptyStateMsg}</h5>
    </div>
  );
};

export default EmptyState;
