export const ROUTE_PATHS = {
  home: "/",
  authorsHome: "/authors",
  editorsHome: "/editors",
  login: "/login",
  authorLogin: "/authors/login",
  editorLogin: "/editors/login",
  missingVerification: "/missing_verification",
  verification: "/verification",
  registration: "/registration",
  forgetPassword: "/forget-password",
  bookProfile: "/book-profile",
  books: "/books",
  bookViewer: "/book-viewer",
  authorBookViewer: "/authors/book-viewer",
  editorBookViewer: "/editors/book-viewer",
  subscriptionManagement: "/subscription-management",
  userBookList: "/subscriped-books",
  checkout: "/checkout",
  // bookAllSupplementary: "/book-all-supplementaries",
  bookAllVideos: "/book-all-videos",
  bookAllTests: "/book-all-tests",
  supplementary: "/supplementary",
  profileInfo: "/profile-info",
  changePassword: "/change-password",
  authorsChangePassword: "/authors/change-password",
  editorsChangePassword: "/editors/change-password",
  contactUs: "/contact-us",
  certificates: "/certificates",
  institutionBooks: "/institution-books",
  promocode: "/promocode",
  testdisplay: "/TestDisplay",
  quizdisplay: "/QuizDisplay",
  signin: "/SignIn",
  editorNotes: "/editors/notes"
};
