import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import { useIntl } from "react-intl";
import { COLORS } from "../../utils/Theme";
import styles from "./Select.module.scss";

const CustomSelect = ({
  name,
  options,
  placeholder,
  handleSelectChange,
  bgColor,
  textColor,
  isRequired,
  isMulti,
  isAddNoneOption,
  isMenuRelative,
  icon,
  width,
  isSearchable,
  containerClass,
  label,
  customStyle,
  showSelectedValue
}) => {
  const Styles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: bgColor ? bgColor : COLORS.white,
      borderRadius: "12px",
      border: 0,
      boxShadow: "none",
      minWidth: width,
      width: "auto",
      cursor: "pointer",
      justifyContent: "center"
    }),
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: "10px 14px",
        display: showSelectedValue ? "static" : "none"
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: isSelected ? COLORS.primary : COLORS.black,
        backgroundColor: isSelected ? "transparent" : COLORS.white,
        ":hover": {
          color: COLORS.primary,
          backgroundColor: "transparent",
          cursor: "pointer"
        }
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: "2px 5px 15px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        overflow: "hidden",
        zIndex: 999,
        position: isMenuRelative ? "relative" : "absolute",
        ...customStyle
      };
    },
    placeholder: (styles) => ({
      ...styles,
      color: COLORS.black,
      fontFamily: "Helvetica-Medium",
      fontSize: "14px"
    }),
    singleValue: (styles) => ({
      ...styles,
      color: textColor
    }),
    indicatorSeparator: () => ({
      display: "none"
    })
  };

  const CustomIcon = ({ innerProps, isDisabled }) =>
    !isDisabled ? (
      <div style={{ marginInline: "4px" }} {...innerProps}>
        {icon}
      </div>
    ) : null;

  const { messages } = useIntl();

  const {
    shared: { none }
  } = messages;

  const getValue = (field) => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) => field.value.indexOf(option.id) >= 0
          )
        : options.find((option) => option.id === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <div className={containerClass}>
      <Field name={name}>
        {({ field, form }) => (
          <div className={styles.wrapper}>
            <Select
              options={
                isAddNoneOption && options.length
                  ? [{ id: "", value: "", label: none }, ...options]
                  : options
              }
              styles={Styles}
              placeholder={
                <>
                  {placeholder}{" "}
                  {isRequired && !label && (
                    <span className="error-msg fsize-14">*</span>
                  )}
                </>
              }
              name={field.name}
              value={getValue(field)}
              onChange={(option) => {
                form.setFieldValue(field.name, option.id);
                _.isFunction(handleSelectChange) &&
                  handleSelectChange(option.id);
              }}
              onBlur={field.onBlur}
              isMulti={isMulti}
              components={{
                DropdownIndicator: CustomIcon
              }}
              isSearchable={isSearchable}
            />
            {label && (
              <label
                className={`${styles.label} ${
                  field.value ? styles.focusState : ""
                }`}
                htmlFor={name}
              >
                {label}
                {isRequired && (
                  <span className="error-msg fsize-14">*</span>
                )}
              </label>
            )}
          </div>
        )}
      </Field>
      <ErrorMessage
        component="div"
        className="error-msg"
        name={name}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  handleSelectChange: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAddNoneOption: PropTypes.bool,
  isMenuRelative: PropTypes.bool,
  icon: PropTypes.node,
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  isSearchable: PropTypes.bool,
  containerClass: PropTypes.string,
  label: PropTypes.string,
  customStyle: PropTypes.object,
  showSelectedValue: PropTypes.bool
};

CustomSelect.defaultProps = {
  name: "",
  placeholder: "",
  options: [],
  isRequired: false,
  isMulti: false,
  isAddNoneOption: false,
  isMenuRelative: false,
  width: "auto",
  isSearchable: true,
  handleSelectChange: () => {},
  containerClass: "",
  label: "",
  customStyle: {},
  showSelectedValue: true
};

export default CustomSelect;
