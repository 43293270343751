import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Typography, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { writtenBy } from "../../../utils/general";
import Certificate from "../../../assets/images/Medblendcertificate1.svg";
import Button from "../../../components/Button";
import { Card as BootstrapCard } from "../../../components/Card/Card";
import styles from "./CertificatesList.module.scss";
import { boxStyle } from "../BookViewer/boxStyle";

const CertificatesList = ({ certificatesList, title, btn_text }) => {
  const user = useSelector((state) => state.auth.login);
  const handleCloseCertificate = () => setOpenCertificateModal(false);
  const [currentCertificate, setCurrentCertificate] = useState();
  const canvasRef = useRef(null);
  const [openCertificateModal, setOpenCertificateModal] =
    useState(false);

  useEffect(() => {
    if (openCertificateModal) {
      setTimeout(() => {
        displayCertificate();
      }, 1000);
    }
  }, [openCertificateModal]);
  const { locale } = useIntl();

  const downloadCertificate = () => {
    var canvas = document.getElementById("canvas");
    var imgData = canvas.toDataURL("image/jpeg", 1.0);
    var pdf = new window.jsPDF();
    pdf.addImage(imgData, "JPEG", 10, 10, 0, 0, null, "FAST", 0);
    pdf.save("download.pdf");
  };

  const displayCertificate = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const usersName = user.first_name + " " + user.second_name;
    const bookName = currentCertificate?.book_title;

    const image = new Image();
    image.src = Certificate;
    ctx.drawImage(image, 0, 0, canvas.width + 2, canvas.height + 2);
    ctx.font = "25px  sans-serif";
    ctx.fillStyle = "#464B55";

    // title
    const demotitle =
      currentCertificate?.certificate.certificate_title;
    ctx.fillText(demotitle, 230, 145);
    // presnst to
    ctx.font = "17px  monospace";

    const demoToUserHint = "This certificate is proudly presented to";

    ctx.fillText(demoToUserHint, 160, 190);
    //username
    ctx.font = "bold italic 30px Arial";
    ctx.fillStyle = "#0e4dce";

    ctx.fillText(usersName, 160, 225);
    // Book
    ctx.font = "12px  Arial";
    ctx.fillStyle = "#464B55";

    ctx.fillText("For Completing", 160, 250);
    ctx.font = "17px Arial";
    ctx.fillStyle = "#0e4dce";
    // separate bookname to 2 line
    if (bookName.length > 63) {
      const demobookNameindex = bookName.lastIndexOf(" ", 64);
      ctx.fillText(bookName.slice(0, demobookNameindex), 160, 275);
      ctx.fillText(
        bookName.slice(demobookNameindex + 1, bookName.length),
        160,
        295
      );
      ctx.font = "italic 10px  Arial";
      ctx.fillStyle = "#464B55";
      ctx.fillText(
        `   by ${currentCertificate?.book_author}`,
        170,
        310
      );
    } else {
      ctx.fillText(bookName, 160, 275);
      ctx.font = "italic 10px  Arial";
      ctx.fillStyle = "#464B55";
      ctx.fillText(
        `   by ${currentCertificate?.book_author}`,
        170,
        295
      );
    }

    const creditHours = currentCertificate?.certificate.credits;
    const creditType = currentCertificate?.certificate.credit_type;

    //CME
    ctx.font = "bold 12px  Arial";
    ctx.fillStyle = "#2e2e2e";
    if (creditHours) {
      ctx.fillText(
        `and has been awarded ${creditHours} ${creditType} credits `,
        160,
        330
      );
    }

    // description
    ctx.font = " 12px  Arial";
    ctx.fillStyle = "#5e5e5e";
    const certificateDescription =
      currentCertificate?.certificate.certificate_description;
    if (certificateDescription.length > 70) {
      const demoDescription1index =
        certificateDescription.lastIndexOf(" ", 71);
      ctx.fillText(
        certificateDescription.slice(0, demoDescription1index),
        160,
        creditHours ? 350 : 330
      );
      ctx.fillText(
        certificateDescription.slice(
          demoDescription1index + 1,
          certificateDescription.length
        ),
        160,
        creditHours ? 370 : 350
      );
    } else {
      ctx.fillText(
        certificateDescription,
        160,
        creditHours ? 350 : 330
      );
    }
  };

  return (
    <div className={`${styles.certificate_container}`}>
      <BootstrapCard
        className={`border-0 my-5 ${styles.certificate_card}`}
        cardBodyClassName={`${styles.card_body}`}
      >
        <p className={`${styles.title} font-B fsize-22 pb-1 my-0`}>
          {title}
        </p>
        <Container>
          {certificatesList?.map((certificate, index) => {
            return (
              <Row
                key={index}
                className={`my-3 ${styles.certificateConatiner}`}
              >
                <Col xs={12} md={12} lg={12}>
                  <Row className={`align-items-center py-3`}>
                    <Col xs={12} md={8} lg={8}>
                      <Row className={`align-items-center py-3`}>
                        <Col
                          xs={12}
                          md={12}
                          lg={4}
                          className={`d-flex justify-content-start ${styles.certificate_author}`}
                        >
                          <img
                            src={Certificate}
                            className={` ${styles.certificate_img}`}
                            alt="Certificate"
                          />
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                          <div>
                            <p
                              className={` mb-1 ${styles.certificate_title} font-B`}
                            >
                              {certificate.book_title}
                              <span
                                className={`mx-1 mb-1 text-capitalize font-M fsize-10 ${styles.certificate_version}`}
                              >
                                &quot;version
                                {certificate.book_version}&quot;
                              </span>
                            </p>
                            <p className="clickable-book__author-name black-mid">
                              {writtenBy(
                                certificate.book_author,
                                locale
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className={`d-flex justify-content-start ${styles.certificate_author}`}
                    >
                      <Button
                        text={btn_text}
                        className="continue_btn"
                        onClick={() => {
                          setCurrentCertificate(certificate);
                          setOpenCertificateModal(true);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Container>
      </BootstrapCard>
      {/* Certificate Modal */}
      <Modal
        open={openCertificateModal}
        onClose={handleCloseCertificate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <canvas
              ref={canvasRef}
              id="canvas"
              height="483px"
              width="700px"
            ></canvas>
          </Typography>
          <a
            className="cursor-pointer"
            onClick={() => downloadCertificate()}
          >
            Download
          </a>
        </Box>
      </Modal>
    </div>
  );
};

CertificatesList.propTypes = {
  certificatesList: PropTypes.array,
  btn_text: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
};

export default CertificatesList;
