import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";

import {
  getInstitutionBooksRequest,
  getInstitutionBooksResponse
} from "../../../store/Books/actions";
import InstitutionBooks from "../../Books/institutionBooks";
import Pagination from "../../../components/Pagination";
import Footer from "../../Footer";
import FeaturedBooks from "../../Books/FeaturedBooks";
import styles from "./InstitutionBooksList.module.scss";

const InstitutionBooksList = () => {
  const { messages } = useIntl();

  const dispatch = useDispatch();

  const institutionBooksList = useSelector(
    (state) => state.books.institutionBooksList
  );
  const meta = useSelector(
    (state) => state.books.institutionBooksMeta
  );

  const [page, setPage] = useState(1);
  const perPage = 6;

  useEffect(() => {
    dispatch(
      getInstitutionBooksRequest({
        page: 1,
        items: perPage,
        paginate: true
      })
    );
    return () => {
      dispatch(getInstitutionBooksResponse({ list: [], meta: {} }));
    };
  }, []);

  const {
    institutionBooks: { title }
  } = messages;

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getInstitutionBooksRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };
  return (
    <>
      <div className={`${styles.certificates_container}`}>
        <div className={`container`}>
          <Row>
            <Col md={8} lg={8}>
              <InstitutionBooks
                books={institutionBooksList}
                title={title}
              />
              {meta.pages > 1 && (
                <div className="d-flex justify-content-end">
                  <div>
                    <Pagination
                      count={meta.pages}
                      page={meta.currentPage || page}
                      handleChange={handlePagination}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col md={4} lg={4} className="py-5">
              <FeaturedBooks />
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default InstitutionBooksList;
