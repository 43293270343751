import Document from "../assets/svgs/Document.svg";

export const numbersOnly = (event) => {
  let key = window.event ? event.keyCode : event.which;
  if (
    event.keyCode == 8 ||
    event.keyCode == 46 ||
    event.keyCode == 37 ||
    event.keyCode == 39 ||
    (key >= 96 && key <= 105) // allow numbers from numpad
  ) {
    return true;
  } else if (key < 48 || key > 57) {
    return event.preventDefault();
  } else return true;
};

export function formatOptions(element, id, label, value) {
  return {
    id: element[id],
    label: element[label],
    value: element[value || id]
  };
}

export const getSupplementaryTypeLabel = (type) => {
  switch (type) {
    case 0:
    case "video":
      return {
        id: 0,
        label: "video",
        icon: <Document fontSize="small" />
      };
    case 1:
    case "image":
      return {
        id: 1,
        label: "image",
        icon: <Document fontSize="small" />
      };
    case 2:
    case "DICOM":
      return {
        id: 2,
        label: "dicom",
        icon: <Document fontSize="small" />
      };
    default:
      break;
  }
};
