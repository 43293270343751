import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import Button from "../../../components/Button";
import styles from "./SurveyBody.module.scss";
import {
  axiosInstance,
  customer_portal
} from "../../../network/apis";
import _ from "lodash";
import { getSingleBookAction } from "../../../store/Books/actions";
export function SurveyBody({
  survey,
  getSurveyData,
  closeSurveyModal,
  singleBook,
  setOpenCertificateModal,
  lastPage,
  id
}) {
  const [answers, setAnswers] = useState([]);
  const [disabledSurvey, setdisabledSurvey] = useState(false);
  const dispatch = useDispatch();
  let answerArr = [];

  const typesOfQuestions = survey?.survey_questions?.map(
    (question) => question?.question_type
  );

  const submitAnAnswer = async () => {
    let payload = {
      survey_id: survey.id,
      answers
    };
    setdisabledSurvey(true);
    try {
      await axiosInstance.post(
        `${customer_portal}/survey_answers`,
        payload
      );
      await getSurveyData();
      dispatch(getSingleBookAction(id));
      if (singleBook.certificate) setOpenCertificateModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const answerOpenEndQuestion = (questionId, text) => {
    if (answers.find((el) => el.survey_question_id === questionId)) {
      answerArr = answers.map((el) => {
        if (el.survey_question_id === questionId) {
          return {
            survey_question_id: questionId,
            open_ended_answer: text
          };
        } else {
          return el;
        }
      });
      setAnswers([...answerArr]);
    } else {
      setAnswers([
        ...answers,
        {
          survey_question_id: questionId,
          open_ended_answer: text
        }
      ]);
    }
  };

  const answerRatingQuestion = (e, questionId) => {
    if (answers.find((el) => el.survey_question_id === questionId)) {
      answerArr = answers.map((el) => {
        if (el.survey_question_id === questionId) {
          return {
            survey_question_id: questionId,
            rating: e.target.value
          };
        } else {
          return el;
        }
      });
      setAnswers([...answerArr]);
    } else {
      setAnswers([
        ...answers,
        {
          survey_question_id: questionId,
          rating: e.target.value
        }
      ]);
    }
  };

  const answerMCQQuestion = (questionId, answerId, type) => {
    if (answers.find((el) => el.survey_question_id === questionId)) {
      answerArr = answers.map((el) => {
        if (el.survey_question_id === questionId) {
          return {
            survey_question_id: questionId,
            survey_question_answer_choice_ids:
              el.survey_question_answer_choice_ids.find(
                (el) => el === answerId
              )
                ? [
                    ...el.survey_question_answer_choice_ids.filter(
                      (el) => el !== answerId
                    )
                  ]
                : type === "single"
                ? [answerId]
                : [...el.survey_question_answer_choice_ids, answerId]
          };
        } else {
          return el;
        }
      });
      setAnswers([...answerArr]);
    } else {
      setAnswers([
        ...answers,
        {
          survey_question_id: questionId,
          survey_question_answer_choice_ids: [answerId]
        }
      ]);
    }
  };

  const updateOpenEndedAnswer = (e, questionId) => {
    answerOpenEndQuestion(questionId, e.target.value);
  };

  let OpenEnded = "Open Ended ";
  return (
    <div className={disabledSurvey ? styles.disabledSurvey : null}>
      <Typography
        id="modal-modal-title"
        variant="h5"
        className="text-center text-primary"
      >
        {survey.title}
      </Typography>
      <p id="modal-modal-title" className="text-gray">
        This Survey contains {_.uniq(typesOfQuestions)?.length} types
        of questions:
        {Array.from(new Set(typesOfQuestions)).map((el, index) => {
          el = el === "OpenEnded" ? OpenEnded : el;
          return index === 0 ? " " + el : " - " + el;
        })}
      </p>
      <div className="text-secondary my-2">
        Please honestly answer the survey question to complete the
        book
      </div>
      {survey.survey_questions
        .sort((q) => q.question_type)
        .map((question, questionId) => (
          <div className="my-2" key={question.id}>
            <div className="d-flex align-items-baseline">
              <p className="me-2 bold mb-0">Q{questionId + 1}-</p>
              <h4 className="mb-0">{question.question} ?</h4>
            </div>
            <div className="d-flex align-items-start flex-column">
              {question.question_type === "MCQ" &&
                question.is_mcq_single_choice === false &&
                question.survey_question_answer_choices.map(
                  ({ answer_choice, id: answerId }) => (
                    <label
                      key={answerId}
                      className="cursor-pointer d-flex align-items-center my-2"
                      htmlFor={`MCQ_${question.id}_${answer_choice}`}
                    >
                      <input
                        id={`MCQ_${question.id}_${answer_choice}`}
                        type="checkbox"
                        value={answer_choice}
                        className="mx-2 mt-1"
                        name={`question_${questionId}`}
                        onChange={() => {
                          answerMCQQuestion(
                            question.id,
                            answerId,
                            "multiple"
                          );
                        }}
                      />
                      <span className="answer-text">
                        {answer_choice}
                      </span>
                    </label>
                  )
                )}
              {question.question_type === "MCQ" &&
                question.is_mcq_single_choice &&
                question.survey_question_answer_choices.map(
                  ({ answer_choice, id: answerId }) => (
                    <label
                      key={answerId}
                      className="cursor-pointer d-flex align-items-center my-2"
                      htmlFor={`MCQ_${question.id}_${answer_choice}`}
                    >
                      <input
                        id={`MCQ_${question.id}_${answer_choice}`}
                        type="radio"
                        value={answer_choice}
                        className="mx-2 mt-1"
                        name={`question_${questionId}`}
                        onChange={() => {
                          answerMCQQuestion(
                            question.id,
                            answerId,
                            "single"
                          );
                        }}
                      />
                      <span className="answer-text">
                        {answer_choice}
                      </span>
                    </label>
                  )
                )}
            </div>

            <div
              className={`d-flex justify-content-between my-2 ${styles.rating}`}
            >
              {question.question_type === "Rating" && (
                <>
                  {[1, 2, 3, 4, 5].map((num) => (
                    <label
                      key={num}
                      className="cursor-pointer d-flex my-2 answer-text"
                      htmlFor={`rate_${question.id}_${num}`}
                    >
                      <input
                        id={`rate_${question.id}_${num}`}
                        type="radio"
                        value={num}
                        className="mx-2"
                        name={`question_${questionId}`}
                        onChange={(e) => {
                          answerRatingQuestion(e, question.id);
                        }}
                      />
                      {num}
                    </label>
                  ))}
                </>
              )}
            </div>

            {question.question_type === "OpenEnded" && (
              <div
                className={`${styles.wrapper} ${styles.openEnded}`}
                id={questionId}
              >
                <textarea
                  placeholder="Your answer"
                  className={`${styles.input} ${styles.textarea}`}
                  rows="6"
                  value={answers[question.id]}
                  onInput={(e) => {
                    updateOpenEndedAnswer(e, question.id, questionId);
                  }}
                />
              </div>
            )}
          </div>
        ))}

      {(survey?.survey_questions
        ?.map((x) => x?.user_answer)
        .every((q) => !!q) ||
        (answers.length === survey?.survey_questions?.length &&
          !answers.find(
            (el) =>
              el?.survey_question_answer_choice_ids?.length === 0
          ) &&
          !answers.find((el) => el?.open_ended_answer === ""))) && (
        <div className="d-flex flex-row-reverse">
          <Button
            text={"Submit Survey"}
            onClick={async () => {
              closeSurveyModal();

              submitAnAnswer();
            }}
          ></Button>
        </div>
      )}
    </div>
  );
}

SurveyBody.propTypes = {
  survey: PropTypes.object,
  getSurveyData: PropTypes.func,
  closeSurveyModal: PropTypes.func,
  singleBook: PropTypes.object,
  setOpenCertificateModal: PropTypes.func,
  lastPage: PropTypes.any,
  id: PropTypes.any
};
