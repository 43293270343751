import * as types from "./types";

export const getBooksAction = (data) => ({
  type: types.GET_BOOKS,
  payload: data
});
export const getAssignedBooksAction = (data) => ({
  type: types.GET_AUTHOR_ASSIGNED_BOOKS,
  payload: data
});
export const getEditoeAssignedBooksAction = (data) => ({
  type: types.GET_EDITOR_ASSIGNED_BOOKS,
  payload: data
});

export const setBooksAction = (data) => ({
  type: types.SET_BOOKS,
  payload: data
});

export const setBooksFeaturedAction = (data) => ({
  type: types.SET_BOOKS_FEATURED,
  payload: data
});

export const setBooksFilters = (data) => ({
  type: types.SET_BOOKS_FILTERS,
  payload: data
});

export const getRelatedBooksAction = (data) => ({
  type: types.GET_RELATED_BOOKS,
  payload: data
});

export const setRelatedBooksAction = (data) => ({
  type: types.SET_RELATED_BOOKS,
  payload: data
});

export const getSingleBookAction = (data) => ({
  type: types.GET_SINGLE_BOOK,
  payload: data
});
export const getAuthorSingleBookAction = (data) => ({
  type: types.GET_AUTHOR_SINGLE_BOOK,
  payload: data
});
export const getEditorSingleBookAction = (data) => ({
  type: types.GET_EDITOR_SINGLE_BOOK,
  payload: data
});

export const setSingleBookAction = (data) => ({
  type: types.SET_SINGLE_BOOK,
  payload: data
});

export const getSingleBookSupplementariesAction = (data) => ({
  type: types.GET_SINGLE_BOOK_SUPPLEMENTRAIES,
  payload: data
});

export const setSingleBookSupplementariesAction = (data) => ({
  type: types.SET_SINGLE_BOOK_SUPPLEMENTRAIES,
  payload: data
});

export const createBookTestsStatus = (data) => {
  return {
    type: types.CREATE_BOOK_TESTS_STATUS,
    payload: data
  };
};

export const updateBookTestsStatus = (data) => {
  return {
    type: types.UPDATE_BOOK_TESTS_STATUS,
    payload: data
  };
};

export const removeUpdateBookTestsStatus = (data) => {
  return {
    type: types.REMOVE_UPDATE_BOOK_TESTS_STATUS,
    payload: data
  };
};

// Fake Subscribtion

export const setSubscribeBookSelected = (payload) => ({
  type: types.SET_SUBSCRIBE_BOOK_SELECTED,
  payload
});

export const unsubscribeBook = (payload) => ({
  type: types.UNSUBSCIBE_BOOK,
  payload
});

export const addSubscibedBookAction = (payload) => ({
  type: types.ADD_SUBSCIBED_BOOK_ACTION,
  payload
});

export const setSubscibedBooksAction = (data) => ({
  type: types.SET_SUBSCIBED_BOOKS_ACTION,
  payload: data
});

export const getSubscribedBooks = () => ({
  type: types.GET_SUBSCRIBED_BOOKS
});

export const getSubscribedBooksRequest = (data) => ({
  type: types.GET_SUBSCRIBED_BOOKS_REQUEST,
  payload: data
});

export const setCurrentSubscriptionLoader = (data) => ({
  type: types.SET_CURRENT_SUBSCRIPTION_LOADER,
  payload: data
});

export const getSubscribedBooksResponse = (data) => ({
  type: types.GET_SUBSCRIBED_BOOKS_RESPONSE,
  payload: data
});

export const setSubscribedBooksIds = (payload) => ({
  type: types.SET_SUBSCRIBED_BOOKS_IDS,
  payload
});

export const updateBookProgress = (payload) => ({
  type: types.UPDATE_BOOK_PROGRESS,
  payload
});

export const updateBookSupplementaryProgress = (payload) => ({
  type: types.UPDATE_BOOK_SUPPLEMENTARY_PROGRESS,
  payload
});

export const getBookSupplementaryProgress = (payload) => ({
  type: types.GET_BOOK_SUPPLEMENTARY_PROGRESS,
  payload
});

export const setBookSupplementaryProgress = (payload) => ({
  type: types.SET_BOOK_SUPPLEMENTARY_PROGRESS,
  payload
});

export const getBookQuizzes = (payload) => ({
  type: types.GET_BOOK_QUIZZES,
  payload
});

export const setBookQuizzes = (payload) => ({
  type: types.SET_BOOK_QUIZZES,
  payload
});

export const getCertainQuiz = (payload) => ({
  type: types.GET_CERTAIN_QUIZ,
  payload
});
export const setCertainQuiz = (payload) => ({
  type: types.SET_CERTAIN_QUIZ,
  payload
});

export const getBookTestsResponse = (payload) => ({
  type: types.GET_BOOK_TESTS_RESPONSE,
  payload
});
export const getBookTestsRequest = (payload) => ({
  type: types.GET_BOOK_TESTS_REQUEST,
  payload
});

export const getBookSingleTestRequest = (payload) => ({
  type: types.GET_BOOK_SINGLE_TEST_REQUEST,
  payload
});

export const getBookSingleTestResponse = (payload) => ({
  type: types.GET_BOOK_SINGLE_TEST_RESPONSE,
  payload
});

export const answerBookSingleTestRequest = (payload) => ({
  type: types.ANSWER_BOOK_SINGLE_TEST_REQUEST,
  payload
});

export const answerBookSingleTestResponse = (payload) => ({
  type: types.ANSWER_BOOK_SINGLE_TEST_RESPONSE,
  payload
});

export const getCertificatetsRequest = (payload) => ({
  type: types.GET_CERTIFICATE_REQUEST,
  payload
});

export const getcertificatetsResponse = (payload) => ({
  type: types.GET_CERTIFICATE_RESPONSE,
  payload
});

export const getInstitutionBooksRequest = (payload) => ({
  type: types.GET_INSTITUTION_BOOKS_REQUEST,
  payload
});

export const getInstitutionBooksResponse = (payload) => ({
  type: types.GET_INSTITUTION_BOOKS_RESPONSE,
  payload
});
export const setEditorNotes = (payload) => ({
  type: types.SET_EDITOR_NOTES,
  payload
});
export const getEditorNotes = (payload) => ({
  type: types.GET_EDITOR_NOTES,
  payload
});
