import * as React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Box, LinearProgress } from "@mui/material";

export default function LinearProgressWithPagesLeft({
  currentProgress,
  totalPages
}) {
  const { messages } = useIntl();
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        variant="determinate"
        value={(currentProgress / totalPages) * 100}
      />
      <span>
        {totalPages - currentProgress} {messages.books.pagesLeft}
      </span>
    </Box>
  );
}

LinearProgressWithPagesLeft.propTypes = {
  currentProgress: PropTypes.any,
  totalPages: PropTypes.any
};
