import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import History from "../../routes/History";
import verifyImage from "../../assets/images/reset-password.png";
import "./Home.scss";
import { resendVerificationEmail } from "../../store/Auth/actions";

const VerifyYourAccount = () => {
  const login = useSelector((state) => state.auth.login);
  const { messages } = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (login.email_verified || !login?.email) {
      History.push("/");
    }
  }, [login]);

  const handleResend = () => {
    dispatch(resendVerificationEmail());
  };
  return (
    <div className="messageWrapper text-center">
      <div className="p-5">
        <h3 className="mb-3">
          {messages.login.youNeedToVerifyYourEmail}
        </h3>
        <img src={verifyImage} />
      </div>
      <button className="btn btn-primary" onClick={handleResend}>
        {messages.login.resendverificationemail}
      </button>
    </div>
  );
};

export default VerifyYourAccount;
