import axios from "axios";
import {
  requestHandler,
  successHandler,
  errorHandler
} from "../interceptors";

export const customer_portal = "/customer_portal/v1";
export const author_portal = "/author_portal/v1";
export const editor_portal = "/editor_portal/v1";
export const axiosInstance = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
  });
})();
// Handle request process
axiosInstance.interceptors.request.use((request) =>
  requestHandler(request)
);
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
