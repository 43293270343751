import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";

import { axiosInstance, customer_portal } from "../../network/apis";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/PathsNames";

import {
  getSubscribedBooksRequest,
  setCurrentSubscriptionLoader
} from "../../store/Books/actions";

const PayPalButtonDefault = () => {
  const dispatch = useDispatch();

  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );

  return (
    <PayPalButton
      options={{
        vault: true,
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD"
      }}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          plan_id: subscribeBookSelected.paypal_plan_id
        });
      }}
      onApprove={(data, actions) => {
        return actions.subscription
          .get()
          .then(function () {
            axiosInstance.post(
              `${customer_portal}/book_subscriptions`,
              {
                payment_gateway: "paypal",
                book_id: subscribeBookSelected.id,
                subscription_id: data.subscriptionID
              }
            );
          })
          .then(() => {
            // This is the loading key
            dispatch(
              setCurrentSubscriptionLoader({
                id: subscribeBookSelected.id
              })
            );
          })
          .then(() => {
            dispatch(getSubscribedBooksRequest({}));
            sessionStorage.removeItem("bookToBuy");
            history.push(
              `${ROUTE_PATHS.bookProfile}/${subscribeBookSelected.id}`
            );
          });
      }}
      style={{
        shape: "rect",
        color: "gold",
        layout: "vertical",
        label: "pay"
      }}
    />
  );
};

export default PayPalButtonDefault;
