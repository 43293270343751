import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem } from "@mui/material";
import CategoriesListIcon from "../../assets/svgs/categories-list-icon";
import useOutSideClickDetector from "../../hooks/useOutSideClickDetector";

import "./CategoriesMenu.scss";

const ITEM_HEIGHT = 48;

export default function CategoriesListIconMenu({
  categories,
  setSelectedCategory,
  selectedCategoryId
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const categoryListRef = useRef();
  useOutSideClickDetector(categoryListRef, () => {
    handleSelectAndClose(null);
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!categories?.length) {
      event.preventDefault();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleSelectAndClose = (id) => {
    setSelectedCategory(id);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <CategoriesListIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        ref={categoryListRef}
        className="homeListBookMenu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleSelectAndClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClick={handleSelectAndClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "300px"
          }
        }}
      >
        {categories?.length &&
          categories?.map((category) => (
            <MenuItem
              onClick={() => handleSelectAndClose(category?.id)}
              key={category?.id}
              selected={category?.id === selectedCategoryId}
            >
              {category?.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

CategoriesListIconMenu.propTypes = {
  categories: PropTypes.array,
  setSelectedCategory: PropTypes.func,
  selectedCategoryId: PropTypes.any
};
