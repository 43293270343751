import { put, call } from "redux-saga/effects";
import { metaObject } from "../../utils/general";
import * as apis from "../../network/apis/countries";
import { getCountriesResponse } from "./actions";

export function* getCountriesSaga({ payload }) {
  try {
    const response = yield call(apis.getCountries, payload);
    yield put(
      getCountriesResponse({
        list: response.data.countries,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}
