import * as types from "./types";

export const getJobsRequest = (data) => ({
  type: types.GET_JOBS_REQUEST,
  payload: data
});

export const getJobsResponse = (data) => ({
  type: types.GET_JOBS_RESPONSE,
  payload: data
});
