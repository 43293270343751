import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import AuthLayout from "../../../components/AuthLayout";
import { useParams, useHistory } from "react-router";
import {
  getBookTestsRequest,
  getSingleBookSupplementariesAction,
  getBookQuizzes,
  getBookSupplementaryProgress
} from "../../../store/Books/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import videoiconBW from "../../../assets/svgs/VideoiconBW.svg";
import completedIcon from "../../../assets/svgs/CompletedIcon.svg";
import styles from "../AllBookSupplementaries/bookSupplementaries.module.scss";
import BackButton from "../../../components/BackButton";
import Collapse from "react-bootstrap/Collapse";
import Footer from "../../Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AllBookSupplementaries = () => {
  const { messages } = useIntl();
  const {
    books: {
      lectures,
      tests,
      videos,
      video,
      chapter,
      mins,
      courseLectures
    }
  } = messages;
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    books: {
      singleBookSupplementaries,
      singleBookTests,
      SupplementariesProgress
    }
  } = useSelector((state) => state);
  const renderRegisterForm = () => {
    useEffect(() => {
      dispatch(getSingleBookSupplementariesAction({ book_id: id }));
      dispatch(getBookTestsRequest({ book_id: id }));
      dispatch(getBookQuizzes({ book_id: id, page: -1 }));
      dispatch(getBookSupplementaryProgress({ book_id: +id }));
    }, []);

    const [open, setOpen] = useState([]);
    const handleToggle = (index) => {
      setOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        if (newOpen[index] === undefined) newOpen[index] = true;
        else newOpen[index] = !newOpen[index];
        return newOpen;
      });
    };

    const sortedTests = singleBookTests.sort(
      (a, b) => a.pre_page - b.pre_page
    );

    const chapterSupplementaries = sortedTests.map((test) => {
      return singleBookSupplementaries.filter(
        (video) =>
          video.book_page >= test.pre_page &&
          video.book_page <= test.post_page
      );
    });

    const handleTestButtonClick = () => {
      const route = `${ROUTE_PATHS["bookAllTests"]}/${id}`;
      history.push(route);
    };

    const goToBookViewer = () => {
      const route = `${ROUTE_PATHS["bookViewer"]}/${id}`;
      history.push(route);
    };

    const [videoDurations, setVideoDurations] = useState([]);

    useEffect(() => {
      let isMounted = true;

      const processVideos = async (videos) => {
        const durations = [];

        for (const video of videos) {
          for (const supplementary of video.supplementary_materials) {
            const src = supplementary.url;
            const vidId = supplementary.id;
            durations.push({ id: vidId, duration: 0 });
            try {
              const duration = await getVideoDuration(src);
              const index = durations.findIndex(
                (item) => item.id === vidId
              );
              durations[index].duration = duration;
            } catch (error) {
              //console.error("Error loading video:", src, error);
            }
          }
        }

        setVideoDurations((prevState) => [
          ...prevState,
          ...durations
        ]);
      };

      const getVideoDuration = async (src) => {
        return new Promise((resolve, reject) => {
          let videoElement = document.createElement("video");
          videoElement.preload = "metadata";
          videoElement.setAttribute("src", src);
          videoElement.onloadedmetadata = function () {
            const duration = videoElement.duration;
            videoElement = null;
            resolve(duration);
          };
          videoElement.onerror = function (event) {
            // console.error("Error loading video:", src, event);
            videoElement = null;
            reject(event);
          };
        });
      };

      const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
          chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
      };

      const MAX_CONCURRENT_REQUESTS = 2;
      const chunkedVideos = chunkArray(
        singleBookSupplementaries,
        MAX_CONCURRENT_REQUESTS
      );

      if (isMounted) {
        // Processing videos sequentially
        (async () => {
          for (const videosChunk of chunkedVideos) {
            await processVideos(videosChunk);
          }
        })();
      }

      return () => {
        isMounted = false;
      };
    }, [singleBookSupplementaries]);

    function formatTime(seconds) {
      if (!seconds) return `--:-- ${mins}`;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedTime = `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds} ${mins}`;
      return formattedTime;
    }

    return (
      <>
        <div className={styles.header}>
          <div className="my-auto d-flex">
            <BackButton
              otherWise={goToBookViewer}
              className={`my-auto me-4 ${styles.backButton}`}
              icon={"b"}
            />
            <span className={styles.headerText}>
              {courseLectures}
            </span>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={`${styles.button} ${styles.buttonLeft}  ${styles.buttonSelected}`}
            >
              {lectures}
            </button>
            <button
              className={`${styles.button} ${styles.buttonRight}`}
              onClick={handleTestButtonClick}
            >
              {tests}
            </button>
          </div>
        </div>
        {sortedTests?.map((test, index) => (
          <div className={styles.chapterContainer} key={test.id}>
            <div className={styles.pagesContainer}>
              {test.pre_page} - {test.post_page}
            </div>
            <div
              className={`${styles.outerSupplementariesContainer} ${
                index == 0 ? styles.firstOuter : ""
              } ${
                index == sortedTests.length - 1
                  ? styles.lastOuter
                  : ""
              }`}
            >
              <div className={styles.supplementariesContainer}>
                <div className={styles.chapterTitleContainer}>
                  <span
                    className={styles.chapterTitle}
                  >{`${chapter} ${index + 1} : ${test.title}`}</span>
                </div>
                <div className={styles.supplementariesNameContainer}>
                  <img src={videoiconBW} />
                  <span className={styles.supplementaryName}>
                    {chapterSupplementaries[index].length}{" "}
                    {chapterSupplementaries[index].length >= 1
                      ? videos
                      : video}
                  </span>
                  <div
                    className={`${styles.dropDownContainer} ${
                      styles.transitionicon
                    } ${open[index] ? styles.rotateup : ""}`}
                    onClick={() => {
                      handleToggle(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                </div>
              </div>
              <Collapse in={open[index]}>
                <div
                  className={`${styles.thinScrollBar} ${styles.innerListContainer}`}
                >
                  {chapterSupplementaries[index]?.length ? (
                    chapterSupplementaries[index].map((book) =>
                      book?.supplementary_materials?.map(
                        (supplementary, i) => (
                          <div
                            key={i}
                            className={`row ${styles.innerListItem}`}
                          >
                            <span
                              className={`col-8
                                ${styles.innerListItemContainer}`}
                            >
                              {SupplementariesProgress[
                                supplementary
                                  .supplementary_material_id
                              ] ? (
                                <img
                                  src={completedIcon}
                                  className={`mx-3 ${styles.icon}`}
                                />
                              ) : (
                                <img
                                  src={videoiconBW}
                                  className={`mx-3 ${styles.icon}`}
                                />
                              )}
                              <Link
                                className={`${styles.supplementary_title}`}
                                to={`${ROUTE_PATHS["supplementary"]}/${supplementary.id}/${id}`}
                              >
                                {supplementary.material_title}
                              </Link>
                            </span>
                            <p
                              className={`col-4 ${styles.supplemenatry_duration} m-0`}
                            >
                              {`${formatTime(
                                videoDurations.find(
                                  (e) => e.id == supplementary.id
                                )?.duration
                              )}`}
                            </p>
                          </div>
                        )
                      )
                    )
                  ) : (
                    <p className="fsize-20 text-center">
                      No Book Supplementaries are added yet
                    </p>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        ))}
      </>
    );
  };
  return (
    <>
      <AuthLayout body={renderRegisterForm()} />
      <Footer />{" "}
    </>
  );
};

export default AllBookSupplementaries;
