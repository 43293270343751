import { put, call } from "redux-saga/effects";
import { metaObject } from "../../utils/general";
import * as apis from "../../network/apis/jobs";
import { getJobsResponse } from "./actions";

export function* getJobsSaga({ payload }) {
  try {
    const response = yield call(apis.getJobs, payload);
    yield put(
      getJobsResponse({
        list: response.data.user,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}
