import React from "react";

const CategoriesListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      className="cursor-pointer"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-219.000000, -101.000000) translate(0.000000, 80.000000) translate(219.500000, 21.500000)">
              <rect width="40" height="40" fill="#FFF" rx="8" />
              <g transform="translate(11.500000, 10.500000)">
                <circle
                  cx="3.409"
                  cy="4.339"
                  r="3.413"
                  fill="#0E4DCE"
                />
                <circle
                  cx="3.409"
                  cy="13.781"
                  r="3.413"
                  fill="#D24EC6"
                />
                <circle
                  cx="13.24"
                  cy="4.339"
                  r="3.413"
                  fill="#D24EC6"
                />
                <circle
                  cx="13.24"
                  cy="13.781"
                  r="3.413"
                  fill="#0E4DCE"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CategoriesListIcon;
