import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { Box, Typography, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// import { subscriptionStatus } from "../../../utils/Constants";
import CloseButtonIcon from "../../../../assets/svgs/close-button-icon";
import Certificate from "../../../../assets/images/Medblendcertificate1.svg";
import BookCover from "../../../../components/BookCover";
import Button from "../../../../components/Button";
import Pagination from "../../../../components/Pagination";
import { Card as BootstrapCard } from "../../../../components/Card/Card";
import BooksNameAndAuthor from "../../BookNameAndAuthor";
import { ROUTE_PATHS } from "../../../../utils/PathsNames";
import {
  getSubscribedBooksRequest,
  getSubscribedBooksResponse,
  unsubscribeBook
} from "../../../../store/Books/actions";
import styles from "./BooksSubscribed.module.scss";
import { boxStyle } from "../../BookViewer/boxStyle";

const UserSubscribedBookList = ({
  books,
  title,
  subTitle,
  btn_text,
  isSubscriptionlist
}) => {
  const user = useSelector((state) => state.auth.login);
  const handleCloseCertificate = () => setOpenCertificateModal(false);
  const [currentBook, setCurrentBook] = useState();
  const canvasRef = useRef(null);
  const [openCertificateModal, setOpenCertificateModal] =
    useState(false);

  const downloadCertificate = () => {
    var canvas = document.getElementById("canvas");
    var imgData = canvas.toDataURL("image/jpeg", 1.0);
    var pdf = new window.jsPDF();
    pdf.addImage(imgData, "JPEG", 10, 10, 0, 0, null, "FAST", 0);
    pdf.save("download.pdf");
  };

  const displayCertificate = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const usersName = user.first_name + " " + user.second_name;
    const bookName = currentBook?.title;
    const bookauthor = currentBook?.author;

    const image = new Image();
    image.src = Certificate;
    ctx.drawImage(image, 0, 0, canvas.width + 2, canvas.height + 2);
    ctx.font = "25px  sans-serif";
    ctx.fillStyle = "#464B55";

    // title
    const demotitle = currentBook?.certificate.certificate_title;
    ctx.fillText(demotitle, 230, 145);
    // presnst to
    ctx.font = "17px  monospace";

    const demoToUserHint = "This certificate is proudly presented to";

    ctx.fillText(demoToUserHint, 160, 190);
    //username
    ctx.font = "bold italic 30px Arial";
    ctx.fillStyle = "#0e4dce";

    ctx.fillText(usersName, 160, 225);
    // Book
    ctx.font = "12px  Arial";
    ctx.fillStyle = "#464B55";

    ctx.fillText("For Completing", 160, 255);
    ctx.font = "17px Arial";
    ctx.fillStyle = "#0e4dce";
    // separate bookname to 2 line
    if (bookName.length > 63) {
      const demobookNameindex = bookName.lastIndexOf(" ", 64);
      ctx.fillText(bookName.slice(0, demobookNameindex), 160, 275);
      ctx.fillText(
        bookName.slice(demobookNameindex + 1, bookName.length),
        160,
        295
      );
      ctx.font = "italic 10px  Arial";
      ctx.fillStyle = "#464B55";
      ctx.fillText(`   by ${bookauthor}`, 170, 310);
    } else {
      ctx.fillText(bookName, 160, 275);
      ctx.font = "italic 10px  Arial";
      ctx.fillStyle = "#464B55";
      ctx.fillText(`   by ${bookauthor}`, 170, 295);
    }

    const creditHours = currentBook?.certificate.credits;
    const creditType = currentBook?.certificate.credit_type;

    //CME
    ctx.font = "bold 12px  Arial";
    ctx.fillStyle = "#2e2e2e";

    if (creditHours) {
      ctx.fillText(
        `and has been awarded ${creditHours} ${creditType} credits `,
        160,
        330
      );
    }

    // description
    ctx.font = " 12px  Arial";
    ctx.fillStyle = "#5e5e5e";
    const certificateDescription =
      currentBook?.certificate.certificate_description;
    if (certificateDescription.length > 70) {
      const demoDescription1index =
        certificateDescription.lastIndexOf(" ", 71);
      ctx.fillText(
        certificateDescription.slice(0, demoDescription1index),
        160,
        creditHours ? 350 : 330
      );
      ctx.fillText(
        certificateDescription.slice(
          demoDescription1index + 1,
          certificateDescription.length
        ),
        160,
        creditHours ? 370 : 350
      );
    } else {
      ctx.fillText(
        certificateDescription,
        160,
        creditHours ? 350 : 330
      );
    }
  };
  useEffect(() => {
    if (openCertificateModal) {
      setTimeout(() => {
        displayCertificate();
      }, 1000);
    }
  }, [openCertificateModal]);

  const [
    openCancelSubscriptionModal,
    setOpenCancelSubscriptionModal
  ] = useState(false);

  const { messages } = useIntl();

  const {
    buttons: { cancel },
    books: { ViewCertificate },
    userBookList: {
      buttonStart,
      buttonContinue,
      completed,
      unSubscrribeHint,
      Unsubscribe,
      cancelSubscription
    }
  } = messages;

  const dispatch = useDispatch();

  const [selectedBook, setSelectedBook] = useState(null);
  const [reason, setReason] = useState("");
  const [page, setPage] = useState(1);
  const perPage = 4;

  const meta = useSelector((state) => state.books.subscibedBooksMeta);

  useEffect(() => {
    dispatch(
      getSubscribedBooksRequest({
        page: 1,
        items: 4,
        paginate: true
      })
    );
  }, []);

  const unsubscribeSelectedBook = () => {
    dispatch(unsubscribeBook({ id: selectedBook?.[0]?.id, reason }));
    setSelectedBook(null);
    setReason("");
    setOpenCancelSubscriptionModal(false);
  };

  useEffect(() => {
    return () => {
      dispatch(getSubscribedBooksResponse({ list: [], meta: {} }));
    };
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getSubscribedBooksRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };
  return (
    <div className={`${styles.subscription_management_container}`}>
      <BootstrapCard
        className={`border-0 my-5 ${styles.subscription_management_card}`}
        cardBodyClassName={`${styles.card_body}`}
      >
        <img
          src={Certificate}
          className="d-none"
          style={{ height: 0, width: 0 }}
        />
        <p className={`${styles.title} fsize-22 pb-1 my-0`}>
          {title}
        </p>
        <p className={`${styles.subTitle} fsize-14 py-0 my-0`}>
          {subTitle}
        </p>
        {books?.map((book, index) => {
          let btn_state_text = btn_text;
          if (isSubscriptionlist) {
            btn_state_text = cancelSubscription;
          }

          const currentBook = {
            ...book.book,
            subscriptionId: book.id,
            subscription_end_date: book.subscription_end_date
          };

          return (
            <Row
              key={index}
              className={`my-3 align-items-center justify-content-between ${styles.bookSingleConatiner}`}
            >
              <Col xs={12} md={8} lg={8}>
                <Row className={`align-items-center py-3`}>
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    className={`d-flex justify-content-start ${styles.book_name_author}`}
                  >
                    <BookCover
                      size="subscriped_books_size"
                      src={currentBook.cover_photo}
                    />
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <BooksNameAndAuthor
                      clickable
                      book={{
                        id: currentBook.id,
                        title: currentBook.title,
                        author: currentBook.author
                      }}
                      titleClass={`${styles.book_title}`}
                    />
                    {currentBook?.book_progress?.status ===
                      "completed" && (
                      <p>
                        <FontAwesomeIcon
                          color="blue"
                          icon={faCheckCircle}
                        />

                        <span className="m-1"></span>
                        {completed}
                      </p>
                    )}
                  </Col>
                </Row>
                {!isSubscriptionlist &&
                  currentBook?.book_progress?.status ===
                    "in_progress" &&
                  currentBook.book_progress.page > 0 && (
                    <Row>
                      <div className="w-100 my-3">
                        <ProgressBar
                          now={Math.round(
                            (currentBook.book_progress.page /
                              currentBook.number_of_pages) *
                              100
                          )}
                          label={`${Math.round(
                            (currentBook.book_progress.page /
                              currentBook.number_of_pages) *
                              100
                          )}%`}
                        />
                      </div>
                    </Row>
                  )}
              </Col>
              {!isSubscriptionlist && (
                <Col xs={12} md={4} lg={4}>
                  <div className="my-3">
                    <Row className={`mt-5 ${styles.mtSmall0} gap-3`}>
                      {currentBook?.book_progress?.is_survey_solved &&
                        currentBook.certificate &&
                        currentBook?.book_progress?.status ===
                          "completed" && (
                          <Col md={12}>
                            <Button
                              className="secondary-lg"
                              text={ViewCertificate}
                              onClick={() => {
                                setCurrentBook(currentBook);
                                setOpenCertificateModal(true);
                              }}
                            />
                          </Col>
                        )}
                      {currentBook?.book_progress?.status ===
                        "not_started" && (
                        <Col md={12}>
                          <Button
                            text={buttonStart}
                            isLink={true}
                            slug={`${ROUTE_PATHS["bookViewer"]}/${currentBook?.id}`}
                          />
                        </Col>
                      )}
                      {currentBook?.book_progress?.status ===
                        "in_progress" && (
                        <Col md={12}>
                          <Button
                            text={buttonContinue}
                            className="continue_btn"
                            isLink={true}
                            slug={`${ROUTE_PATHS["bookViewer"]}/${currentBook?.id}`}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              )}
              {!isSubscriptionlist ||
                (isSubscriptionlist &&
                  (book.book.can_cancel_subscription ? (
                    <Col
                      lg={4}
                      md={6}
                      sm={6}
                      className="d-flex justify-content-md-end justify-content-start p-0 my-3 my-md-0 my-lg-0"
                    >
                      <Button
                        text={btn_state_text}
                        onClick={() => {
                          if (unsubscribeBook) {
                            setOpenCancelSubscriptionModal(true);
                            setSelectedBook([book, currentBook]);
                          }
                        }}
                        className={`${styles.cancel_subs_btn} fsize-14 w-100 px-2`}
                      />
                    </Col>
                  ) : (
                    <Col
                      lg={4}
                      md={6}
                      sm={6}
                      className="d-flex justify-content-start p-0 my-3 my-md-0 my-lg-0"
                    >
                      <div
                        className={`${styles.unsubscibedStatus} d-flex align-items-center`}
                      >
                        <span> </span>
                        <span>available till</span>
                        <span className="bold">
                          {moment(
                            currentBook.subscription_end_date
                          ).format("DD MM YYYY")}
                        </span>
                      </div>
                    </Col>
                  )))}
            </Row>
          );
        })}
      </BootstrapCard>
      {meta.pages > 1 && (
        <div className="d-flex justify-content-end">
          <div>
            <Pagination
              count={meta.pages}
              page={meta.currentPage || page}
              handleChange={handlePagination}
            />
          </div>
        </div>
      )}
      {/* Certificate Modal */}
      <Modal
        open={openCertificateModal}
        onClose={handleCloseCertificate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <canvas
              ref={canvasRef}
              id="canvas"
              height="483px"
              width="700px"
            ></canvas>
          </Typography>
          <a
            className="cursor-pointer"
            onClick={() => downloadCertificate()}
          >
            Download
          </a>
        </Box>
      </Modal>
      {/* Cancel Subscription Modal */}
      <Modal
        open={openCancelSubscriptionModal}
        onClose={() => setOpenCancelSubscriptionModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <div className="relative p-0">
            <div
              onClick={() => {
                setOpenCancelSubscriptionModal(false);
              }}
              className="cursor-pointer d-flex justify-content-end"
            >
              <CloseButtonIcon />
            </div>
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to unsubscribe from this book ?
          </Typography>

          <textarea
            onInput={(e) => {
              setReason(e.target.value);
            }}
            rows={6}
            style={{ fontFamily: "initial" }}
            cols={70}
            placeholder="Tell us the reason for unsubscribing this book"
            className={styles.textArea}
          />
          <div
            className={
              reason.length > 70
                ? "text-danger fsize-12"
                : "text-secondary fsize-12"
            }
          >
            {reason.length} / 70
          </div>
          <p className={`mb-3 mt-2 ${styles.unSubscrribeHint}`}>
            {unSubscrribeHint}
          </p>
          <div className="d-flex justify-content-center gap-4 mt-3">
            <div className="d-flex flex-row-reverse gap-4 mt-3">
              <Button
                onClick={() => setOpenCancelSubscriptionModal(false)}
                text={cancel}
              />
              <Button
                text={Unsubscribe}
                className="continue_btn"
                disabled={reason?.length < 3 || reason.length > 70}
                onClick={() => {
                  unsubscribeSelectedBook();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

UserSubscribedBookList.propTypes = {
  books: PropTypes.array,
  btn_text: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isSubscriptionlist: PropTypes.bool
};

export default UserSubscribedBookList;
