import React from "react";
import PropTypes from "prop-types";
import { Card as BootstrapCard } from "react-bootstrap";

export const Card = ({
  img,
  children,
  className,
  cardBodyClassName
}) => {
  return (
    <BootstrapCard className={className}>
      {img && (
        <BootstrapCard.Img variant="top" src="holder.js/100px180" />
      )}
      <BootstrapCard.Body className={cardBodyClassName}>
        {children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};
Card.propTypes = {
  img: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cardBodyClassName: PropTypes.string
};

Card.defaultProps = {
  img: "",
  className: ""
};
