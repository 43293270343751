import { alpha } from "@mui/material/styles";

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8)
};

const primary = {
  light: "#0e4dce",
  main: "#0e4dce",
  dark: "#0e4dce",
  contrastText: "#0e4dce"
};
const secondary = {
  light: "#d24ec6",
  main: "#9575cd",
  dark: "#9575cd",
  contrastText: "#fff"
};
const info = {
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  contrastText: "#fff"
};
const success = {
  light: "#34c759",
  main: "#54D62C",
  dark: "#54D62C",
  contrastText: GREY[800]
};
const warning = {
  light: "#ff9800",
  main: "#ED6C02",
  dark: "#e65100",
  contrastText: GREY[800]
};
const error = {
  light: "#b00020",
  main: "#FF4842",
  dark: "#B72136",
  contrastText: "#fff"
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...primary },
  secondary: { ...secondary },
  info: { ...info },
  success: { ...success },
  warning: { ...warning },
  error: { ...error },
  grey: GREY,
  divider: GREY[500_24],
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500]
  },
  background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
