import React from "react";
import PropTypes from "prop-types";

const TitleWithSubtitle = ({ title, subtitle }) => {
  return (
    <div>
      <h2 style={{ fontSize: "2.3vh" }} className="mt-2">
        {title}
      </h2>
      <small
        style={{ fontSize: "2vh" }}
        className="text-black-mid d-block"
      >
        {subtitle}
      </small>
    </div>
  );
};

TitleWithSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default TitleWithSubtitle;
