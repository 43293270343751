import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "../../Footer";
import UserSubscribedBookList from "../../Books/BookSubscribtion/BooksSubscribed";
import FeaturedBooks from "../../Books/FeaturedBooks";
import styles from "./SubscriptionManagement.module.scss";

const SubscriptionManagement = () => {
  const subscibedBooks = useSelector(
    (state) => state.books.subscibedBooks
  );

  return (
    <div className={`${styles.subscriped_books_container}`}>
      <div className={`container`}>
        <Row>
          <Col md={8} lg={8}>
            <UserSubscribedBookList
              title={`Subscription Management`}
              subTitle={""}
              books={subscibedBooks}
              isSubscriptionlist
            />
          </Col>
          <Col md={4} lg={4} className="py-5">
            <FeaturedBooks />
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionManagement;
