import React from "react";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import LockIcon from "../../assets/svgs/lock";
import styles from "./PDFViewer.module.scss";

const PDFViewerSample = ({ pdf, subscribed }) => {
  const renderPage = (props) => {
    const { canvasLayer, annotationLayer, pageIndex } = props;
    return (
      <>
        {
          !subscribed ? (
            pageIndex < 5 && pageIndex < 3 ? (
              <>
                <div onContextMenu={(e) => e.preventDefault()}>
                  {canvasLayer.children}
                </div>
                {annotationLayer.children}
              </>
            ) : (
              <>
                {canvasLayer.children}
                <div className={styles.overlay}>
                  <div className={styles.lockIcon}>
                    <LockIcon />
                  </div>
                </div>
                {annotationLayer.children}
              </>
            )
          ) : (
            <div className="d-flex">
              {canvasLayer.children}
              {canvasLayer.children}
            </div>
          )
          // annotationLayer.children
        }
      </>
    );
  };

  const renderPageSubscribedUser = (props) => {
    const { canvasLayer, annotationLayer } = props;

    return (
      <>
        <div onContextMenu={(e) => e.preventDefault()}>
          {canvasLayer.children}
        </div>
        {annotationLayer.children}
      </>
    );
  };

  return (
    <div className={styles.sampleWrapper}>
      {/* {pdf && (
        <Viewer
          renderPage={
            subscribed ? renderPageSubscribedUser : renderPage
          }
          defaultScale={0.8}
          fileUrl={encodeURI(pdf)}
          renderLoader={(percentages) => (
            <div className="w-100">
              <ProgressBar
                now={Math.round(percentages)}
                label={`${Math.round(percentages)}%`}
              />
            </div>
          )}
        />
      )} */}
    </div>
  );
};

PDFViewerSample.propTypes = {
  canvasLayer: PropTypes.object,
  annotationLayer: PropTypes.object,
  pageIndex: PropTypes.number,
  textLayer: PropTypes.object,
  pdf: PropTypes.string,
  subscribed: PropTypes.object
};

export default PDFViewerSample;
