import { useMemo } from "react";
import PropTypes from "prop-types";
import { memo } from "react";

import PDFViewer from "../../../components/PDFViewer";

const BookPdfViewer = ({
  width,
  availableWidth,
  handleBookMarks,
  singleBook,
  isBookSubscribed,
  setCurrentPage,
  setPagesNumber
}) => {
  const renderPdf = () => {
    return (
      <>
        <PDFViewer
          singleBook={singleBook}
          sendBookMarksToParent={handleBookMarks}
          subscribed={isBookSubscribed()}
          setPagesNumber={setPagesNumber}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };
  const renderPdfViewer = useMemo(() => renderPdf(), [singleBook]);

  return (
    <div className="pdf bg_red">
      <div
        // className="fullViewerBg"
        style={{
          height: "100%",
          width: "inherit",
          backgroundColor: "$black",
          top: 0,
          zIndex: 0
        }}
      >
        {renderPdfViewer}
      </div>
    </div>
  );
};

export default memo(BookPdfViewer);

BookPdfViewer.propTypes = {
  width: PropTypes.number,
  availableWidth: PropTypes.number,
  handleBookMarks: PropTypes.func,
  singleBook: PropTypes.object,
  isBookSubscribed: PropTypes.func,
  setCurrentPage: PropTypes.func,
  setPagesNumber: PropTypes.func
};
