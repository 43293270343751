import React from "react";

const CloseButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="40"
          height="40"
          fill="#FFF"
          rx="8"
          transform="translate(.29 .5)"
        ></rect>
        <path
          stroke="#0E4DCE"
          strokeLinecap="round"
          strokeWidth="2"
          d="M26.04 14.771L14.584 26.228M14.583 14.771L26.04 26.228"
        ></path>
      </g>
    </svg>
  );
};

export default CloseButtonIcon;
