import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="custom-book-loader-component">
      <div className="loader">
        <div className="hover">
          <div className="book-left"></div>
          <div className="book-right"></div>
          <div>
            <ul>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 95 136" fill="currentColor">
                  <path d="M10,0A10,10,0,0,0,0,10V120a10,10,0,0,0,10,10l85,6V6ZM74.79,111,18.93,107a2,2,0,1,1,.28-4L75.07,107a2,2,0,1,1-.28,4Zm0-16L18.93,91a2,2,0,1,1,.28-4L75.07,91a2,2,0,1,1-.28,4Zm0-16L18.93,75a2,2,0,1,1,.28-4L75.07,75a2,2,0,1,1-.28,4Zm0-16L18.93,59a2,2,0,1,1,.28-4L75.07,59a2,2,0,1,1-.28,4Zm0-16L18.93,43a2,2,0,1,1,.28-4L75.07,43a2,2,0,1,1-.28,4Zm0-16L18.93,27a2,2,0,1,1,.28-4L75.07,27a2,2,0,1,1-.28,4Z"></path>
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
