import messages from "../assets/locale/messages";

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function metaObject(response) {
  return {
    currentPage: +response.headers["current-page"],
    count: +response.headers["total-count"],
    pages: +response.headers["total-pages"]
  };
}

export function writtenBy(author) {
  return `${author}`;
}
