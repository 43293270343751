import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getEditorNotes,
  getEditorSingleBookAction
} from "../../../store/Books/actions";
import { CSVLink, CSVDownload } from "react-csv";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownloadTwoTone";

export default function EditorBookNotes() {
  const { bookId } = useParams();
  const dispatch = useDispatch();

  const { messages } = useIntl();
  const {
    EditorNotes: { pagenumber, bookText, authorName, note }
  } = messages;
  const { singleBook } = useSelector((state) => state.books);
  useEffect(() => {
    dispatch(getEditorNotes({ bookId, isNote: true }));
    dispatch(getEditorSingleBookAction(bookId));
  }, []);
  const { notes } = useSelector((state) => state.books);
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    if (notes?.length) {
      const titlesRow = [pagenumber, bookText, note, authorName];
      const data = notes?.map((row, i) => {
        const { pageNumber, quote, text } = row?.note;
        return [pageNumber, quote, text, row?.author_name];
      });
      setCsvData([titlesRow, ...data]);
    }
  }, [notes]);

  return (
    <div className="m-2 table-container">
      <div className="d-flex gap-3 justify-content-between">
        <h3>Book Title: {singleBook?.title}</h3>
        <span className="p-1 rounded border border-2 border-light text-md-left">
          <CSVLink
            data={csvData}
            filename={`book-${singleBook?.title}-${bookId}-notes.csv`}
          >
            <CloudDownloadTwoToneIcon />
            &nbsp;
            {` as csv`}
          </CSVLink>
        </span>
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
            <th>{pagenumber}</th>
            <th>{bookText}</th>
            <th>{note}</th>
            <th>{authorName}</th>
          </tr>
        </thead>
        <tbody>
          {notes?.map((row, i) => {
            const { pageNumber, quote, text } = row?.note;
            return (
              <tr key={row?.id}>
                <td> {pageNumber}</td>
                <td> {quote}</td>
                <td> {text}</td>
                <td> {row?.author_name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
