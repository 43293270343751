import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import {
  setPromocodeResponse,
  setPromocodeNameResponse
} from "../../store/Checkout/actions";
import CheckoutForm from "../../components/CheckoutForm";
import Footer from "../Footer";

import styles from "./Checkout.module.scss";

const Checkout = () => {
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (promocodeDiscount || promocodeDiscount === 0) {
        dispatch(setPromocodeResponse(null));
        dispatch(setPromocodeNameResponse(null));
      }
    };
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={6} xs={12} className="mx-auto mb-5">
            <div className={styles.checkout}>
              <CheckoutForm />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Checkout;
