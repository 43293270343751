import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Input from "../Input";
import styles from "./BooksSearchBox.module.scss";
import { setBooksFilters } from "../../store/Books/actions";

const BooksSearchBox = ({ color }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const booksFilters = useSelector((state) => state.books.filters);

  const {
    books: { welcomeTo, acoreSlogan, whatYouLookFor, findHere }
  } = messages;

  const handleSearchByTerm = ({ search_term }) => {
    dispatch(setBooksFilters({ ...booksFilters, search_term }));
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.textWrapper}`}>
        <p className="fsize-20 text-fw-bold">{welcomeTo}</p>
        <p className={`${styles.slogan}`}>{acoreSlogan}</p>
        <div className={`${styles.search}`}>
          <p>{whatYouLookFor}</p>
          <Formik
            initialValues={{ search_term: null }}
            onSubmit={handleSearchByTerm}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className={styles.searchInputWrapper}>
                  <Input
                    name="search_term"
                    placeholder={findHere}
                    inputIcon={
                      <div className={styles.iconBackGround}>
                        <FontAwesomeIcon
                          icon={faSearch}
                          className={styles.icon}
                          onClick={() => handleSubmit()}
                        />
                      </div>
                    }
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

BooksSearchBox.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string
};

BooksSearchBox.defaultProps = {
  onClick: () => {},
  color: "primary"
};

export default BooksSearchBox;
