import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { setCurrentLang } from "../store/Lang/actions";
import messages from "../assets/locale/messages";
import Header from "./Header";
import Loader from "../components/Loader";
import { Snackbar } from "../components/Snackbar";
import MuiProvider from "../providers/MuiProvider";
import { getSubscribedBooksRequest } from "../store/Books/actions";
import { initCornerstone } from "../utils/Cornerstone";
import { SubscriptionLoader } from "../components/Snackbar/SubscriptionLoader";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.auth.login);
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(setCurrentLang(lang));
    initCornerstone();
  });

  useEffect(() => {
    if (login?.id && login?.email_verified) {
      dispatch(getSubscribedBooksRequest({}));
    }
  }, []);

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale="en"
    >
      <MuiProvider>
        <Router history={history}>
          <Header />
          <SubscriptionLoader />
          <Snackbar />
          <div style={{ backgroundColor: "white" }}>{Routes}</div>
        </Router>
        {isLoading && <Loader />}
      </MuiProvider>
    </IntlProvider>
  );
};

export default App;
