import React from "react";
import PropTypes from "prop-types";
import {
  Col,
  Container,
  Modal as BModal,
  Row
} from "react-bootstrap";
import CloseIcon from "../../assets/svgs/close-icon";
import styles from "./Modal.module.scss";

const Modal = ({
  isOpen,
  title,
  children,
  footer,
  onHide,
  imageSrc,
  header
}) => {
  return (
    <BModal
      show={isOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.wrapper}
      contentClassName={`${styles.content} container`}
      fullscreen={"true"}
      onHide={onHide}
    >
      <Container>
        <Row>
          <Col
            className={header ? styles.body : ""}
            lg={imageSrc ? 7 : 12}
          >
            {header && (
              <BModal.Header className="p-0 border-0 mb-md-30 mb-sm-20">
                <CloseIcon
                  className="cursor-pointer"
                  onClick={onHide}
                />
                <BModal.Title id="contained-modal-title-vcenter">
                  {title}
                </BModal.Title>
              </BModal.Header>
            )}
            <BModal.Body>{children}</BModal.Body>
            {footer && <BModal.Footer>{footer}</BModal.Footer>}
          </Col>
          {imageSrc && (
            <Col
              className={styles.cover}
              lg={5}
              style={{ backgroundImage: `url(${imageSrc})` }}
            ></Col>
          )}
        </Row>
      </Container>
    </BModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  footer: PropTypes.node,
  onHide: PropTypes.func,
  imageSrc: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.bool
};

Modal.defaultProps = {
  isOpen: false,
  onHide: () => {},
  header: true
};

export default Modal;
