import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { editUserInfo } from "../../../store/Auth/actions";
import { uploadToS3 } from "../../../utils/S3";
import CustomSelect from "../../../components/Select";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import AuthLayout from "../../../components/AuthLayout";
import Footer from "../../Footer";
import { formatOptions } from "../../../utils/Helpers";
import { getCountriesRequest } from "../../../store/Countries/actions";
import { getJobsRequest } from "../../../store/Jobs/actions";
import { REGISTER_SCHEMA } from "./validations";
import editIcon from "../../../assets/images/edit_icon.png";
import uploadImg from "../../../assets/svgs/uploadImg.svg";
import { COLORS } from "../../../utils/Theme";
import styles from "./ProfileInfo.module.scss";
import { showHideSnackbar } from "../../../store/Snackbar/actions";
import { showHideLoader } from "../../../store/Loader/actions";

const ProfileInfo = () => {
  const { messages, locale } = useIntl();
  const {
    editUserInfo: {
      header,
      editHeader,
      editUserInfoMessage,
      imageUpload
    },
    inputs: { firstName, lastName, institution, country, job, city },
    buttons: { save }
  } = messages;

  const dispatch = useDispatch();
  const [disableForm, setdisableForm] = useState(true);
  const [profileimage, setProfileImage] = useState("");
  const [image, setImage] = useState("");

  const countries = useSelector((state) => state.countries.list);
  const jobs = useSelector((state) => state.jobs.list);
  const user = useSelector((state) => state.auth.login);

  const getIntialProfileImage = () => {
    setProfileImage(user.image);
  };
  const getIntialValues = () => {
    let initialValues = {
      first_name: user.first_name,
      second_name: user.second_name,
      country_id: user.country_id,
      city: user.city,
      institution: user.institution,
      job_id: user.job.id
    };
    return initialValues;
  };
  const editForm = () => {
    setdisableForm(false);
  };
  useEffect(() => {
    getIntialProfileImage();
    dispatch(getCountriesRequest());
    dispatch(getJobsRequest());
  }, []);

  // NOTE: Dont't move useDropzone before onDrop useCallback this causes a ReferenceError
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/png"
  });

  const handleSubmit = async (values) => {
    if (image) {
      try {
        dispatch(showHideLoader(true));
        await uploadToS3("image", image, image.name).then((data) => {
          const payload = { ...values, image: data.link };
          dispatch(editUserInfo(payload));
        });
      } catch (error) {
        dispatch(
          showHideSnackbar({
            isOpen: true,
            type: "error",
            message: "Something went wrong, Please try again later"
          })
        );
      }
      dispatch(showHideLoader(false));
    } else {
      dispatch(editUserInfo(values));
    }
  };

  const renderRegisterForm = () => (
    <Formik
      initialValues={getIntialValues()}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={REGISTER_SCHEMA(locale)}
    >
      {({ submitForm, isValid }) => (
        <section className="d-flex align-items-start">
          <fieldset disabled={disableForm}>
            <Form
              className={
                disableForm ? styles.disabledFormFeild : null
              }
            >
              <Container>
                <Row>
                  <Col className="mx-auto" sm={12} md={12} lg={10}>
                    <h3 className="mb-4">
                      {disableForm ? header : editHeader}
                    </h3>
                    {disableForm === false && (
                      <p className="mb-5 fsize-14">
                        {editUserInfoMessage}
                      </p>
                    )}
                    <Form>
                      <div
                        className={`mb-4 d-flex flex-row align-items-center ${
                          disableForm ? styles.imageDisabled : null
                        }`}
                      >
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />{" "}
                          <img
                            src={
                              image && !disableForm
                                ? URL.createObjectURL(image)
                                : profileimage
                                ? profileimage
                                : uploadImg
                            }
                            alt="Upload Image"
                            className={styles.personalImage}
                          />
                        </div>
                        {disableForm === false && (
                          <p className="text-gray mx-4 mt-3">
                            {imageUpload}
                          </p>
                        )}
                      </div>
                      <Row>
                        <Col sm={12} md={6}>
                          <Input
                            name="first_name"
                            label={firstName}
                            isRequired={!disableForm}
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Input
                            name="second_name"
                            label={lastName}
                            isRequired={!disableForm}
                          />
                        </Col>
                      </Row>
                      <div className={styles.selectContainer}>
                        <label>
                          {country}{" "}
                          {!disableForm && (
                            <span className="fsize-14">*</span>
                          )}
                        </label>
                        <CustomSelect
                          options={countries.map((country) =>
                            formatOptions(country, "id", "name")
                          )}
                          placeholder={country}
                          name="country_id"
                          bgColor={
                            disableForm
                              ? "#ecf1fb"
                              : COLORS.bgPrimaryLight
                          }
                          textColor={
                            disableForm ? "#999999" : "inheret"
                          }
                          icon={
                            !disableForm ? (
                              <FontAwesomeIcon icon={faChevronDown} />
                            ) : null
                          }
                          isSearchable={true}
                          containerClass="mb-4"
                          isRequired
                        />
                      </div>
                      <Input name="city" label={city} />
                      <Input name="institution" label={institution} />
                      <div className={styles.selectContainer}>
                        <label>
                          {job}{" "}
                          {!disableForm && (
                            <span className="fsize-14">*</span>
                          )}
                        </label>
                        <CustomSelect
                          options={jobs?.map((job) =>
                            formatOptions(job, "id", "job_type")
                          )}
                          placeholder={job}
                          name="job_id"
                          bgColor={
                            disableForm
                              ? "#ecf1fb"
                              : COLORS.bgPrimaryLight
                          }
                          textColor={
                            disableForm ? "#999999" : "inheret"
                          }
                          icon={
                            !disableForm ? (
                              <FontAwesomeIcon icon={faChevronDown} />
                            ) : null
                          }
                          isSearchable={true}
                          containerClass="mb-4"
                          isRequired={!disableForm}
                        />
                      </div>
                      {disableForm === false && (
                        <div className="mt-4 mb-5 d-flex justify-content-center">
                          <Button
                            text={save}
                            className="primary-lg"
                            type="submit"
                            disabled={!isValid}
                            onClick={(e) => {
                              e.preventDefault();
                              submitForm();
                              setdisableForm(true);
                            }}
                          />
                        </div>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Form>
          </fieldset>
          {disableForm === true && (
            <button
              className={styles.edit}
              onClick={() => editForm()}
              data-toggle="tooltip"
              title="edit"
            >
              <img src={editIcon} />
            </button>
          )}
        </section>
      )}
    </Formik>
  );
  return (
    <div>
      <AuthLayout body={renderRegisterForm()} />
      <Footer />
    </div>
  );
};

export default ProfileInfo;
