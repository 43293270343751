import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";

import {
  getCertificatetsRequest,
  getcertificatetsResponse
} from "../../../store/Books/actions";
import CertificatesList from "../../Books/CertificatesList";
import Pagination from "../../../components/Pagination";
import Footer from "../../Footer";
import FeaturedBooks from "../../Books/FeaturedBooks";
import styles from "./Certificates.module.scss";

const Certificates = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const certificatesList = useSelector(
    (state) => state.books.certificateList
  );
  const meta = useSelector(
    (state) => state.books.certificateListMeta
  );

  const [page, setPage] = useState(1);
  const perPage = 6;

  const {
    userCertificatesList: {
      certificatesListTitle,
      certificatesListSubTitle,
      showCertifiacte,
      yourCertificates
    }
  } = messages;
  useEffect(() => {
    document.title = yourCertificates;
    dispatch(
      getCertificatetsRequest({
        page: 1,
        items: perPage,
        paginate: true
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      document.title = "Medblend";
      dispatch(getcertificatetsResponse({ list: [], meta: {} }));
    };
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getCertificatetsRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };

  return (
    <>
      <div className={`${styles.certificates_container}`}>
        <div className={`container`}>
          <Row>
            <Col md={8} lg={8}>
              <CertificatesList
                certificatesList={certificatesList}
                btn_text={showCertifiacte}
                title={certificatesListTitle}
                subTitle={certificatesListSubTitle}
              />
              {meta.pages > 1 && (
                <div className="d-flex justify-content-end">
                  <div>
                    <Pagination
                      count={meta.pages}
                      page={meta.currentPage || page}
                      handleChange={handlePagination}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col md={4} lg={4} className="py-5">
              <FeaturedBooks />
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Certificates;
