import React from "react";
import "react-image-lightbox/style.css";
import MaterialPreview from "../../../components/MaterialPreview";
import QuizPreview from "../../../components/MaterialPreview/QuizPreview";
import TestPreview from "../../../components/MaterialPreview/TestPreview";
import { getSupplementaryTypeLabel } from "../../../utils/Helpers";
import PropTypes from "prop-types";
import "./BookViewer.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import SurveyPreview from "../../../components/MaterialPreview/SurveyPreview";

function MobileSupplementaries({
  singleBookSupplementaries,
  singleBookQuizzes,
  singleBookTests,
  setImageModalFromTest,
  currentPage,
  isBookSubscribed,
  singleBook,
  markTestAsSolved,
  supplementariesContainerRef,
  survey
}) {
  const [hideSupplementeries, setHideSupplementeries] =
    useState(false);
  return (
    <>
      {hideSupplementeries ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "rgba(0, 0, 0, 0.7)"
          }}
          onClick={() => setHideSupplementeries(false)}
        >
          {" "}
          Show supplementaries
        </div>
      ) : (
        <div
          ref={supplementariesContainerRef}
          className="supplementaries_container_mobile"
          onContextMenu={(e) => e.preventDefault()}
          style={{ display: hideSupplementeries ? "none" : "flex" }}
        >
          <div
            style={{
              padding: ".5rem"
            }}
            onClick={() => setHideSupplementeries(true)}
          >
            <CloseIcon />
          </div>
          <div className="supplementaryCol-mobile thinScrollBar">
            {/* Supplementary video demo view for unsubscriber user */}
            {!singleBookSupplementaries.filter(
              (sub) => sub.book_page === 1
            )?.length &&
              currentPage === 1 &&
              !isBookSubscribed() && (
                <div className="supplementaryItem">
                  <MaterialPreview
                    withModal
                    isBookSubscribed={isBookSubscribed()}
                    src={"VIDEO.mp4"}
                    type={getSupplementaryTypeLabel("video")?.id}
                    details={{
                      material_type: "video",
                      book_page: 1,
                      diagnosis: "",
                      id: 207,
                      description: "",
                      material_title: "",
                      url: ["asd.mp4"]
                    }}
                  />
                </div>
              )}
            {isBookSubscribed() &&
              singleBookSupplementaries
                .find((supplementary) => {
                  return supplementary.book_page === currentPage;
                })
                ?.supplementary_materials?.map(
                  (singleSupplementary, index) => (
                    <div key={index} className="supplementaryItem">
                      <MaterialPreview
                        withModal
                        isBookSubscribed={isBookSubscribed()}
                        details={singleSupplementary}
                        src={singleSupplementary.url?.[0]}
                        type={
                          getSupplementaryTypeLabel(
                            singleSupplementary.material_type
                          )?.id
                        }
                        dicomImages={
                          singleSupplementary.material_type ===
                          "DICOM"
                            ? singleSupplementary.url
                            : []
                        }
                      />
                    </div>
                  )
                )}
            {isBookSubscribed() &&
              singleBookQuizzes?.map((quiz, index) => {
                if (quiz.book_page === currentPage) {
                  return (
                    <div key={index} className="supplementaryItem">
                      <QuizPreview
                        quiz={quiz}
                        singleBook={singleBook}
                        currentPage={currentPage}
                        setImageModal={setImageModalFromTest}
                      />
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            {singleBookTests?.map((test, index) => {
              // if (bookSingleTestCanceled[test?.id]) {
              const type = test.pre_page === currentPage ? 0 : 1;
              if (
                (test.pre_page === currentPage ||
                  test.post_page === currentPage) &&
                // bookSingleTestCanceled[test?.id][type] &&
                (type === 1 || !test.isPreTestSolved) &&
                (!test?.title.toLowerCase().includes("final test") ||
                  type == 1)
              ) {
                return (
                  <div key={index} className="supplementaryItem">
                    <TestPreview
                      bookTest={test}
                      testType={type}
                      setImageModal={setImageModalFromTest}
                      markTestAsSolved={markTestAsSolved}
                    />
                  </div>
                );
              }
              // } else {
              //   return "";
              // }
            })}
            {!singleBook?.book_progress?.is_survey_solved &&
              survey &&
              currentPage == singleBook?.number_of_pages && (
                <SurveyPreview />
              )}
          </div>
        </div>
      )}
    </>
  );
}

MobileSupplementaries.propTypes = {
  singleBookSupplementaries: PropTypes.array,
  singleBookQuizzes: PropTypes.array,
  singleBookTests: PropTypes.array,
  setImageModalFromTest: PropTypes.func,
  currentPage: PropTypes.number,
  isBookSubscribed: PropTypes.func,
  singleBook: PropTypes.object,
  markTestAsSolved: PropTypes.func,
  supplementariesContainerRef: PropTypes.object,
  survey: PropTypes.object
};

// MobileSupplementaries.defaultProps = {
//   singleBook: {},
//   sendBookMarksToParent: () => {},
//   isBookSubscribed: false
// };

export default MobileSupplementaries;
