import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { writtenBy } from "../../../utils/general";
import History from "../../../routes/History";

const BooksNameAndAuthor = ({
  book,
  className,
  titleClass,
  clickable
}) => {
  const { title, author } = book;
  const { locale } = useIntl();

  return (
    <div className={className}>
      <p
        className={`fsize-16 mb-1 no-wrap ${titleClass} ${
          clickable ? "cursor-pointer" : ""
        }`}
        onClick={() => History.push(`book-viewer/${book.id}`)}
      >
        {title}
      </p>
      <p className="clickable-book__author-name no-wrap black-mid">
        {writtenBy(author, locale)}
      </p>
    </div>
  );
};
BooksNameAndAuthor.propTypes = {
  book: PropTypes.object,
  className: PropTypes.string,
  titleClass: PropTypes.string,
  clickable: PropTypes.bool
};
export default BooksNameAndAuthor;
