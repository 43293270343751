import { takeEvery } from "redux-saga/effects";
import * as BooksTypes from "./Books/types";
import * as BooksSagas from "./Books/sagas";
import * as CategoriesTypes from "./Categories/types";
import * as CategoriesSagas from "./Categories/sagas";
import * as AuthSagas from "./Auth/sagas";
import * as AuthTypes from "./Auth/types";
import * as CountriesTypes from "./Countries/types";
import * as CountriesSaga from "./Countries/sagas";
import * as JobsTypes from "./Jobs/types";
import * as JobsSaga from "./Jobs/sagas";
import * as CheckoutTypes from "./Checkout/types";
import * as CheckoutSaga from "./Checkout/sagas";

export function* watchAll() {
  yield takeEvery(BooksTypes.GET_BOOKS, BooksSagas.getBooksSaga);
  yield takeEvery(
    BooksTypes.GET_AUTHOR_ASSIGNED_BOOKS,
    BooksSagas.getAuthorAssignedBookSaga
  );
  yield takeEvery(
    BooksTypes.GET_EDITOR_ASSIGNED_BOOKS,
    BooksSagas.getEditorAssignedBookSaga
  );
  yield takeEvery(
    BooksTypes.GET_RELATED_BOOKS,
    BooksSagas.getRelatedBooksSaga
  );
  yield takeEvery(
    BooksTypes.GET_SINGLE_BOOK,
    BooksSagas.getSingeBookSaga
  );
  yield takeEvery(
    BooksTypes.GET_AUTHOR_SINGLE_BOOK,
    BooksSagas.getAuthorSingeBookSaga
  );
  // editor
  yield takeEvery(
    BooksTypes.GET_EDITOR_SINGLE_BOOK,
    BooksSagas.getEditorSingleBookSaga
  );
  yield takeEvery(
    BooksTypes.GET_SUBSCRIBED_BOOKS_REQUEST,
    BooksSagas.getSubscribedBooks
  );
  yield takeEvery(
    BooksTypes.GET_SINGLE_BOOK_SUPPLEMENTRAIES,
    BooksSagas.getSingeBookSupplementariesSaga
  );

  yield takeEvery(
    BooksTypes.UNSUBSCIBE_BOOK,
    BooksSagas.UnubscribeBook
  );
  yield takeEvery(
    BooksTypes.UPDATE_BOOK_PROGRESS,
    BooksSagas.updateBookProgress
  );
  yield takeEvery(
    BooksTypes.UPDATE_BOOK_SUPPLEMENTARY_PROGRESS,
    BooksSagas.updateBookSupplementaryProgress
  );
  yield takeEvery(
    BooksTypes.GET_BOOK_SUPPLEMENTARY_PROGRESS,
    BooksSagas.getBookSupplementaryProgress
  );
  yield takeEvery(
    CategoriesTypes.GET_CATEGORIES_REQUEST,
    CategoriesSagas.getCategoriesSaga
  );
  yield takeEvery(AuthTypes.LOGIN_REQUEST, AuthSagas.loginSaga);
  yield takeEvery(
    AuthTypes.LOGIN_THEN_VERIFY,
    AuthSagas.loginThenVerify
  );
  yield takeEvery(AuthTypes.LOGOUT_REQUEST, AuthSagas.logoutSaga);
  yield takeEvery(
    AuthTypes.NEW_USER_REGISTRATION,
    AuthSagas.newUserRegistration
  );

  yield takeEvery(AuthTypes.EDIT_USER_INFO, AuthSagas.editUserInfo);
  yield takeEvery(
    AuthTypes.CHANGE_PASSWORD_REQUEST,
    AuthSagas.changePassword
  );

  yield takeEvery(
    AuthTypes.RESET_NEW_PASSWORD_REQUEST,
    AuthSagas.restNewPasswordSaga
  );

  yield takeEvery(AuthTypes.USER_DETAILS, AuthSagas.getDetails);

  yield takeEvery(
    AuthTypes.SEND_EMAIL_NEW_PASSWORD,
    AuthSagas.sendEmailNewPasswordSaga
  );

  yield takeEvery(AuthTypes.VERIFY_USER, AuthSagas.verifyUser);

  yield takeEvery(
    AuthTypes.RESEND_VERIFICATION_EMAIL,
    AuthSagas.resendVerificationEmail
  );

  yield takeEvery(
    AuthTypes.SOCIAL_AUTHORIZATION,
    AuthSagas.authorizeViaGoogle
  );
  yield takeEvery(
    CountriesTypes.GET_COUNTRIES_REQUEST,
    CountriesSaga.getCountriesSaga
  );
  yield takeEvery(JobsTypes.GET_JOBS_REQUEST, JobsSaga.getJobsSaga);
  yield takeEvery(
    BooksTypes.GET_BOOK_QUIZZES,
    BooksSagas.getBookQuizzes
  );
  yield takeEvery(
    BooksTypes.GET_CERTAIN_QUIZ,
    BooksSagas.getCertainQuiz
  );
  yield takeEvery(
    BooksTypes.GET_BOOK_TESTS_REQUEST,
    BooksSagas.getBookTestsSaga
  );
  yield takeEvery(
    BooksTypes.GET_BOOK_SINGLE_TEST_REQUEST,
    BooksSagas.getBookSingleTestSaga
  );
  yield takeEvery(
    BooksTypes.ANSWER_BOOK_SINGLE_TEST_REQUEST,
    BooksSagas.answerBookTestSaga
  );

  yield takeEvery(
    BooksTypes.GET_CERTIFICATE_REQUEST,
    BooksSagas.getCertificateList
  );

  yield takeEvery(
    CheckoutTypes.SET_PROMOCODE_REQUEST,
    CheckoutSaga.setPromocodeSaga
  );
  yield takeEvery(
    CheckoutTypes.SET_FREE_DISCOUNT_PROMOCODE_REQUEST,
    CheckoutSaga.setFreeDiscountPromocodeSaga
  );

  yield takeEvery(
    BooksTypes.GET_INSTITUTION_BOOKS_REQUEST,
    BooksSagas.getInstitutionBooksList
  );
  // book notes
  yield takeEvery(
    BooksTypes.GET_EDITOR_NOTES,
    BooksSagas.getEditorNotesByBookSaga
  );
}
