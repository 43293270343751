import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import History from "../../../routes/History";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import Footer from "../../Footer";
import UserSubscribedBookList from "../../Books/BookSubscribtion/BooksSubscribed";
import FeaturedBooks from "../../Books/FeaturedBooks";
import styles from "./BookList.module.scss";

const BookList = () => {
  const { messages } = useIntl();
  const {
    buttons: { read_book },
    userBookList: { booklistTitle, booklistSubTitle }
  } = messages;

  const subscibedBooks = useSelector(
    (state) => state.books.subscibedBooksList
  );

  return (
    <div className={`${styles.subscriped_books_container}`}>
      <div className={`container`}>
        <Row>
          <Col md={8} lg={8}>
            <UserSubscribedBookList
              books={subscibedBooks}
              btn_text={read_book}
              title={booklistTitle}
              subTitle={booklistSubTitle}
              onClick={(id) =>
                History.push(`${ROUTE_PATHS["bookViewer"]}/${id}`)
              }
            />
          </Col>
          <Col md={4} lg={4} className="py-5">
            <FeaturedBooks />
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default BookList;
