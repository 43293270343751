import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CloseButtonIcon from "../../../assets/svgs/close-button-icon";
import Group from "../../../assets/svgs/Group.svg";
import TestSvg from "../../../assets/svgs/Quizicon.svg";
import { boxStyle } from "../../../containers/Books/BookViewer/boxStyle";
import {
  axiosInstance,
  customer_portal
} from "../../../network/apis";
import {
  answerBookSingleTestRequest,
  getBookSingleTestRequest,
  removeUpdateBookTestsStatus,
  updateBookTestsStatus
} from "../../../store/Books/actions";
import {
  SET_OPEN_TEST_MODAL,
  SET_TEST_MODAL_DATA
} from "../../../store/Utilities/types";
import Button from "../../Button";
import MaterialPreviewStyles from "../MaterialPreview.module.scss";

export default function Test({
  bookTest,
  testType,
  setImageModal,
  markTestAsSolved
}) {
  const { messages } = useIntl();
  const {
    test: {
      rightAnswer,
      wrongAnswer,
      score,
      explanation,
      notedescription,
      note,
      title,
      success,
      failed,
      mark,
      sectionscore,
      advice,
      testReport,
      pretest,
      posttest,
      pretestnotsolved,
      finaltest
    },
    buttons: { next, ok, submit }
  } = messages;

  const dispatch = useDispatch();

  const bookSingleTest = useSelector(
    (state) => state.books.bookSingleTest
  );

  const [notTestAllAnsweredOptionl, setNotTestAllAnsweredOptionl] =
    useState(false);
  // const [TestPrePageIconShow, setTestPrePageIconShow] =
  //   useState(true);

  const [testTypeQuestion, setTestTypeQuestion] = useState();
  const [testQuestionAnwseredView, setTestQuestionAnwseredView] =
    useState(false);

  const [isOpen, setOpen] = useState(false);
  useEffect(async () => {
    if (bookTest?.id) {
      await dispatch(getBookSingleTestRequest({ id: bookTest?.id }));
    }
  }, [bookTest]);

  const [isPreTestSolved, setIsPreTestSolved] = useState(false);

  useEffect(async () => {
    if (bookSingleTest?.id) {
      if (bookSingleTest.is_mandatory) {
        bookSingleTest?.test_questions?.every(
          (question) => question[`post_test_user_answer`] !== null
        ) || testType === 1
          ? setTestTypeQuestion("post_")
          : setTestTypeQuestion("pre_");
      } else {
        (bookSingleTest?.test_questions?.every(
          (question) => question[`post_test_user_answer`] !== null
        ) &&
          bookSingleTest?.test_questions?.every(
            (question) => question[`pre_test_user_answer`] !== null
          )) ||
        testType === 1
          ? setTestTypeQuestion("post_")
          : setTestTypeQuestion("pre_");
      }
      if (
        bookSingleTest?.test_questions?.every(
          (question) => question[`pre_test_user_answer`] !== null
        )
      )
        setIsPreTestSolved(true);
    }
  }, [bookSingleTest, testType]);

  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(async () => {
    if (buttonClicked || !bookSingleTest?.id) {
      return;
    }
    const fetchURL = (url) =>
      axiosInstance.get(`${customer_portal}/${url}`);
    const testPromise = fetchURL(`tests/${bookSingleTest?.id}`);

    testPromise
      .then((promiseResult) => {
        console.log("promiseResult", promiseResult);
        const postTestAnswered =
          promiseResult.data.test?.test_questions?.every(
            (question) => !!question["post_test_user_answer"]
          );
        const preTestAnswered =
          promiseResult.data.test?.test_questions?.every(
            (question) => !!question["pre_test_user_answer"]
          );
        setTestQuestionAnwseredView(
          testType == 1 ? postTestAnswered : preTestAnswered
        );
      })
      .catch((err) => {
        // Handle Error
      });
  }, [testQuestionAnwseredView, bookSingleTest?.id]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchData = async () => {
      try {
        const bookTestCheck = await axiosInstance.get(
          `${customer_portal}/tests/${bookTest.id}`
        );

        if (isMounted) {
          bookTestCheck?.data?.test?.test_questions?.every(
            (question) =>
              question?.[
                `${testType === 0 ? "pre_" : "post_"}test_user_answer`
              ]
          ) === false && !bookTestCheck?.data?.test?.is_mandatory
            ? setNotTestAllAnsweredOptionl(true)
            : setNotTestAllAnsweredOptionl(false);

          // if (
          //   testType === 0 &&
          //   bookTestCheck?.test_questions?.every(
          //     (question) => question[`post_test_user_answer`] !== null
          //   )
          // ) {
          //   setTestPrePageIconShow(false);
          // }
        }
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    };

    fetchData(); // Initial data fetch

    // Cleanup function
    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [bookTest, testType]);

  const RenderTooltip = ({ children }) => (
    <OverlayTrigger overlay={<Tooltip>{pretestnotsolved}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );

  const RenderPostTest = () => (
    <div className={MaterialPreviewStyles.wrapper}>
      <div className={MaterialPreviewStyles.quizCover}>
        <button
          className={`${MaterialPreviewStyles.emptyPreview} d-flex w-100 fsize-20`}
          onClick={() => {
            // setOpen(true);
            setModalData();
            setOpenModal(true);
          }}
          disabled={!isPreTestSolved}
        >
          <img src={TestSvg} alt="medblend-image" />
          <h6 className="text-start my-auto">
            {bookSingleTest?.title}{" "}
            {bookSingleTest?.title
              .toLowerCase()
              .includes("final test")
              ? finaltest
              : posttest}
          </h6>
        </button>
      </div>
    </div>
  );

  RenderTooltip.propTypes = {
    children: PropTypes.any
  };

  const getTotalScore = () => {
    const score = bookSingleTest
      ? bookSingleTest[`${testTypeQuestion}test_points`]
      : 0;
    return score ? score : 0;
  };
  const submitAnAnswer = async (questionId, answer) => {
    const payload = {
      test_question_id: questionId,
      test_answer_choice_id: answer,
      test_type: testType
    };
    await dispatch(answerBookSingleTestRequest(payload));
    dispatch(getBookSingleTestRequest({ id: bookTest.id }));
  };

  const setImageModalToBook = (img) => {
    setImageModal({ src: img });
  };

  const setOpenModal = (open) => {
    dispatch({
      type: SET_OPEN_TEST_MODAL,
      payload: open
    });
  };

  const setModalData = () => {
    dispatch({
      type: SET_TEST_MODAL_DATA,
      payload: {
        testTypeQuestion,
        testType,
        setImageModal,
        markTestAsSolved,
        bookTest
      }
    });
  };

  return (
    <>
      {testType === 0 && (
        <>
          <div className={MaterialPreviewStyles.wrapper}>
            <div className={MaterialPreviewStyles.quizCover}>
              <button
                className={`${MaterialPreviewStyles.emptyPreview} d-flex w-100 fsize-20`}
                onClick={() => {
                  // setOpen(true);
                  // add data to test modal
                  setModalData();
                  setOpenModal(true);
                }}
              >
                <img src={TestSvg} alt="medblend-image" />
                <h6 className="text-start my-auto">
                  {bookSingleTest?.title} {pretest}
                </h6>
              </button>
            </div>
          </div>
        </>
      )}
      {testType === 1 && (
        <>
          {isPreTestSolved ? (
            RenderPostTest()
          ) : (
            <RenderTooltip>{RenderPostTest()}</RenderTooltip>
          )}
        </>
      )}
      {/* <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...boxStyle,
            maxHeight: "90%",
            height: "90%",
            width: "45%",
            minWidth: "45%",
            overflowY: "auto"
          }}
        >
          <div
            className={
              testQuestionAnwseredView &&
              bookSingleTest?.sections_results.length > 0 &&
              testType === 1
                ? "relative bg-modal-image"
                : "relative"
            }
          >
            <div
              onClick={() => {
                setOpen(false);
              }}
              className="cursor-pointer d-flex justify-content-end"
            >
              <CloseButtonIcon />
            </div>
            {testQuestionAnwseredView ? (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  className="text-center text-primary mb-4"
                >
                  {bookSingleTest?.title} {title}
                </Typography>
                <>
                  <div>
                    {50 <
                    (getTotalScore() / bookSingleTest?.total_points) *
                      100 ? (
                      <p className="success text-center my-4">
                        {success}
                      </p>
                    ) : (
                      <p className="error text-center my-4">
                        {failed}{" "}
                      </p>
                    )}
                    <div className="precentage mb-4">
                      <CircularProgressbar
                        value={Math.round(
                          (getTotalScore() /
                            bookSingleTest?.total_points) *
                            100
                        )}
                        text={`${Math.round(
                          (getTotalScore() /
                            bookSingleTest?.total_points) *
                            100
                        )}%`}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <div className=" mt-3 mb-4">
                        <Button
                          text={ok}
                          className="continue_btn"
                          onClick={() => {
                            setOpen(false);
                            setNotTestAllAnsweredOptionl(false);
                            setTestQuestionAnwseredView(false);
                            markTestAsSolved(bookSingleTest.id);

                            if (testType === 0)
                              dispatch(
                                removeUpdateBookTestsStatus({
                                  id: bookSingleTest.id,
                                  type: 0
                                })
                              );
                            if (testType === 1)
                              dispatch(
                                updateBookTestsStatus({
                                  id: bookSingleTest.id,
                                  type: testType
                                })
                              );
                          }}
                        />
                        <Button
                          text={"view test"}
                          className="continue_btn"
                          onClick={() => {
                            setTestQuestionAnwseredView(false);
                          }}
                        />
                      </div>
                    </div>
                    {bookSingleTest?.sections_results.length > 0 &&
                      testType === 1 && (
                        <>
                          <Typography
                            variant="p"
                            className="text-gray my-4 d-block"
                          >
                            {testReport}
                          </Typography>{" "}
                          <div className="mt-2 table-container">
                            <table>
                              <tr>
                                <th>{mark}</th>
                                <th>{sectionscore}</th>
                                <th>{advice}</th>
                              </tr>

                              {bookSingleTest?.sections_results?.map(
                                (row, i) => (
                                  <>
                                    <tr key={i}>
                                      <td> {row.name}</td>

                                      <td> {row.percentage}</td>
                                      <td> {row.message}</td>
                                    </tr>
                                  </>
                                )
                              )}
                            </table>
                          </div>{" "}
                        </>
                      )}
                  </div>
                </>
              </>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  className="text-center text-primary"
                >
                  {bookSingleTest?.title}
                </Typography>
                {testType === 1 && (
                  <div className="text-secondary my-3">
                    {score}: {getTotalScore()}/
                    {bookSingleTest?.total_points}
                  </div>
                )}
                {bookSingleTest?.test_questions?.find(
                  (qs) =>
                    !!qs?.image_url ||
                    qs?.test_answer_choices?.some(
                      (ans) => ans.image_url
                    )
                ) && (
                  <div className="note gray mb-3">
                    <span className="grey bold">{note} </span>
                    {notedescription}
                  </div>
                )}

                <div className="note text-primary mb-3">
                  {bookSingleTest?.note}
                </div>
                {bookSingleTest && (
                  <>
                    {bookSingleTest?.test_questions?.map(
                      (question, questionId) => (
                        <div className="mb-2" key={question.id}>
                          <div className=" mb-2">
                            <div className="d-flex align-items-baseline">
                              <p className="me-2 bold mb-0">
                                Q{questionId + 1}-
                              </p>

                              <h4 className="mb-0 ">
                                {question.question} ?
                              </h4>
                            </div>

                            {question.image_url && (
                              <div
                                className="container_image_question mb-2"
                                onClick={() =>
                                  setImageModalToBook(
                                    question.image_url
                                  )
                                }
                              >
                                <img
                                  src={question.image_url}
                                  className="img-thumbnail question-image"
                                />
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      src={Group}
                                      alt="medblend-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-center flex-column ">
                            {question?.test_answer_choices?.map(
                              (
                                {
                                  answer,
                                  id: answerId,
                                  image_url,
                                  correct
                                },
                                index
                              ) => (
                                <React.Fragment key={index}>
                                  <div className="d-flex align-items-center">
                                    <div className="mb-2 answer">
                                      <label
                                        key={answerId}
                                        className="cursor-pointer d-flex align-items-start"
                                        htmlFor={
                                          !image_url
                                            ? `${question.id}_${answerId}`
                                            : ""
                                        }
                                      >
                                        <input
                                          id={`${question.id}_${answerId}`}
                                          type="radio"
                                          value={answer}
                                          disabled={
                                            question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]
                                          }
                                          className="mt-2 mr-11"
                                          name={`question_${questionId}`}
                                          checked={
                                            question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]
                                              ?.test_answer_choice_id ===
                                            answerId
                                          }
                                          onChange={() => {
                                            submitAnAnswer(
                                              question.id,
                                              answerId
                                            );
                                            if (
                                              testType === 0 &&
                                              !bookSingleTest.is_mandatory
                                            )
                                              dispatch(
                                                updateBookTestsStatus(
                                                  {
                                                    id: bookSingleTest.id,
                                                    type: testType
                                                  }
                                                )
                                              );
                                          }}
                                        />
                                        {image_url && (
                                          <div
                                            className="container_image_answer"
                                            onClick={() =>
                                              setImageModalToBook(
                                                image_url
                                              )
                                            }
                                          >
                                            <img
                                              src={image_url}
                                              width="100px"
                                              className="img-thumbnail answer-image"
                                              alt="answer"
                                            />
                                            <div className="middle">
                                              <div className="text">
                                                <img
                                                  height="20px"
                                                  src={Group}
                                                  alt="medblend-image"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        <span className="answer-text">
                                          {answer}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="result">
                                      {question[
                                        `${testTypeQuestion}test_user_answer`
                                      ] && (
                                        <>
                                          {question[
                                            `${testTypeQuestion}test_user_answer`
                                          ]?.correct &&
                                            question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]
                                              ?.test_answer_choice_id ===
                                              answerId &&
                                            testType === 1 && (
                                              <div className="success">
                                                {rightAnswer}
                                              </div>
                                            )}

                                          {question[
                                            `${testTypeQuestion}test_user_answer`
                                          ]?.test_answer_choice_id ===
                                            answerId &&
                                          question[
                                            `${testTypeQuestion}test_user_answer`
                                          ]?.correct === false &&
                                          testType === 1 ? (
                                            <div className="error">
                                              {wrongAnswer}
                                            </div>
                                          ) : (
                                            question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]?.correct === false &&
                                            correct &&
                                            testType === 1 && (
                                              <div className="success">
                                                {rightAnswer}
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                            {question[
                              `${testTypeQuestion}test_user_answer`
                            ] &&
                              question?.test_answer_choices[0]
                                .justification &&
                              testType === 1 && (
                                <div className="explanation my-3">
                                  {explanation} :
                                  {
                                    question?.test_answer_choices[0]
                                      .justification
                                  }
                                </div>
                              )}
                          </div>
                        </div>
                      )
                    )}

                    {bookSingleTest?.test_questions.every(
                      (question) =>
                        question?.[
                          `${testTypeQuestion}test_user_answer`
                        ]
                    ) && (
                      <div className="button-submit">
                        {notTestAllAnsweredOptionl ? (
                          <Button
                            text={submit}
                            onClick={() => {
                              setTestQuestionAnwseredView(true);
                            }}
                          />
                        ) : (
                          <Button
                            text={next}
                            onClick={() => {
                              setOpen(false);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal> */}
    </>
  );
}

Test.propTypes = {
  closeTestModal: PropTypes.func,
  bookTest: PropTypes.object,
  testAllAnwsered: PropTypes.boolean,
  testType: PropTypes.number,
  setImageModal: PropTypes.func,
  markTestAsSolved: PropTypes.func
};
