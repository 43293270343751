const dateBasicFormat = {
  month: "short",
  day: "numeric",
  year: "numeric"
};

export function dateToDashed(date, locale = "en", formatOption) {
  const localeOption = locale === "ar" ? "ar-EG" : "en-GB";
  const options = formatOption || dateBasicFormat;
  return new Date(date).toLocaleDateString(localeOption, options);
}
