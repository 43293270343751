import * as Yup from "yup";
import { emailPattern } from "../../constants/validations";
import messages from "../../assets/locale/messages";
import { strongPasswordPattern } from "../../utils/Patterns";

export const REGISTER_SCHEMA = (locale) => {
  const {
    inputs: {
      emailRequired,
      passwordRequired,
      emailShouldMatchEmailFormat,
      firstNameRequires,
      secondNameRequires,
      termsAgreementRequired,
      countryRequired,
      jobReuired,
      strongPasswordRequired
    }
  } = messages[locale];

  return Yup.object().shape({
    password: Yup.string(passwordRequired)
      .matches(strongPasswordPattern, strongPasswordRequired)
      .when("social_provider_type", {
        is: "google",
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(passwordRequired)
      }),
    email: Yup.string(emailRequired)
      .email(emailShouldMatchEmailFormat)
      .matches(emailPattern, emailShouldMatchEmailFormat),
    first_name:
      Yup.string(firstNameRequires).required(firstNameRequires),
    second_name: Yup.string(secondNameRequires).required(
      secondNameRequires
    ),
    country_id: Yup.number(countryRequired).required(countryRequired),
    job_id: Yup.number(jobReuired).required(jobReuired),
    termsAgreement: Yup.boolean(termsAgreementRequired)
      .isTrue(termsAgreementRequired)
      .required()
  });
};
