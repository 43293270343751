import * as Yup from "yup";
import messages from "../../../assets/locale/messages";

export const REGISTER_SCHEMA = (locale) => {
  const {
    inputs: {
      firstNameRequires,
      nameMaxLength,
      secondNameRequires,
      countryRequired,
      jobReuired
    }
  } = messages[locale];

  return Yup.object().shape({
    first_name: Yup.string(firstNameRequires)
      .max(50, nameMaxLength)
      .required(firstNameRequires),
    second_name: Yup.string(secondNameRequires)
      .max(50, nameMaxLength)
      .required(secondNameRequires),
    country_id: Yup.number(countryRequired).required(countryRequired),
    job_id: Yup.number(jobReuired).required(jobReuired)
  });
};
