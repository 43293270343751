export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const SOCIAL_AUTHORIZATION = "SOCIAL_AUTHORIZATION";
export const NEW_USER_REGISTRATION = "NEW_USER_REGISTRATION";
export const EDIT_USER_INFO = "EDIT_USER_INFO";
export const USER_DETAILS = "USER_DETAILS";
export const COMPLETE_DATA_WITH_GOOGLE = "COMPLETE_DATA_WITH_GOOGLE";
export const VERIFY_USER = "VERIFY_USER";
export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const LOGIN_THEN_VERIFY = "LOGIN_THEN_VERIFY";
export const RESET_NEW_PASSWORD_REQUEST =
  "RESET_NEW_PASSWORD_REQUEST";
export const SEND_EMAIL_NEW_PASSWORD = "SEND_EMAIL_NEW_PASSWORD";
export const SET_LOGIN_MODAL_TRIGGER = "SET_LOGIN_MODAL_TRIGGER";
