import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import books from "./Books/reducers";
import categories from "./Categories/reducers";
import loader from "./Loader/reducers";
import auth from "./Auth/reducers";
import snackbar from "./Snackbar/reducers";
import countries from "./Countries/reducers";
import jobs from "./Jobs/reducers";
import checkout from "./Checkout/reducers";
import utilities from "./Utilities/reducers";

export default combineReducers({
  auth,
  snackbar,
  locale,
  books,
  categories,
  loader,
  countries,
  jobs,
  checkout,
  utilities
});
