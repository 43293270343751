import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { changePassword } from "../../../store/Auth/actions";
import Footer from "../../Footer";
import AuthLayout from "../../../components/AuthLayout";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { CHANGE_PASSWORD_SCHEMA } from "./validations";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ChangePassword = () => {
  const { messages, locale } = useIntl();
  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const user = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const {
    changePassword: { header },
    inputs: { newPassword, oldPassword },
    buttons: { confirm }
  } = messages;

  const setPasswordStatus = (type) => {
    if (type === 1) {
      setIsShowOldPassword(!isShowOldPassword);
    }
    if (type === 2) {
      setIsShowNewPassword(!isShowNewPassword);
    }
  };

  const handleSubmit = async (payload) => {
    dispatch(
      changePassword({ ...payload, isAuthor, author_id: user?.id })
    );
  };

  const renderChangePasswordForm = () => (
    <Formik
      initialValues={{
        old_password: "",
        password: ""
      }}
      enableReinitialize
      validationSchema={CHANGE_PASSWORD_SCHEMA(locale)}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isValid }) => (
        <Form>
          <Container>
            <Row>
              <Col className="mx-auto" sm={12} md={12} lg={10}>
                <Form>
                  <h3 className="mb-4">{header}</h3>
                  <Input
                    inputIcon={
                      isShowOldPassword ? (
                        <div>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      )
                    }
                    type={!isShowOldPassword ? "password" : "text"}
                    placeholder={oldPassword}
                    name="old_password"
                    onIconClick={() => setPasswordStatus(1)}
                    isRequired={true}
                  />
                  <Input
                    inputIcon={
                      isShowNewPassword ? (
                        <div>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      )
                    }
                    type={!isShowNewPassword ? "password" : "text"}
                    placeholder={newPassword}
                    name="password"
                    onIconClick={() => setPasswordStatus(2)}
                    isRequired={true}
                  />

                  <div
                    className={`mb-5 d-flex justify-content-center`}
                  >
                    <Button
                      text={confirm}
                      disabled={!isValid}
                      className="primary-lg"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        submitForm();
                      }}
                    />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <AuthLayout body={renderChangePasswordForm()} />
      <Footer />
    </>
  );
};

export default ChangePassword;
