import { React, useEffect } from "react";
import PropTypes from "prop-types";
import PDFViewerSample from "./PDFViewerSample";
import PDFViewerFull from "./PDFViewerFull";
const PDFViewer = ({
  singleBook,
  type,
  pdf,
  sendBookMarksToParent,
  subscribed,
  setCurrentPage,
  setPagesNumber
}) => {
  const renderViewer = (type) => {
    switch (type) {
      case "full":
        return (
          <PDFViewerFull
            singleBook={singleBook}
            sendBookMarksToParent={sendBookMarksToParent}
            isBookSubscribed={subscribed}
            setCurrentPage={setCurrentPage}
            setPagesNumber={setPagesNumber}
          />
        );
      case "preview":
        return <PDFViewerSample pdf={pdf} subscribed={subscribed} />;

      default:
        return null;
    }
  };
  return <>{renderViewer(type)}</>;
};

PDFViewer.propTypes = {
  singleBook: PropTypes.object,
  type: PropTypes.string,
  pdf: PropTypes.string,
  sendBookMarksToParent: PropTypes.func,
  subscribed: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  setPagesNumber: PropTypes.func
};

PDFViewer.defaultProps = {
  singleBook: {},
  type: "full",
  pdf: "",
  sendBookMarksToParent: () => {},
  isBookSubscribed: false
};

export default PDFViewer;
