import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";

const Checkbox = ({ label, name, isLabelBefore }) => {
  return (
    <div>
      <label className="d-flex align-items-center fsize-12">
        {isLabelBefore && (
          <span className="checkbox-label d-inline-block">
            {label}
          </span>
        )}
        <div className="acore-checkbox d-inline-block">
          <Field name={name}>
            {({ field }) => (
              <input
                id={name}
                name={name}
                checked={field.value}
                type="checkbox"
                {...field}
              />
            )}
          </Field>
          <span className="checkbox"></span>
        </div>
        {!isLabelBefore && (
          <span className="checkbox-label d-inline-block fsize-14">
            {label}
          </span>
        )}
      </label>
      <ErrorMessage
        component="div"
        className="error-msg"
        name={name}
      />
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  isLabelBefore: PropTypes.bool
};

Checkbox.defaultProps = {
  label: "",
  name: "",
  isLabelBefore: false
};

export default Checkbox;
