import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Container } from "@mui/material";

import styles from "./CheckoutForm.module.scss";
import History from "../../routes/History";
import PayPalButtonDiscount from "./PayPalButtonDiscount";
import PayPalButtonDefault from "./PayPalButtonDefault";

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );
  const paymentMethod = useSelector(
    (state) => state.checkout.paymentMethod
  );
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  useEffect(() => {
    if (!subscribeBookSelected) {
      History.push("/");
    }
  }, [subscribeBookSelected]);
  let promocodeDiscountSaved = JSON.parse(
    localStorage.getItem("promocodeDiscountSaved")
  );
  const {
    checkoutForm: {
      checkoutStatement,
      checkout,
      payPal,
      visaMaster,
      bookTitle,
      price
    }
  } = messages;

  return (
    <>
      <Col>
        <div className={styles.container + " py-5 px-3"}>
          <Container>
            <Row>
              <Col xs="12">
                <h3>{checkout}</h3>
                <p className="mb-5 font-R fsize-14">
                  {checkoutStatement}
                </p>
              </Col>

              <Col xs="12" className="mb-5">
                <div>
                  <p>
                    {bookTitle}{" "}
                    <span className="fsize-18 font-M">
                      {subscribeBookSelected?.title}
                    </span>
                  </p>
                  <div>
                    <p className="d-flex">
                      <span className="m-1">{price} </span>
                      <p className="m-1">
                        <span
                          className={
                            promocodeDiscountSaved ||
                            promocodeDiscount === 0
                              ? ` ${styles.promocodeApplied} text-primary`
                              : " text-primary"
                          }
                        >
                          ${subscribeBookSelected?.price}
                        </span>
                        <br />
                        {(promocodeDiscountSaved ||
                          promocodeDiscount === 0) && (
                          <span className="text-secondary">
                            {
                              promocodeDiscountSaved?.promocodeDiscount
                            }
                          </span>
                        )}
                      </p>
                    </p>
                  </div>
                </div>
              </Col>
              <Formik
                initialValues={{
                  paymentMethod:
                    paymentMethod === "paypal" ? payPal : visaMaster,
                  country_id: ""
                }}
                enableReinitialize
                onSubmit={() => {}}
              >
                {({ values }) => (
                  <div>
                    <Row>
                      <Col md={12} className="mx-auto">
                        <Form className={styles.container}>
                          {/* <Col md={6}>
                    <CustomSelect
                      options={countries.map((country) =>
                        formatOptions(country, "id", "name")
                      )}
                      name="country_id"
                      bgColor={COLORS.bgPrimaryLight}
                      label={billingAddress}
                      icon={<FontAwesomeIcon icon={faChevronDown} />}
                      isSearchable={true}
                      containerclassName="mb-5"
                      isRequired
                    />
                  </Col> */}
                          {/* NOTE: to enable multiple payment methods */}
                          {/* <div className="my-5">
                    {[visaMaster, payPal].map((option) => (
                      <label
                        htmlhtmlFor={option}
                        key={option}
                        className="d-block mb-2"
                      >
                        <Field
                          type="radio"
                          name="paymentMethod"
                          className="mx-2"
                          id={option}
                          checked={values.paymentMethod === option}
                          value={option}
                          label={option}
                        />
                        {option}
                      </label>
                    ))}
                  </div> */}
                          {/* NOTE: VISA METHOD */}
                          <div>
                            {/*   {values.paymentMethod === visaMaster && (
                      <div>
                        <Input
                          name="nameOnCard"
                          placeholder={nameOnCard}
                        />
                        <Input
                          name="cardNumber"
                          placeholder={cardNumber}
                        />
                        <Row>
                          <Col>
                            <Input name="mmYy" placeholder={mmYy} />
                          </Col>
                          <Col>
                            <Input
                              name="securityCode"
                              placeholder={securityCode}
                            />
                          </Col>
                        </Row>
                        <Input
                          name="zipPostalCode"
                          placeholder={zipPostalCode}
                        />
                        <div className="d-flex justify-content-between">
                          <Checkbox
                            name="remeberThisCard"
                            label={remeberThisCard}
                          />
                          <div className="d-inline">
                            <span className={styles.secureText}>
                              {secureConnection}
                            </span>
                            <div className={styles.lockCircle}>
                              <FontAwesomeIcon
                                icon={faLock}
                                color="blue"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}

                            {promocodeDiscount ||
                            promocodeDiscount === 0 ||
                            promocodeDiscountSaved ? (
                              <PayPalButtonDiscount />
                            ) : (
                              <PayPalButtonDefault />
                            )}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </Row>
          </Container>
        </div>
      </Col>
    </>
  );
};

export default CheckoutForm;
