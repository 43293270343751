import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import medblendLogo from "../../assets/images/logo.png";
import iconFacebook from "../../assets/svgs/icon-facebook.svg";
import iconInstagram from "../../assets/svgs/icon-instgram.svg";
import iconTwitter from "../../assets/svgs/icon-twitter.svg";
import iconLinkedin from "../../assets/svgs/linkedin.svg";
import {
  setLoginModalTrigger,
  toggleLogin
} from "../../store/Auth/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import Login from "../Login";
import "./Footer.scss";

const Footer = () => {
  const dispatch = useDispatch();
  const { messages, locale } = useIntl();
  const user = useSelector((state) => state.auth.login);
  const setOpenLogin = () => dispatch(toggleLogin());
  const setLoginTrigger = (trigger) =>
    dispatch(setLoginModalTrigger(trigger));
  const isLoginOpen = useSelector(
    (state) => state.auth.loginModalOpen
  );
  const loginModalTrigger = useSelector(
    (state) => state.auth.loginModalTrigger
  );

  const {
    footer: {
      contactUs,
      aboutus,
      copyRight,
      Register,
      signin,
      digitalBooks,
      acoreTitle
    }
  } = messages;

  const [books, setbooks] = useState(null);
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  useEffect(async () => {
    // const getTheBooks =
    //   isAuthor ? getAuthorBooks : getBooks;
    // let { data } = await getTheBooks({ page: 1, items: 17 });
    // setbooks(data.books);
    setbooks([]);
  }, []);

  const renderBookList = (bookList, List_margin_top = "") => {
    if (bookList) {
      return (
        <ul className={`menu-list ${List_margin_top}`}>
          {bookList.map((book, i) => (
            <li className="text-black-mid" key={i}>
              <Link to={`${ROUTE_PATHS["bookViewer"]}/${book?.id}`}>
                {book.title}
              </Link>{" "}
            </li>
          ))}{" "}
        </ul>
      );
    }
    return;
  };
  return (
    <footer>
      <div className="paddingY-4 relative">
        <Container>
          <Row>
            <Col xs={12} sm={6} md={5}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="d-flex justify-content-center align-items-center align-items-md-start flex-column mb-4">
                    <Link to="/" className="footer-logo">
                      <img
                        height="104px"
                        src={medblendLogo}
                        alt="medblend-logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-social-links-container d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start mb-4 ">
                    <Link
                      className="ms-3"
                      to={{
                        pathname:
                          "https://www.facebook.com/MedblendPublishing"
                      }}
                      target="_blank"
                    >
                      <img
                        height="20px"
                        src={iconFacebook}
                        alt="medblend-Facebook"
                      />
                    </Link>
                    <Link
                      className="ms-3"
                      to={{
                        pathname: "https://twitter.com/MedBlendPub"
                      }}
                      target="_blank"
                    >
                      <img
                        height="20px"
                        src={iconTwitter}
                        alt="medblend-Twitter"
                      />
                    </Link>
                    <Link
                      className="ms-3"
                      to={{
                        pathname:
                          "https://www.instagram.com/medblendpublishing"
                      }}
                      target="_blank"
                    >
                      <img
                        height="20px"
                        src={iconInstagram}
                        alt="medblend-Instagram"
                      />
                    </Link>
                    <Link
                      className="ms-3"
                      to={{
                        pathname:
                          "https://www.linkedin.com/company/medblendpublishing"
                      }}
                      target="_blank"
                    >
                      <img
                        height="20px"
                        src={iconLinkedin}
                        alt="medblend-Linkedin"
                      />
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col className="d-flex  justify-content-md-end align-items-center align-items-md-start flex-column">
                      <h5 className="footer-h5 mr-sm">
                        {acoreTitle}
                      </h5>
                      <ul className="menu-list margin-top-list">
                        <li
                          className="text-black-mid cursor-pointer"
                          onClick={() => {
                            const element = document.getElementById(
                              "about-us-section-id"
                            );
                            const header =
                              document.getElementById("header");
                            const headerOffset =
                              (header?.clientHeight ?? 40) + 65; // adjust this value based on your needs
                            const elementPosition =
                              element?.getBoundingClientRect().top;
                            const offsetPosition =
                              elementPosition +
                              window.pageYOffset -
                              headerOffset;
                            window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth"
                            });
                          }}
                        >
                          {aboutus}
                          {/* <Link>{aboutus}</Link> */}
                        </li>
                        <li className="text-black-mid">
                          <Link to={ROUTE_PATHS.contactUs}>
                            {contactUs}
                          </Link>
                        </li>
                        {!user?.id && (
                          <>
                            <li className="text-black-mid">
                              <Link
                                onClick={() => {
                                  setOpenLogin();
                                  setLoginTrigger("footer");
                                }}
                              >
                                {signin}
                              </Link>
                            </li>
                            <li className="text-black-mid">
                              <Link to={ROUTE_PATHS.registration}>
                                {Register}
                              </Link>
                            </li>{" "}
                          </>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={6} md={7}>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <h5 className="footer-h5 text-footer-sm-center">
                    {digitalBooks}
                  </h5>
                  <div>
                    {renderBookList(
                      books?.slice(0, 3),
                      "margin-top-list"
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="no-margin-top-list">
                    {renderBookList(
                      books?.slice(4, 7),
                      "margin-top-list"
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="no-margin-top-list">
                    {renderBookList(
                      books?.slice(8, 11),
                      "margin-top-list"
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="fs-9 cpyWrite ">{copyRight}</p>
            </Col>
          </Row>
        </Container>
        {loginModalTrigger === "footer" && (
          <Login
            isLoginOpen={isLoginOpen}
            onLoginClose={() => {
              setOpenLogin();
              setLoginTrigger("");
            }}
            lang={locale}
          />
        )}
      </div>
    </footer>
  );
};

export default Footer;
