import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Formik } from "formik";

import { ROUTE_PATHS } from "../../utils/PathsNames";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Footer from "../Footer";
import {
  setPromocodeRequest,
  setPromocodeResponse,
  setPromocodeNameResponse,
  setFreeDiscountPromocodeRequest
} from "../../store/Checkout/actions";
import history from "../../routes/History";
import styles from "./Promocode.module.scss";

const Promocode = () => {
  const { messages } = useIntl();
  let params = useParams();
  const dispatch = useDispatch();

  const [disabledInput, setdisabledInput] = useState(false);
  const {
    checkoutForm: {
      checkout,
      completePayment,
      promocodeStatement,
      bookTitle,
      apply,
      promocode,
      price,
      removePromo
    }
  } = messages;

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
    return () => {
      dispatch(setPromocodeResponse(null));
      dispatch(setPromocodeNameResponse(null));
    };
  }, []);
  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  const promocodeName = useSelector(
    (state) => state.checkout.promocodeName
  );
  let promocodeDiscountSaved = JSON.parse(
    localStorage.getItem("promocodeDiscountSaved")
  );
  useEffect(() => {
    if (promocodeDiscountSaved) {
      localStorage.removeItem("promocodeDiscountSaved");
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      await dispatch(
        setPromocodeRequest({
          book_id: params?.id,
          name: values.promocode
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
  const removePromocode = () => {
    if (
      promocodeDiscount ||
      promocodeDiscountSaved ||
      promocodeName
    ) {
      dispatch(setPromocodeResponse(null));
      dispatch(setPromocodeNameResponse(null));
      localStorage.removeItem("promocodeDiscountSaved");
    }
  };
  return (
    <>
      <Container>
        <Row>
          <Col md={6} xs={12} className="mx-auto mb-5">
            <div className={styles.container}>
              <Col xs="12">
                <h3 className="mb-5">{checkout}</h3>
                <p className=" font-R fsize-14 mb-3">
                  {promocodeStatement}
                </p>
              </Col>
              <Col xs="12 ">
                <Formik
                  initialValues={{
                    promocode:
                      promocodeDiscountSaved || promocodeName
                        ? promocodeName
                        : ""
                  }}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ submitForm, isValid, resetForm }) => (
                    <section
                      className={
                        disabledInput ||
                        promocodeDiscount ||
                        promocodeDiscount === 0
                          ? styles.disabledInput
                          : null + "d-flex align-items-start"
                      }
                    >
                      <Form>
                        <Row>
                          <Col
                            className={`mx-auto ${styles.promocode}`}
                            sm={12}
                            md={12}
                          >
                            <div>
                              <Input
                                name="promocode"
                                label={promocode}
                                isRequired={true}
                                disabled={
                                  promocodeDiscount ||
                                  promocodeDiscount === 0
                                }
                              />
                            </div>
                            <Button
                              className="primary_light_border"
                              text={apply}
                              type="submit"
                              disabled={!isValid}
                              onClick={(e) => {
                                e.preventDefault();
                                submitForm();
                              }}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </section>
                  )}
                </Formik>
              </Col>
              {(promocodeDiscount || promocodeDiscount === 0) && (
                <Col xs="12" className="cursor-pointer">
                  <a
                    onClick={() => {
                      removePromocode();
                    }}
                    className="text-secondary fsize-12"
                  >
                    {removePromo}
                  </a>
                </Col>
              )}

              <Col xs="12" className="mt-5">
                <div>
                  <p>
                    {bookTitle}{" "}
                    <span className="fsize-18 font-M">
                      {subscribeBookSelected?.title}
                    </span>
                  </p>
                  <div>
                    <p className="d-flex">
                      <span className="m-1">{price} </span>
                      <p className="m-1">
                        <span
                          className={
                            promocodeDiscount ||
                            promocodeDiscount === 0
                              ? ` ${styles.promocodeApplied} text-primary`
                              : " text-primary"
                          }
                        >
                          ${subscribeBookSelected?.price}
                        </span>
                        <br />{" "}
                        {(promocodeDiscount ||
                          promocodeDiscount === 0) && (
                          <span className="text-secondary">
                            {promocodeDiscount}
                          </span>
                        )}
                      </p>
                    </p>
                  </div>
                </div>
                {subscribeBookSelected && (
                  <div className="d-flex mt-5 justify-content-end">
                    <Button
                      text={completePayment}
                      onClick={async () => {
                        if (promocodeDiscount === 0) {
                          await dispatch(
                            setFreeDiscountPromocodeRequest({
                              book_id: params?.id,
                              promocode_name: promocodeName
                            })
                          );
                          history.push(
                            `${ROUTE_PATHS.bookProfile}/${subscribeBookSelected.id}`
                          );
                          return;
                        }
                        if (promocodeDiscount) {
                          localStorage.setItem(
                            "promocodeDiscountSaved",
                            JSON.stringify({
                              promocodeDiscount: promocodeDiscount,
                              promocodeName: promocodeName
                            })
                          );
                        }
                        history.push(`${ROUTE_PATHS["checkout"]}`);
                      }}
                    />
                  </div>
                )}
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Promocode;
