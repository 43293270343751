import React from "react";

const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-247.000000, -125.000000) translate(247.000000, 125.500000)">
            <rect width="40" height="40" fill="#FFF" rx="8" />
            <path
              fill="#0E4DCE"
              stroke="#0E4DCE"
              strokeWidth=".5"
              d="M16.209 25.381l-4.814-4.813c-.315-.316-.315-.824 0-1.14l4.814-4.809c.315-.316.823-.316 1.139 0 .316.316.316.824 0 1.14l-3.426 3.425h14.104c.452 0 .816.364.816.816 0 .452-.364.816-.816.816H13.922l3.426 3.426c.316.315.316.823 0 1.139-.312.316-.824.316-1.14 0z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackArrow;
