import React from "react";
import PropTypes from "prop-types";
import { Dropdown as BootstrapDropDown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { logoutRequest } from "../../store/Auth/actions";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_PATHS } from "../../utils/PathsNames";

const DropDown = ({
  buttonText,
  dropList,
  menuButtonClassName,
  menuBodyClassName
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isEditor = pathname.includes("editor");
  const user = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { headerMenu } = messages;
  const showAllUserMenu =
    user.email_verified ||
    user?.institution_subscription?.id ||
    user?.is_beta;
  return (
    <BootstrapDropDown>
      <BootstrapDropDown.Toggle
        id="dropdown-menu-align-end"
        className={menuButtonClassName}
      >
        {buttonText}
      </BootstrapDropDown.Toggle>
      {showAllUserMenu ? (
        <BootstrapDropDown.Menu className={menuBodyClassName}>
          {dropList.map(({ label, onClick }, index) => {
            return (
              <BootstrapDropDown.Item
                key={index}
                as="button"
                onClick={onClick}
              >
                {label}
              </BootstrapDropDown.Item>
            );
          })}
        </BootstrapDropDown.Menu>
      ) : (
        <BootstrapDropDown.Menu className={menuBodyClassName}>
          {isAuthor && (
            <BootstrapDropDown.Item
              as="button"
              onClick={() =>
                history.push(ROUTE_PATHS.authorsChangePassword)
              }
            >
              {headerMenu.changePassword}
            </BootstrapDropDown.Item>
          )}
          {isEditor && (
            <BootstrapDropDown.Item
              as="button"
              onClick={() =>
                history.push(ROUTE_PATHS.editorsChangePassword)
              }
            >
              {headerMenu.changePassword}
            </BootstrapDropDown.Item>
          )}
          <BootstrapDropDown.Item
            as="button"
            onClick={() =>
              dispatch(logoutRequest({ isAuthor, isEditor }))
            }
          >
            {headerMenu.logout}
          </BootstrapDropDown.Item>
        </BootstrapDropDown.Menu>
      )}
    </BootstrapDropDown>
  );
};
DropDown.propTypes = {
  menuButtonClassName: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  dropList: PropTypes.array,
  menuBodyClassName: PropTypes.string,
  onClick: PropTypes.func
};
export default DropDown;
