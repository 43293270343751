import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../network/apis";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/PathsNames";

import {
  getSingleBookAction,
  setCurrentSubscriptionLoader,
  unsubscribeBook
} from "../../store/Books/actions";

import styles from "./Snackbar.module.scss";

var interval = null;

export function SubscriptionLoader() {
  const dispatch = useDispatch();
  const [currentlyLoadingBookId, setCurrentlyLoadingBookId] =
    useState(null);

  const currentSunscriptionLoader = useSelector(
    (state) => state.books.currentSunscriptionLoader
  );
  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );
  const { singleBook } = useSelector((state) => state.books);
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  function startStuff(func, time) {
    interval = setInterval(func, time);
  }

  function stopStuff() {
    clearInterval(interval);
  }

  const makeSureUserSubscribed = () => {
    startStuff(() => {
      if (!singleBook?.is_subscribed) {
        dispatch(getSingleBookAction(+currentlyLoadingBookId));
      }
    }, 16000);
  };

  useEffect(() => {
    if (singleBook?.is_subscribed) {
      dispatch(
        setCurrentSubscriptionLoader({
          id: null
        })
      );
      setCurrentlyLoadingBookId(null);
      stopStuff();
      if (localStorage.getItem("subscription_id")) {
        dispatch(
          unsubscribeBook({
            id: localStorage.getItem("subscription_id"),
            reason: "promo code activated"
          })
        );
        history.push(`${ROUTE_PATHS.bookProfile}/${singleBook.id}`);
        localStorage.removeItem("subscription_id");
        localStorage.removeItem("promocodeDiscountSaved");
      }
    }
  }, [singleBook]);

  useEffect(() => {
    // Just to handle requests in the component, faraway from store
    if (currentSunscriptionLoader) {
      setCurrentlyLoadingBookId(currentSunscriptionLoader);
    }
  }, [currentSunscriptionLoader]);

  useEffect(() => {
    if (currentlyLoadingBookId) {
      makeSureUserSubscribed();
    }
  }, [currentlyLoadingBookId]);

  return (
    <div className={styles.SubscriptionLoader}>
      <div className={styles.snackbarContainer}>
        <div className={styles.snackbarWrapper}>
          <Toast
            show={!!currentlyLoadingBookId}
            autohide
            className={`${styles.subscriptionLoader} ${styles.toast}`}
          >
            <Toast.Body>
              <div>
                Please wait.. your payment is currently being
                processed
              </div>
            </Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
}
