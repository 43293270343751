import { BOOKS_LIST_TYPES } from "../../../utils/Constants";

export const getParams = (listType, items, page = 1) => {
  let params = {
    page,
    items,
    show_details: true
  };
  switch (listType) {
    case BOOKS_LIST_TYPES.featured:
      params.is_featured = true;
      break;
    default:
      break;
  }
  return params;
};

export const getBooksListFromStore = (state, listType) => {
  switch (listType) {
    case BOOKS_LIST_TYPES.featured:
      return {
        booksList: state.books.featured.list,
        metaData: state.books.featured.meta
      };
    case BOOKS_LIST_TYPES.related:
      return {
        booksList: state.books.related.list,
        metaData: state.books.related.meta
      };
    default:
      return {
        booksList: state.books.list,
        metaData: state.books.meta
      };
  }
};
