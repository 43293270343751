import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks/useQuery";
import { verifyUser } from "../../store/Auth/actions";
import Login from "../Login";

const Verification = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.auth.login);
  const query = useQuery();
  useEffect(() => {
    if (query) {
      localStorage.setItem("token", query.get("token"));
    }
    return () => {
      localStorage.removeItem("token");
    };
  }, [query]);

  useEffect(() => {
    if (login?.id && !login.email_verified) {
      dispatch(
        verifyUser({
          email_verification_token: query.get("token")
        })
      );
    }
  }, []);

  return (
    <Login
      isLoginOpen={true}
      isVerification
      verificationToken={query.get("token")}
    />
  );
};

export default Verification;
