import {
  SET_IS_LOGGED_IN_AUTHOR,
  SET_MATERIAL_MODAL_DATA,
  SET_OPEN_MATERIAL_MODAL,
  SET_OPEN_QUIZ_MODAL,
  SET_OPEN_SURVEY,
  SET_OPEN_TEST_MODAL,
  SET_QUIZE_MODAL_DATA,
  SET_TEST_MODAL_DATA
} from "./types";

const INITIAL_STATE = {
  showMaterialModal: false,
  materialData: {
    details: {},
    type: 0,
    src: "",
    dicomImages: []
  },
  showTestModal: false,
  testData: {
    testTypeQuestion: "",
    testType: -1,
    setImageModal: () => {},
    markTestAsSolved: () => {},
    // setNotTestAllAnsweredOptionl: () => {},
    bookTest: {}
  },
  showQuizeModal: false,
  quizeData: {},
  showSurvey: false,
  isLoggedInAuthor: false
};

const utilities = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_MATERIAL_MODAL:
      return {
        ...state,
        showMaterialModal: action.payload
      };

    case SET_OPEN_TEST_MODAL:
      return {
        ...state,
        showTestModal: action.payload
      };

    case SET_OPEN_QUIZ_MODAL:
      return {
        ...state,
        showQuizeModal: action.payload
      };

    case SET_OPEN_SURVEY:
      return {
        ...state,
        showSurvey: action.payload
      };

    case SET_MATERIAL_MODAL_DATA:
      return {
        ...state,
        materialData: {
          details: action.payload.details,
          type: action.payload.type,
          src: action.payload.src,
          dicomImages: action.payload.dicomImages
        }
      };

    case SET_TEST_MODAL_DATA:
      return {
        ...state,
        testData: {
          testTypeQuestion: action.payload.testTypeQuestion,
          testType: action.payload.testType,
          setImageModal: action.payload.setImageModal,
          markTestAsSolved: action.payload.markTestAsSolved,
          bookTest: action.payload.bookTest
          // setNotTestAllAnsweredOptionl:
          //   action.payload.setNotTestAllAnsweredOptionl
        }
      };
    case SET_QUIZE_MODAL_DATA:
      return {
        ...state,
        quizeData: {}
      };
    case SET_IS_LOGGED_IN_AUTHOR:
      return {
        ...state,
        isLoggedInAuthor: action.payload
      };

    default:
      return state;
  }
};

export default utilities;
