export const COLORS = {
  primary: "#0e4dce",
  secondary: "#d24ec6",
  bgPrimaryLight: "#ecf1fb",
  bgPrimaryMid: "#d0dcf5",
  black: "#3d3d3d",
  white: "#ffffff",
  gray: "#807f7f",
  grayLight: "#f6f6f6",
  bgPrimary: "#f4f7fd",
  blackMid: "#5e5e5e",
  grayMid: "#bcbcbc"
};
