import React from "react";

const SwapVerticalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="31"
      viewBox="0 0 30 31"
    >
      <defs>
        <path
          id="ipidpoa44a"
          d="M.002.037h15.032v15.034H.002z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(7 7.5)">
        <mask id="fr3ab58odb" fill="#fff">
          <use xlinkHref="#ipidpoa44a"></use>
        </mask>
        <path
          fill="#3D3D3D"
          d="M14.853 3.912L11.159.22a.616.616 0 00-.875 0l-3.69 3.693a.616.616 0 000 .875.617.617 0 00.874 0l2.63-2.63v10.824a.625.625 0 101.251 0V2.158l2.63 2.629a.618.618 0 10.874-.875zM8.44 10.321a.617.617 0 00-.874 0l-2.629 2.63V2.126a.625.625 0 10-1.252 0V12.95l-2.629-2.629a.617.617 0 00-.874 0 .616.616 0 000 .875l3.694 3.693a.615.615 0 00.874 0l3.694-3.693a.619.619 0 00-.003-.875z"
          mask="url(#fr3ab58odb)"
        ></path>
      </g>
    </svg>
  );
};

export default SwapVerticalIcon;
