import React from "react";
import { Router, Switch, Redirect, Route } from "react-router-dom";
import history from "./History";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { ROUTE_PATHS } from "../utils/PathsNames";
// ========= Components =========
import Home from "../containers/Home/Home";
import Verify from "../containers/Home/Verify";
import BookProfile from "../containers/Books/BookProfile";
import Registration from "../containers/Registration";
import BooksListing from "../containers/Books/BooksListing/BooksListing";
import BookViewer from "../containers/Books/BookViewer";
import Checkout from "../containers/Checkout";
import Verification from "../containers/Verification";
import SubscriptionManagement from "../containers/User/SubscriptionManagement";
import ProfileInfo from "../containers/User/ProfileInfo";
import ChangePassword from "../containers/User/ChangePassword";
import ForgetPassword from "../containers/ForgetPassword";
import Certificates from "../containers/User/Certificates";
import SupplementaryDisplay from "../containers/SupplementaryDisplay";
import UserSubscribedBookList from "../containers/User/BookList";
// import AllBookSupplementaries from "../containers/Books/AllBookSupplementaries";
import AllBookVideos from "../containers/Books/AllBookVideos";
import AllBookTests from "../containers/Books/AllBookTests";
import ContactUs from "../containers/ContactUs";
import InstitutionBooksList from "../containers/User/InstitutionBooksList";
import Promocode from "../containers/Promocode";
import TestDisplay from "../containers/TestDisplay";
import QuizDisplay from "../containers/QuizDisplay";
import LoginPage from "../containers/Login/LoginPage";
import SubscriberRoute from "./SubscriberRoute";
import EditorBookNotes from "../containers/Books/EditorBookNotes/EditorBookNotes";

const Routes = (
  <Router history={history}>
    <Switch>
      <Route
        component={Home}
        path={["/", "/authors", "/editors"]}
        exact
      />
      <Route
        component={Verify}
        path={ROUTE_PATHS.missingVerification}
        exact
      />
      <PublicRoute
        component={Registration}
        path={`${ROUTE_PATHS.registration}`}
        exact
      />
      <Route
        component={ContactUs}
        path={`${ROUTE_PATHS.contactUs}`}
        exact
      />
      <Route
        component={Verification}
        path={`${ROUTE_PATHS.verification}`}
      />
      <Route
        component={BookProfile}
        path={`${ROUTE_PATHS.bookProfile}/:id`}
      />
      <Route
        component={BooksListing}
        path={`${ROUTE_PATHS.books}/:listType?/:bookId?`}
      />

      <Route
        component={InstitutionBooksList}
        path={`${ROUTE_PATHS.institutionBooks}`}
      />

      <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.bookViewer}/:id`}
      />
      <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.authorBookViewer}/:id`}
      />
      <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.editorBookViewer}/:id`}
      />
      <SubscriberRoute
        component={SupplementaryDisplay}
        path={`${ROUTE_PATHS.supplementary}/:id/:bookId`}
      />
      <Route
        component={ProfileInfo}
        path={`${ROUTE_PATHS.profileInfo}/:id`}
      />
      <PrivateRoute
        component={ChangePassword}
        path={[
          `${ROUTE_PATHS.changePassword}`,
          `${ROUTE_PATHS.authorsChangePassword}`,
          `${ROUTE_PATHS.editorsChangePassword}`
        ]}
      />
      <Route
        component={ForgetPassword}
        path={`${ROUTE_PATHS.forgetPassword}`}
      />
      <Route
        component={Certificates}
        path={`${ROUTE_PATHS.certificates}`}
      />
      <PrivateRoute
        component={Checkout}
        path={`${ROUTE_PATHS.checkout}`}
      />

      <PrivateRoute
        component={Promocode}
        path={`${ROUTE_PATHS.promocode}/:id`}
      />
      <PrivateRoute
        component={SubscriptionManagement}
        path={`${ROUTE_PATHS.subscriptionManagement}`}
      />
      <PrivateRoute
        component={UserSubscribedBookList}
        path={`${ROUTE_PATHS.userBookList}`}
      />
      {/* <Route
        component={AllBookSupplementaries}
        path={`${ROUTE_PATHS.bookAllSupplementary}/:id`}
      /> */}
      <Route
        component={AllBookVideos}
        path={`${ROUTE_PATHS.bookAllVideos}/:id`}
      />
      <Route
        component={AllBookTests}
        path={`${ROUTE_PATHS.bookAllTests}/:id`}
      />
      <SubscriberRoute
        component={TestDisplay}
        path={`${ROUTE_PATHS.testdisplay}/:id/:testTypeString/:bookId`}
      />
      <SubscriberRoute
        component={QuizDisplay}
        path={`${ROUTE_PATHS.quizdisplay}/:id/:bookId/:bookPage`}
      />
      <Route
        component={EditorBookNotes}
        path={`${ROUTE_PATHS.editorNotes}/:bookId`}
      />
      <PublicRoute
        component={LoginPage}
        path={[
          `${ROUTE_PATHS.signin}`,
          `${ROUTE_PATHS.authorLogin}`,
          `${ROUTE_PATHS.editorLogin}`
        ]}
        exact
      />
      <Redirect from="*" to="/" />
    </Switch>
  </Router>
);

export default Routes;
