import React from "react";
import MaterialPreviewStyles from "./MaterialPreview.module.scss";
import surveySVG from "../../assets/svgs/survey.svg";
import { useDispatch } from "react-redux";
import { SET_OPEN_SURVEY } from "../../store/Utilities/types";
import { useIntl } from "react-intl";

export default function SurveyPreview() {
  const { messages } = useIntl();
  const { survey } = messages;

  const dispatch = useDispatch();
  const setOpenSurvey = (open) => {
    dispatch({
      type: SET_OPEN_SURVEY,
      payload: open
    });
  };

  return (
    <div className={MaterialPreviewStyles.wrapper}>
      <div className={MaterialPreviewStyles.quizCover}>
        <button
          className={`${MaterialPreviewStyles.emptyPreview} d-flex w-100 fsize-20`}
          onClick={() => {
            setOpenSurvey(true);
          }}
        >
          <img src={surveySVG} alt="survey-image" />
          <h6 className="text-start my-auto">{survey ?? "Survey"}</h6>
        </button>
      </div>
    </div>
  );
}
