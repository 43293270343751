import { React, useState } from "react";
import PropTypes from "prop-types";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Collapse from "react-bootstrap/Collapse";
// import Accordion from "react-bootstrap/Accordion";
// import { Card } from "react-bootstrap";

const BookChapters = ({ pdfViewer, outline }) => {
  const [open, setOpen] = useState([]);
  const handleToggle = (index) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      if (newOpen[index] === undefined) newOpen[index] = true;
      else newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };
  return (
    <ul className="rpv-bookmark__list">
      {outline?.map((o, index) => (
        <li aria-label={o?.title} key={index + 1}>
          <div className="cardDiv">
            <div
              className={`rpv-bookmark__item ${
                open[index] ? "itemborder" : ""
              }`}
            >
              <div
                className="rpv-bookmark__title"
                onClick={() =>
                  pdfViewer.linkService.goToDestination(o?.dest)
                }
              >
                {o?.title}
              </div>
              <span
                className={`rpv-bookmark__toggle transition-icon ${
                  open[index] ? "rotate-up" : ""
                }`}
                aria-controls={`${o?.title} Data`}
                onClick={() => {
                  handleToggle(index);
                }}
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
            <Collapse in={open[index]}>
              <div className="innerListContainer mt-3">
                {o.items.map((io, iindex) => (
                  <div
                    className="rpv-bookmark__listitem"
                    key={iindex}
                  >
                    <div
                      className="rpv-bookmark__title"
                      onClick={() =>
                        pdfViewer.linkService.goToDestination(
                          io?.dest
                        )
                      }
                    >
                      {io?.title}
                    </div>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        </li>
      ))}
    </ul>

    // <Accordion defaultActiveKey="0">
    //   <Card>
    //     <Card.Header>
    //       <Accordion.Toggle variant="link" eventKey="0">
    //         Click me!
    //       </Accordion.Toggle>
    //     </Card.Header>
    //     <Accordion.Collapse eventKey="0">
    //       <Card.Body>Hello!</Card.Body>
    //     </Accordion.Collapse>
    //   </Card>
    // </Accordion>
  );
};

BookChapters.propTypes = {
  pdfViewer: PropTypes.object,
  outline: PropTypes.array
};

export default BookChapters;
