import * as types from "./types";

export const setPaymentMethod = (method) => ({
  type: types.SET_PAYMENT_METHOD,
  payload: method
});
export const setPromocodeRequest = (method) => ({
  type: types.SET_PROMOCODE_REQUEST,
  payload: method
});
export const setPromocodeResponse = (method) => ({
  type: types.SET_PROMOCODE_RESPONSE,
  payload: method
});
export const setFreeDiscountPromocodeRequest = (method) => ({
  type: types.SET_FREE_DISCOUNT_PROMOCODE_REQUEST,
  payload: method
});
export const setPromocodeNameResponse = (method) => ({
  type: types.SET_PROMOCODE_NAME_RESPONSE,
  payload: method
});
