import React from "react";
import PropTypes from "prop-types";
import styles from "./AuthLayout.module.scss";
import { Col, Container, Row } from "react-bootstrap";

const AuthLayout = ({ body }) => {
  return (
    <div className={`${styles.authWrapper} my-5 mx-auto`}>
      <Container
        className={`bg-bg-primary p-md-5 p-0 pb-5 ${styles.container}`}
      >
        {/* <Row>
        <Col item xs={12} sm={12} md={7} className="pt-xs-80"> */}
        {body}
        {/* </Col>
        <Col item sm={0} md={5} className={styles.image}></Col>
        </Row> */}
      </Container>
    </div>
  );
};

AuthLayout.propTypes = {
  body: PropTypes.node
};

export default AuthLayout;
