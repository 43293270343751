import * as types from "./types";

export const loginRequest = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload
});

export const loginThenVerify = (payload) => ({
  type: types.LOGIN_THEN_VERIFY,
  payload
});

export const loginResponse = (payload) => ({
  type: types.LOGIN_RESPONSE,
  payload
});

export const completeDataWithGoogle = (payload) => ({
  type: types.COMPLETE_DATA_WITH_GOOGLE,
  payload
});

export const socialAuthorization = (payload) => ({
  type: types.SOCIAL_AUTHORIZATION,
  payload
});

export const editUserInfo = (payload) => ({
  type: types.EDIT_USER_INFO,
  payload
});

export const userDetails = (payload) => ({
  type: types.USER_DETAILS,
  payload
});

export const changePassword = (payload) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload
});

export const resetNewPassword = (payload) => ({
  type: types.RESET_NEW_PASSWORD_REQUEST,
  payload
});
export const sendEmailNewPassword = (payload) => ({
  type: types.SEND_EMAIL_NEW_PASSWORD,
  payload
});

export const registerNewUser = (payload) => ({
  type: types.NEW_USER_REGISTRATION,
  payload
});

export const logoutRequest = (payload) => ({
  type: types.LOGOUT_REQUEST,
  payload
});

export const verifyUser = (payload) => ({
  type: types.VERIFY_USER,
  payload
});

export const resendVerificationEmail = (payload) => ({
  type: types.RESEND_VERIFICATION_EMAIL,
  payload
});

export const toggleLogin = (payload) => ({
  type: types.TOGGLE_LOGIN,
  payload
});

export const setLoginModalTrigger = (payload) => ({
  type: types.SET_LOGIN_MODAL_TRIGGER,
  payload
});
