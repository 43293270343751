import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import BackArrow from "../../assets/svgs/back-arrow";
import CloseButtonIcon from "../../assets/svgs/close-button-icon";
import styles from "./BackButton.module.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BackButton = ({ icon, className, otherWise }) => {
  const history = useHistory();

  const goBack = () => {
    otherWise ? otherWise() : history.goBack();
  };

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={goBack}
    >
      {icon === "x" ? (
        <CloseButtonIcon />
      ) : icon === "b" ? (
        <FontAwesomeIcon icon={faAngleLeft} />
      ) : (
        <BackArrow />
      )}
    </button>
  );
};

BackButton.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  otherWise: PropTypes.func
};

BackButton.defaultProps = {
  icon: "arrow",
  className: ""
};

export default BackButton;
