import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Bookshelves from "../../assets/images/bookshelves.jpg";
import medblendLogo from "../../assets/images/logo.png";
import Button from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessge";
import GoogleButton from "../../components/GoogleButton";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import Or from "../../components/Or";
import { useQuery } from "../../hooks/useQuery";
import {
  loginRequest,
  loginThenVerify
} from "../../store/Auth/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { LOGIN_SCHEMA } from "./validations";

const Login = ({ isLoginOpen, onLoginClose, isVerification }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const login = useSelector((state) => state.auth.login);
  const { messages, locale } = useIntl();
  const {
    login: { header, isHaveAccount, createAccount },
    inputs: { email, password, remeberMe },
    buttons: { signIn },
    forgotPassword: { link },
    copyRights,
    contactUs
  } = messages;

  const setPasswordStatus = () => setIsShowPassword(!isShowPassword);
  // save hash value in redux store
  // make axios instance dynamic
  const { pathname } = useLocation();
  const isEditor = pathname.includes("editor");
  const { isLoggedInAuthor } = useSelector(
    (state) => state.utilities
  );
  const handleSubmit = (data) => {
    const clonedData = {
      ...data,
      isEditor,
      isAuthor: isLoggedInAuthor
    };
    if (isVerification && query.get("token")) {
      dispatch(
        loginThenVerify({
          clonedData,
          token:
            query.get("token") || localStorage.getItem("tempToken")
        })
      );
      localStorage.removeItem("tempToken");
    } else {
      dispatch(loginRequest(clonedData));
    }
  };

  return (
    <Modal
      isOpen={isLoginOpen}
      onHide={onLoginClose}
      imageSrc={Bookshelves}
    >
      <Container>
        <Row>
          <Col className="mx-auto" lg={10}>
            <h3 className="mb-4 w-75">{header}</h3>
            <GoogleButton type="signin" />
            <Or />
            <Formik
              initialValues={{
                email: null,
                password: null,
                remeberMe: false
              }}
              validationSchema={LOGIN_SCHEMA(locale)}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Form>
                    <Input name="email" label={email} />
                    <Input
                      inputIcon={isShowPassword ? "Hide" : "Show"}
                      type={!isShowPassword ? "password" : "text"}
                      label={password}
                      name="password"
                      onIconClick={setPasswordStatus}
                    />
                    <ErrorMessage message={login?.message} />
                    <div className="mb-4">
                      <Link
                        to={ROUTE_PATHS.forgetPassword}
                        className="font-M fsize-12"
                        onClick={() => onLoginClose()}
                      >
                        {" "}
                        {link}
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <Button text={signIn} className="primary-lg" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr />
            <div className="fsize-12 mb-sm-20 mb-md-40 font-M">
              {isHaveAccount}
              <Link
                to={ROUTE_PATHS.registration}
                className="text-secondary font-M fsize-12"
                onClick={() => onLoginClose()}
              >
                {" "}
                {createAccount}
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-between text-gray">
              <img
                src={medblendLogo}
                alt="medblend-logo"
                width="50px"
              />
              <p className="m-0 fsize-10">{copyRights}</p>
              <Link
                className="fsize-10"
                to={ROUTE_PATHS.contactUs}
                onClick={() => onLoginClose()}
              >
                {contactUs}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

Login.propTypes = {
  isLoginOpen: PropTypes.bool,
  onLoginClose: PropTypes.func,
  isVerification: PropTypes.bool
};

Login.defaultProps = {
  onLoginClose: () => {},
  isVerification: false
};

export default Login;
