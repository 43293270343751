import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useGoogleLogin } from "@react-oauth/google";

import GoogleIcon from "../../assets/svgs/google.svg";
import { socialAuthorization } from "../../store/Auth/actions";
import styles from "./GoogleButton.module.scss";
import axios from "axios";
import { useState } from "react";
import { boxStyle } from "../../containers/Books/BookViewer/boxStyle";
import { Box, Modal } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import Input from "../Input";
import { useIntl } from "react-intl";
import CustomSelect from "../Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../utils/Theme";
import { formatOptions } from "../../utils/Helpers";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";

const GoogleButton = ({ text, type, registerData = {} }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    signup: { header, termsAgreement, signUpMessage, imageUpload },
    inputs: {
      email,
      password,
      phoneOptional,
      firstName,
      lastName,
      institution,
      city,
      country,
      job,
      ContinueRegisteration
    },
    buttons: { register }
  } = messages;
  const jobs = useSelector((state) => state.jobs.list);
  const countries = useSelector((state) => state.countries.list);

  const getUserInfo = async (token) => {
    return await axios({
      url: "https://www.googleapis.com/oauth2/v3/userinfo",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response)
      .catch((error) => console.log("get user info error", error));
  };

  const [userGoogleData, setUserGoogleData] = useState({});
  const authorizeGoogleAccount = (response) => {
    getUserInfo(response.access_token).then((userInfo) => {
      const login = {
        social_provider_id: userInfo.data.sub,
        social_provider_type: "google"
      };
      if (type === "signin") {
        dispatch(
          socialAuthorization({
            ...login,
            first_name: userInfo.data.given_name,
            second_name: userInfo.data.family_name,
            email: userInfo.data.email,
            sign_in: true
          })
        );
      }
      if (type === "signup") {
        setOpenModal(true);
        console.log(login, userInfo);
        setUserGoogleData(userInfo);
      }
    });
  };

  const login = useGoogleLogin({
    onSuccess: (response) => {
      authorizeGoogleAccount(response);
    },
    onError: (error) => {
      console.log("error testing", error);
    }
  });
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <button className={styles.button} onClick={() => login()}>
        <img src={GoogleIcon} width="20px" alt="google-icon" />
        <span className="mx-2 d-inline-block text-black">{text}</span>
      </button>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...boxStyle,
            maxHeight: "90%",
            height: "90%",
            width: "45%",
            minWidth: "45%",
            overflowY: "auto"
          }}
        >
          <h2>{ContinueRegisteration}</h2>
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <Input
                  name="second_name"
                  label={lastName}
                  isRequired={true}
                />
              </Col>
            </Row>
            <CustomSelect
              options={countries.map((country) =>
                formatOptions(country, "id", "name")
              )}
              placeholder={country}
              name="country_id"
              bgColor={COLORS.bgPrimaryLight}
              icon={<FontAwesomeIcon icon={faChevronDown} />}
              isSearchable={true}
              containerClass="mb-4"
              isRequired
            />
            <CustomSelect
              options={jobs?.map((job) =>
                formatOptions(job, "id", "job_type")
              )}
              placeholder={job}
              name="job_id"
              bgColor={COLORS.bgPrimaryLight}
              icon={<FontAwesomeIcon icon={faChevronDown} />}
              isSearchable={true}
              containerClass="mb-4"
              isRequired
            />
            <Button
              text={register}
              className="primary-lg"
              type="button"
              onClick={() => {
                dispatch(
                  socialAuthorization({
                    social_provider_id: userGoogleData.data?.sub,
                    social_provider_type: "google",
                    first_name: userGoogleData.data?.given_name,
                    second_name: userGoogleData.data?.family_name,
                    email: userGoogleData.data?.email,
                    ...registerData
                  })
                );
              }}
            />
          </Container>
        </Box>
      </Modal>
    </>
  );
};

GoogleButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  registerData: PropTypes.object
};

GoogleButton.defaultProps = {
  text: "Sign in with Google",
  type: "signin"
};

export default GoogleButton;
