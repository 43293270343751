import * as Yup from "yup";
import messages from "../../../assets/locale/messages";
import { strongPasswordPattern } from "../../../utils/Patterns";

export const CHANGE_PASSWORD_SCHEMA = (locale) => {
  const {
    inputs: {
      passwordRequired,
      oldPassordRequired,
      newPassordRequired,
      strongPasswordRequired
    }
  } = messages[locale];

  return Yup.object().shape({
    old_password: Yup.string(passwordRequired).required(
      oldPassordRequired
    ),

    password: Yup.string(passwordRequired)
      .matches(strongPasswordPattern, strongPasswordRequired)
      .required(newPassordRequired)
  });
};
