const en = {
  errors: {
    subscriptionError: "You must register first to continue browsing",
    notSubscribedError: "You need to subscribe to access this page."
  },
  header: {
    digitalBooks: "Digital Books",
    aboutUs: "About us",
    signIn: "Sign in",
    register: "Register",
    home: "Home"
  },
  login: {
    header:
      "Please enter your email address and password to access your account",
    isHaveAccount: "Don’t have an account.",
    createAccount: "Create your account",
    youNeedToVerifyYourEmail:
      "You need to verify your email to continue",
    resendverificationemail: "Resend Verification email"
  },
  signup: {
    header: "Create a new account",
    signUpMessage:
      "Please enter the below information to create your account",
    imageUpload: "Tap or Drag your profile picture",
    termsAgreement: "I Agree on our terms and conditions",
    successMessage: "A new user has been created",
    verificationSuccessMessage:
      "You've successfully verified your account",
    invalidActivationUrl: "Invalid activation url",
    verificaitonEmailResent: "We have sent another verification email"
  },
  editUserInfo: {
    header: "Your Profile",
    editHeader: "Edit your Profile",
    editUserInfoMessage:
      "Please enter the below information to update your profile",
    imageUpload: "Tap or Drag your profile picture",
    termsAgreement: "I Agree on our terms and conditions",
    successMessage: "User has been updated",
    verificationSuccessMessage:
      "You've successfully updated your account",
    invalidActivationUrl: "Invalid activation url"
  },
  changePassword: {
    header: "New Password",
    successMessage: "Your password has changed successfully",
    errorMessage: "Submission error"
  },
  headerMenu: {
    profileInfo: "Edit profile info",
    changePassword: "Change password",
    TermsAndCondition: "Terms and Conditions",
    institutionBooks: "Institution books",
    subscriptionManagement: "Subscription management",
    certificates: "My certificates",
    logout: "Log out"
  },
  inputs: {
    firstName: "First name",
    lastName: "Last name",
    phoneOptional: "Phone number ( optional )",
    email: "Email address",
    password: "Password",
    repeatPassword: "Repeat password",
    newPassword: "Enter your New Password",
    oldPassword: "Enter your Old Password",
    remeberMe: "Remember me",
    emailRequired: "Email is required.",
    passwordRequired: "Password is required.",
    oldPassordRequired: "Old password is required.",
    newPassordRequired: "New password is required.",
    passwordNotMatch: "Password is not matching",
    firstNameRequires: "First name is required.",
    secondNameRequires: "Last name is required.",
    nameMaxLength: "Maximum length is 50 characters",
    subjectRequired: "Subject is required.",
    messsageRequired: "Message is required.",
    emailShouldMatchEmailFormat:
      "Please provide a vaild email format",
    institution: "Institution",
    termsAgreementRequired:
      "You must agree to the terms and condition to complete the registration successfully",
    city: "State/City",
    country: "Country",
    job: "Job",
    countryRequired: "Country is required",
    jobReuired: "Job is required",
    strongPasswordRequired:
      "Password should be at least 8 characters containing (1 Uppercase letter - 1 Lowercase letter - 1 Number)",
    signUpWithGoogle: "Register with Google",
    ContinueRegisteration: "Continue Registeration"
  },
  copyRights: "© Copyright medblendedu 2023",
  contactUs: "Contact us",
  forgotPassword: {
    link: "Forgot Password?",
    header: "Forgot Password",
    subTitle: "Enter your email to get the reset password link",
    repeatPasswordRequired: "Repeat password is required",
    successMessage: "Password reset successfully",
    successemailMessage: "Email send successuly",
    emailTitle:
      "write your email and the reset password link will be sent to you"
  },
  userCertificatesList: {
    certificatesListTitle: "My Certificates",
    certificatesListSubTitle:
      "Enjoy with our reading experience tools and CME hours",
    showCertifiacte: "Show Certifiacte",
    yourCertificates: "Your certificates"
  },
  institutionBooks: {
    title: "Institution books"
  },
  buttons: {
    signIn: "Sign in",
    send: "Send",
    register: "Register",
    save: "Save",
    viewSample: "View Sample",
    getAccess: "Get Access",
    enroll: "Enroll",
    paypalCheckout: "Checkout with PayPal",
    stripeCheckout: "Checkout with Stripe",
    cancel: "Cancel",
    ok: "Back to read",
    next: "Next",
    resubscribe_subs_btn: "Resubscribe",
    read_book: "Start reading",
    confirm: "Confirm",
    submit: "Submit",
    preview: "Preview"
  },
  shared: {
    by: "By",
    hi: "Hi",
    sortBy: "Sort by",
    viewMore: "View more",
    viewLess: "View less",
    none: "None",
    emptyStateMsg: "No results found!",
    welcomeMessage: "Welcome back",
    watchNow: "Watch Now",
    messageSent: "Your message is sent successfully"
  },
  books: {
    bookCover: "Book Cover",
    pagesLeft: "Pages Left",
    FeaturedBooksTitle: "Featured Books",
    RelatedBooksTitle: "Related Books",
    viewTheFullLibrary: "Full Library",
    priceHigh: "Price: High to low",
    priceLow: "Price: Low to high",
    newsetToOldest: "Release Date: Newest to oldest",
    oldestToNewset: "Release Date: Oldest to newest",
    bestSeller: "Best seller",
    welcomeTo: "Welcome to",
    acoreSlogan:
      "Medblend, Future of Medical Education Multimedia and Text Books",
    whatYouLookFor: "What kind of book you are looking for?",
    findHere: "Find here..",
    brief: "Brief",
    sample: "Sample",
    pages: "Pages",
    pageSupplementaries: "Page Supplementaries",
    toRead: "To read",
    hours: "Hours",
    quizzes: "Quizzes",
    supplementariesIndex: "Supplementaries Index",
    chaptersIndex: "Chapters Index",
    page: "Page",
    mins: "mins",
    showYourProgress: "Show your Progress",
    viewAllBookSupplementaries: "View Course Lectures",
    courseLectures: "Course Lectures",
    lectures: "Lectures",
    tests: "Tests",
    ViewCertificate: "View Certificate",
    viewAllBookVideos: "View all Book Videos",
    videos: "Videos",
    video: "Video",
    chapter: "Chapter",
    bookVideos: "Book Videos",
    oldVersion: "Old version",
    availableHint: "available till",
    scoreNote:
      "  You underperform this section, please read it again",
    noteHiglightingHint:
      "Select the text and choose to add notes or highlights"
  },
  pdfViewer: {
    shouldSubscribeMsg:
      "Subscribe now and get full access of reading experience tools and CME hours",
    limtiedAccess: "You have limited access",
    noNote: "There is no note",
    AddNoteHeader: "Add Note",
    highligth: "Highlight",
    remove: "Remove",
    add: "Add",
    cancel: "Cancel",
    deleteHiglight: "delete",
    openNewTab: " Open in a new tab"
  },
  booksubscription: {
    yearlyPlan: "Yearly plan",
    subscritptionTitle: "Subscription plan",
    subscritptionInfo:
      "Please select your subscription plan for your selected book",
    subscriptionFirstServices: "12-Month Unlimited Access to Book.",
    subscriptionSecondService: "Exclusive Textbook Access.",
    subscriptionThirdService:
      "Full Course Material Access including video lectures, quizzes, and self-assessment tests.",
    note: "Notes:",
    firstNoteSubscription:
      "Your subscription will be deducting yearly, you can unsubscribe anytime you want.",
    secondNoteSubscription:
      "You can also enter promo code in the next step."
  },
  cols: { actions: "Actions" },

  checkoutForm: {
    checkout: "Checkout",
    apply: "Apply",
    price: "Price",
    promocode: "Promocode",
    bookTitle: "Book title",
    checkoutStatement:
      "Review your book details and tap on checkout to complete your books subscription",
    promocodeStatement:
      "Get your great discount if you have a promo code, otherwise click on Continue payment to proceed",
    promodeSuccess: "Promode applied successfully",
    visaMaster: "Visa / Master card",
    payPal: "PayPal",
    billingAddress: "Billing address",
    nameOnCard: "Name on Card",
    cardNumber: "Card Number",
    mmYy: "MM/YY",
    securityCode: "Security code",
    zipPostalCode: "Zip/Postal code",
    remeberThisCard: "Remeber this card",
    secureConnection: "Secure Connection",
    completePayment: "Complete payment",
    byComoleting: "By completing your purchase you agree to these",
    termsOfService: "Terms of service.",
    summary: "Summary",
    estimatedTaxes: "Estimated Taxes",
    totalPrice: "Total price",
    quantity: "Quantity",
    FreeDiscountpromodeSuccess: "Promode applied successfully",
    removePromo: "Remove Promocode"
  },
  EditorNotes: {
    pagenumber: "Page Number",
    bookText: "Book Text",
    note: "Note",
    authorName: "Author Name"
  },
  userBookList: {
    booklistTitle: "My Booklist",
    booklistSubTitle:
      "Enjoy with our reading experience tools and CME hours",
    buttonCertificate: "View certificate",
    buttonStart: "Start reading",
    buttonContinue: "Continue",
    completed: "Completed",
    viewCourseLectures: "View Course Lectures",
    Unsubscribe: "Unsubscribe",
    cancelSubscription: "Cancel subscription",
    unSubscrribeHint:
      "If you unsubscribe, you will lose access to that book after your subsciption duration ends and the subscription will not auto renewed "
  },
  test: {
    title: "Test",
    rightAnswer: "Correct",
    wrongAnswer: "Wrong",
    success: "Success",
    failed: "Not well performed",
    score: "Total score",
    explanation: "Explanation ",
    answeredTest: "is already answered",
    viewTest: "View test",
    note: "Note : ",
    notedescription: "Click on any image to full screen mode",
    mark: "Section Name",
    sectionscore: "Section Score%",
    advice: "Performance",
    testReport: "Test Report",
    pretest: "Pre-Test",
    posttest: "Post-Test",
    pretestnotsolved: "Pre-test is not solved",
    finaltest: "Final Test"
  },
  quiz: {
    note: "Note : ",
    notedescription: "Click on any image to full screen mode"
  },
  contact: {
    subject: "Subject",
    message: "Message",
    header: "Contact Us",
    headerMessage: ""
  },
  footer: {
    copyRight: "© Copyright medblendedu 2023",
    contactUs: "Contact us",
    aboutus: "About us",
    Register: "Register",
    signin: "Sign in",
    digitalBooks: "Digital Books",
    acoreTitle: "Medblend"
  },
  aboutUs: {
    title: "About Medblend",
    p1: "At Medblend, our mission is to provide a transformative educational experience that empowers learners to reach their full potential.",
    p2: "Our solution provides an immersive learning experience that goes beyond the limitations of traditional textbooks.",
    p3: "Our content is developed by leading experts in different disciplines, ensuring that they are accurate and relevant to clinical practice.",
    p4: "We are passionate about providing the highest quality educational resources to help modern learners take control of their educational journey and achieve their professional goals.",
    p5: "Join us on this exciting journey of discovery and growth as we reimagine the world of medical education."
  },
  aboutUs2: {
    title: "About Medblend",
    p1: "We are passionate about providing the highest quality educational resources to help modern learners take control of their educational journey and achieve their professional goals.",
    p2: "Join us on this exciting journey of discovery and growth as we reimagine the world of medical education.",
    bullets: [
      {
        h: "Our Mission",
        b: "Our mission is to provide a transformative educational experience that empowers learners to reach their full potential."
      },
      {
        h: "Our Solution",
        b: "Our solution provides an immersive learning experience that goes beyond the limitations of traditional textbooks."
      },
      {
        h: "Our Content",
        b: "Our content is developed by leading experts in different disciplines, ensuring that they are accurate and relevant to clinical practice."
      }
    ]
  },
  contactUsText:
    "Get in touch with us we’re here to answer any questions you may have."
};

export default en;
