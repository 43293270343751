import React from "react";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import BookCover from "../../../components/BookCover";
import Button from "../../../components/Button";
import { Card as BootstrapCard } from "../../../components/Card/Card";
import BooksNameAndAuthor from "../BookNameAndAuthor";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import styles from "./institutionBooks.module.scss";

const InstitutionBooks = ({ books, title }) => {
  const { messages } = useIntl();

  const {
    userBookList: { buttonContinue, completed }
  } = messages;

  return (
    <div className={`${styles.subscription_management_container}`}>
      <BootstrapCard
        className={`border-0 my-5 ${styles.subscription_management_card}`}
        cardBodyClassName={`${styles.card_body}`}
      >
        <p className={`${styles.title} fsize-22 pb-1 my-0`}>
          {title}
        </p>
        {books?.map((book, index) => {
          return (
            <Row
              key={index}
              className={`my-3 align-items-center justify-content-between ${styles.bookSingleConatiner}`}
            >
              <Col xs={12} md={8} lg={8}>
                <Row className={`align-items-center py-3`}>
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    className={`d-flex justify-content-start ${styles.book_name_author}`}
                  >
                    <BookCover
                      size="subscriped_books_size"
                      src={book?.cover_photo}
                    />
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <BooksNameAndAuthor
                      clickable
                      book={{
                        id: book?.id,
                        title: book?.title,
                        author: book?.author
                      }}
                      titleClass={`${styles.book_title}`}
                    />
                    {book?.book_progress?.status === "completed" && (
                      <p>
                        <FontAwesomeIcon
                          color="blue"
                          icon={faCheckCircle}
                        />
                        <span className="m-1"></span>
                        {completed}
                      </p>
                    )}
                  </Col>
                </Row>

                {book?.book_progress?.status === "in_progress" &&
                  book?.book_progress?.page > 0 && (
                    <Row>
                      <div className="w-100 my-3">
                        <ProgressBar
                          now={Math.round(
                            (book.book_progress.page /
                              book.number_of_pages) *
                              100
                          )}
                          label={`${Math.round(
                            (book.book_progress.page /
                              book.number_of_pages) *
                              100
                          )}%`}
                        />
                      </div>
                    </Row>
                  )}
              </Col>
              <Col xs={12} md={4} lg={4}>
                <div className="my-3">
                  <Row className={`mt-5 ${styles.mtSmall0} gap-3`}>
                    {book?.book_progress?.status ===
                      "in_progress" && (
                      <Col md={12}>
                        <Button
                          text={buttonContinue}
                          className="continue_btn"
                          isLink={true}
                          slug={`${ROUTE_PATHS["bookViewer"]}/${book?.id}`}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          );
        })}
      </BootstrapCard>
    </div>
  );
};
InstitutionBooks.propTypes = {
  books: PropTypes.array,
  title: PropTypes.string
};
export default InstitutionBooks;
