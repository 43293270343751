import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { ROUTE_PATHS } from "../utils/PathsNames";
import { useDispatch, useSelector } from "react-redux";
import { showHideSnackbar } from "../store/Snackbar/actions";
import { useIntl } from "react-intl";
import { getSingleBookAction } from "../store/Books/actions";

const SubscriberRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { bookId } = rest.computedMatch.params;
  const bookIdInt = JSON.parse(bookId);
  const { messages } = useIntl();
  const { singleBook } = useSelector((state) => state.books);
  const login = useSelector((state) => state.auth.login);
  const {
    errors: { notSubscribedError }
  } = messages;

  const isBookSubscribed = () => {
    return (
      singleBook?.is_subscribed ||
      login?.institution_subscription?.id ||
      login?.is_beta
    );
  };

  useEffect(() => {
    dispatch(getSingleBookAction(bookIdInt));
  }, []);

  useEffect(() => {
    if (singleBook !== null && !isBookSubscribed()) {
      dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: notSubscribedError
        })
      );
    }
  }, [singleBook]);

  if (!singleBook) {
    return <div />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isBookSubscribed() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROUTE_PATHS["home"] }} />
        )
      }
    />
  );
};

SubscriberRoute.propTypes = {
  component: PropTypes.func
};

export default SubscriberRoute;
