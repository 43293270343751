import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import BookCover from "../BookCover";
import styles from "./BooksSlider.module.scss";

const BooksSlider = ({ title, slides, color, fullLibraryLink }) => {
  const { messages } = useIntl();
  const {
    shared: { by }
  } = messages;

  const NextArrow = ({ onClick }) => {
    return (
      <button className={styles.nextButton} onClick={onClick}>
        <FontAwesomeIcon
          className={`fsize-22 text-${color}`}
          icon={faChevronRight}
        />
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />
  };

  return (
    <div className={`${styles.wrapper} bg-${color}`}>
      <div className="d-flex align-items-center justify-content-between mb-xs-25 pe-4">
        <h2 className="fsize-20">{title}</h2>
        <Link to={fullLibraryLink} className={styles.arrowButton}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      <Slider {...settings}>
        {slides.map(({ id, title, author, cover_photo }) => (
          <div key={id}>
            <Link
              to={`${ROUTE_PATHS["bookProfile"]}/${id}`}
              className={`d-table mb-xs-25 ${styles.bookLink}`}
            >
              <BookCover size="xs" src={cover_photo} />
            </Link>
            <h6 className="fsize-14 m-0 w-80 line-clamp-2">
              {title}
            </h6>
            <small className="fsize-10">
              {by} {author}
            </small>
          </div>
        ))}
      </Slider>
    </div>
  );
};

BooksSlider.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  fullLibraryLink: PropTypes.string
};

BooksSlider.defaultProps = {
  onClick: () => {},
  color: "secondary",
  slides: [],
  fullLibraryLink: "#"
};

export default BooksSlider;
