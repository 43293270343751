import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar as CustomizedSnackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { showHideSnackbar } from "./../../store/Snackbar/actions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export const Snackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showSnackbar = useSelector(
    (state) => state.snackbar.showSnackbar
  );
  const message = useSelector((state) => state.snackbar.message);
  const type = useSelector((state) => state.snackbar.type);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showHideSnackbar({
        isOpen: false,
        type,
        message
      })
    );
  };

  return (
    <div className={classes.root}>
      <CustomizedSnackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </CustomizedSnackbar>
    </div>
  );
};

export default Snackbar;
