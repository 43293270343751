import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./BookCover.module.scss";
import { ImgDisplay } from "../../hooks/ImgDisplay";

const BookCover = ({ src, size }) => {
  const { messages } = useIntl();

  const {
    books: { bookCover }
  } = messages;

  return (
    <div className={`${styles.container}`}>
      <div className={styles.wrapper}>
        <ImgDisplay src={src} className={styles.cover} />
        {!src && (
          <span className="d-flex align-items-center justify-content-center h-100 fsize-14">
            {bookCover}
          </span>
        )}
      </div>
      <div className={styles.page}></div>
      <FontAwesomeIcon icon={faBookmark} className={styles.icon} />
      <div className={styles.page}></div>
      <div className={styles.page}></div>
    </div>
  );
};

BookCover.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string
};

BookCover.defaultProps = {
  src: "",
  size: "sm"
};

export default BookCover;
