import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import contentSvg from "../../assets/svgs/Content.svg";
import missionSvg from "../../assets/svgs/Mission.svg";
import solutionSvg from "../../assets/svgs/Solution.svg";
import "./AboutUs.scss";

const AboutUs = () => {
  const { messages } = useIntl();
  const {
    aboutUs2: { title, p1, p2, bullets }
  } = messages;

  return (
    <section id="about-us-section-id">
      <Container>
        <Row>
          <Col item xs={12}>
            <div id="about-us" className="about-us">
              <div className="right-section ">
                <p className="fsize-20 fw-bold">{title}</p>
                <p className="mb-2">{p1}</p>
                <p className="mb-2">{p2}</p>
              </div>
              <div className="left-section">
                {bullets?.map((bullet, i) => {
                  const Icon = (() => {
                    if (bullet.h === "Our Mission") {
                      return missionSvg;
                    } else if (bullet.h === "Our Solution") {
                      return solutionSvg;
                    } else if (bullet.h === "Our Content") {
                      return contentSvg;
                    }
                  })();
                  return (
                    <div className="bullet" key={i}>
                      <div className="bullet-image">
                        <img src={Icon} />
                      </div>
                      <div className="bullet-header">
                        <p className="fw-bold">{bullet.h}</p>
                        <p className="bullet-body">{bullet.b}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
