import * as Yup from "yup";
import { emailPattern } from "../../constants/validations";
import messages from "../../assets/locale/messages";

export const LOGIN_SCHEMA = (locale) => {
  const {
    inputs: {
      emailRequired,
      passwordRequired,
      emailShouldMatchEmailFormat
    }
  } = messages[locale];

  return Yup.object().shape({
    password: Yup.string(passwordRequired)
      .required(passwordRequired)
      .nullable(),

    email: Yup.string(passwordRequired)
      .email(emailShouldMatchEmailFormat)
      .matches(emailPattern, emailShouldMatchEmailFormat)
      .required(emailRequired)
      .nullable()
  });
};
