import PropTypes from "prop-types";
// import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl"
  // stylisPlugins: [...rtlPlugin]
});

const cacheLtr = createCache({
  key: "muiltr",
  stylisPlugins: []
});

function RTLSwitcher({ lang, children }) {
  return (
    <CacheProvider value={lang === "ar" ? cacheRtl : cacheLtr}>
      {children}
    </CacheProvider>
  );
}

RTLSwitcher.propTypes = {
  children: PropTypes.any,
  lang: PropTypes.string
};

export default RTLSwitcher;
