import React from "react";

const LockIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      {...props}
    >
      <defs>
        <filter
          id="m2mbsgokpa"
          width="454.5%"
          height="454.5%"
          x="-177.3%"
          y="-177.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="2"
            dy="5"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="7.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g
              fill="#FFF"
              filter="url(#m2mbsgokpa)"
              transform="translate(-675.000000, -450.000000) translate(675.270020, 450.500000)"
            >
              <circle cx="10.904" cy="10.904" r="10.904" />
            </g>
            <g>
              <path
                fill="#0E4DCE"
                stroke="#004AD6"
                d="M3.35 0c1.232 0 2.234 1.002 2.234 2.234V3.35h.28c.461 0 .837.376.837.838v3.909c0 .462-.376.838-.838.838H.838C.376 8.935 0 8.559 0 8.097V4.188c0-.462.376-.838.838-.838h.278V2.234C1.117 1.002 2.12 0 3.35 0zm0 .558c-.923 0-1.675.752-1.675 1.676V3.35h3.35V2.234c0-.924-.75-1.676-1.675-1.676z"
                transform="translate(-675.000000, -450.000000) translate(675.270020, 450.500000) translate(7.553865, 5.480515)"
              />
              <path
                fill="#FFF"
                d="M3.35 4.84c.411 0 .745.334.745.744 0 .312-.193.58-.466.69v.892c0 .154-.124.28-.279.28-.154 0-.279-.126-.279-.28v-.891c-.272-.111-.465-.379-.465-.69 0-.411.334-.745.744-.745zm0 .558c-.102 0-.186.083-.186.186s.084.186.186.186c.103 0 .187-.083.187-.186s-.084-.186-.187-.186z"
                transform="translate(-675.000000, -450.000000) translate(675.270020, 450.500000) translate(7.553865, 5.480515)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LockIcon;
