import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import Auth from "../utils/Auth";
import { ROUTE_PATHS } from "../utils/PathsNames";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !Auth.getAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTE_PATHS["home"] }} />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func
};

export default PublicRoute;
