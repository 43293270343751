import * as Yup from "yup";
import messages from "../../assets/locale/messages";
import { emailPattern } from "../../constants/validations";
import { strongPasswordPattern } from "../../utils/Patterns";

export const RESET_PASSWORD_SCHEMA = (locale) => {
  const {
    inputs: {
      passwordRequired,
      passwordNotMatch,
      strongPasswordRequired
    },
    forgotPassword: { repeatPasswordRequired }
  } = messages[locale];

  return Yup.object().shape({
    new_password: Yup.string(passwordRequired)
      .matches(strongPasswordPattern, strongPasswordRequired)
      .required(passwordRequired),
    confirm_password: Yup.string()
      .nullable()
      .required(repeatPasswordRequired)
      .oneOf([Yup.ref("new_password"), null], passwordNotMatch)
  });
};

export const EMAIL_SCHEMA = (locale) => {
  const {
    inputs: { emailRequired, emailShouldMatchEmailFormat }
  } = messages[locale];

  return Yup.object().shape({
    email: Yup.string()
      .email(emailShouldMatchEmailFormat)
      .matches(emailPattern, emailShouldMatchEmailFormat)
      .required(emailRequired)
  });
};
