import React from "react";

const CloseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g
                fill="#FFF"
                transform="translate(-241.000000, -179.000000) translate(220.000000, 154.500000) translate(21.000000, 24.500000)"
              >
                <rect width="40" height="40" rx="8" />
              </g>
              <path
                stroke="#0E4DCE"
                strokeLinecap="round"
                strokeWidth="2"
                d="M11.92 20L28.122 20"
                transform="translate(-241.000000, -179.000000) translate(220.000000, 154.500000) translate(21.000000, 24.500000) translate(20.021383, 20.000000) scale(-1, 1) rotate(45.000000) translate(-20.021383, -20.000000)"
              />
              <path
                stroke="#0E4DCE"
                strokeLinecap="round"
                strokeWidth="2"
                d="M11.92 20L28.122 20"
                transform="translate(-241.000000, -179.000000) translate(220.000000, 154.500000) translate(21.000000, 24.500000) translate(20.021383, 20.000000) rotate(45.000000) translate(-20.021383, -20.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
