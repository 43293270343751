import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/books";
import messages from "../../assets/locale/messages";
import {
  setPromocodeResponse,
  setPromocodeNameResponse,
  setFreeDiscountPromocodeRequest
} from "./actions";
import { getSubscribedBooksResponse } from "../Books/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import History from "../../routes/History";

export function* setPromocodeSaga({ payload }) {
  try {
    const response = yield call(apis.setPromocode, payload);
    yield put(setPromocodeResponse(response.data.discounted_price));
    yield put(setPromocodeNameResponse(response.data.promo_code));

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].checkoutForm.promodeSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* setFreeDiscountPromocodeSaga({ payload }) {
  try {
    const response = yield call(
      apis.setFreeDiscountPromocode,
      payload
    );
    yield put(setPromocodeResponse(response.data.discounted_price));
    yield put(setPromocodeNameResponse(response.data.promo_code));

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].checkoutForm.promodeSuccess
      })
    );

    yield put(
      getSubscribedBooksResponse(response.data.book_subcription.book)
    );

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].checkoutForm
            .FreeDiscountpromodeSuccess
      })
    );
    localStorage.removeItem("promocodeDiscountSaved");

    History.push(
      `${ROUTE_PATHS.bookProfile}/${response.data.book_subcription.book.id}`
    );
    yield put(setPromocodeResponse(null));

    yield put(setPromocodeNameResponse(null));
  } catch (err) {
    console.error(err);
  }
}
