import * as types from "./types";

export const getCategoriesRequest = (data) => ({
  type: types.GET_CATEGORIES_REQUEST,
  payload: data
});

export const getCategoriesResponse = (data) => ({
  type: types.GET_CATEGORIES_RESPONSE,
  payload: data
});

export const setCategorySearch = (data) => ({
  type: types.SET_CATEGORY_SEARCH,
  payload: data
});
