import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

import { useQuery } from "../../hooks/useQuery";
import {
  resetNewPassword,
  sendEmailNewPassword
} from "../../store/Auth/actions";
import AuthLayout from "../../components/AuthLayout";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { RESET_PASSWORD_SCHEMA, EMAIL_SCHEMA } from "./validations";

const ForgetPassword = () => {
  const { messages, locale } = useIntl();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState(false);
  const [emailRegester, setEmailRegester] = useState(true);

  const dispatch = useDispatch();
  const query = useQuery();

  const {
    forgotPassword: { header, emailTitle },
    inputs: { email, password, repeatPassword },

    buttons: { confirm }
  } = messages;

  useEffect(() => {
    if (query.get("token")) {
      localStorage.setItem("token", query.get("token"));
      setEmailRegester(false);
    } else {
      setEmailRegester(true);
    }
    return () => {
      localStorage.removeItem("token");
    };
  }, [query]);

  const setPasswordStatus = (type) => {
    if (type === 1) {
      setIsShowPassword(!isShowPassword);
    }
    if (type === 2) {
      setIsShowConfirmPassword(!isShowConfirmPassword);
    }
  };

  const handlePasswordSubmit = async (data) => {
    let payload = {
      email: query.get("email"),
      reset_password_token: query.get("token"),
      new_password: data.new_password
    };

    dispatch(resetNewPassword(payload));
  };
  const handleEmailSubmit = async (payload) => {
    dispatch(sendEmailNewPassword(payload));
  };
  const renderChangePasswordForm = () => (
    <>
      <Container>
        <Row>
          <Col className="mx-auto" sm={12} md={12} lg={10}>
            {emailRegester ? (
              <>
                <div className="mb-4">
                  <Typography variant="p">{emailTitle}</Typography>
                </div>

                <Formik
                  initialValues={{
                    email: ""
                  }}
                  enableReinitialize
                  validationSchema={EMAIL_SCHEMA(locale)}
                  onSubmit={handleEmailSubmit}
                >
                  {({ submitForm, isValid }) => (
                    <Form>
                      <>
                        <Form>
                          <Input
                            placeholder={email}
                            name="email"
                            isRequired={true}
                          />

                          <div
                            className={`mb-5 d-flex justify-content-center`}
                          >
                            <Button
                              text={confirm}
                              disabled={!isValid}
                              className="primary-lg"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                submitForm();
                              }}
                            />
                          </div>
                        </Form>
                      </>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <h3 className="mb-4">{header}</h3>

                <Formik
                  initialValues={{
                    new_password: "",
                    confirm_password: ""
                  }}
                  enableReinitialize
                  validationSchema={RESET_PASSWORD_SCHEMA(locale)}
                  onSubmit={handlePasswordSubmit}
                >
                  {({ submitForm, isValid }) => (
                    <Form>
                      <>
                        <Form>
                          <Input
                            inputIcon={
                              isShowPassword ? (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <FontAwesomeIcon icon={faEye} />
                                </div>
                              )
                            }
                            type={
                              !isShowPassword ? "password" : "text"
                            }
                            placeholder={password}
                            name="new_password"
                            onIconClick={() => setPasswordStatus(1)}
                            isRequired={true}
                          />
                          <Input
                            inputIcon={
                              isShowConfirmPassword ? (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <FontAwesomeIcon icon={faEye} />
                                </div>
                              )
                            }
                            type={
                              !isShowConfirmPassword
                                ? "password"
                                : "text"
                            }
                            placeholder={repeatPassword}
                            name="confirm_password"
                            onIconClick={() => setPasswordStatus(2)}
                            isRequired={true}
                          />

                          <div
                            className={`mb-5 d-flex justify-content-center`}
                          >
                            <Button
                              text={confirm}
                              disabled={!isValid}
                              className="primary-lg"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                submitForm();
                              }}
                            />
                          </div>
                        </Form>
                      </>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );

  return <AuthLayout body={renderChangePasswordForm()} />;
};

export default ForgetPassword;
