import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material/styles";
import RTLSwitcher from "../themes/RTLtheme";
import theme from "../themes/light";

function MuiProvider({ children }) {
  const { locale } = useIntl();
  const [dir, setDir] = useState("ltr");

  useEffect(() => {
    if (locale === "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.removeAttribute("dir");
    }

    setDir(locale == "ar" ? "rtl" : "ltr");
  }, [locale]);

  const themeProvider = theme({ dir });

  return (
    <RTLSwitcher lang={locale}>
      <ThemeProvider theme={themeProvider}>{children}</ThemeProvider>
    </RTLSwitcher>
  );
}

MuiProvider.propTypes = {
  children: PropTypes.any
};

export default MuiProvider;
