import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import BooksSlider from "../../../components/BooksSlider";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { getRelatedBooksAction } from "../../../store/Books/actions";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import ClickableBookCover from "../../../components/ClickableBookCover/ClickableBookCover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./RelatedBooks.module.scss";
import PropTypes from "prop-types";

const RelatedBooks = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { id } = useParams();
  const relatedList = useSelector(
    (state) => state.books.related.list
  );

  useEffect(() => {
    const payload = {
      id,
      params: {
        page: 1,
        items: 5
      }
    };
    function getBooks() {
      dispatch(getRelatedBooksAction(payload));
    }
    getBooks();
  }, [id]);

  const {
    books: { RelatedBooksTitle }
  } = messages;
  const [selectedBook, setSelectedBook] = useState(null);
  /* eslint-disable react/prop-types */
  const NextArrow = ({ onClick }) => {
    return (
      <button className={styles.nextButton} onClick={onClick}>
        <FontAwesomeIcon
          className={`fsize-22`}
          icon={faChevronRight}
        />
      </button>
    );
  };
  NextArrow.prototype = {
    onClick: PropTypes.func
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: null,
    className: "cd"
  };

  return (
    <>
      {relatedList.length !== 0 && (
        <>
          {/* <BooksSlider
            title={RelatedBooksTitle}
            slides={relatedList}
            color="[#EAF4FF]"
            fullLibraryLink={`${ROUTE_PATHS["books"]}/related/${id}`}
          /> */}
          <h3>{RelatedBooksTitle}</h3>
          <div>
            <Slider {...settings}>
              {relatedList.map((book, index) => (
                <div
                  key={index}
                  className={`position-relative ${styles.minHieghtBookClickable}`}
                >
                  <ClickableBookCover
                    isSelected={selectedBook === book.id}
                    book={book}
                    index={index}
                    clearSelection={() => setSelectedBook(null)}
                    selectBook={() => setSelectedBook(book.id)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
};

export default RelatedBooks;
